import { Component, ViewChild, ElementRef } from '@angular/core';
import { IDownloadAnchor } from 'src/app/model/contracts-for-plugins/idownload-anchor';

/** @description Needed for download to disc.
 *
 * See backup.service.md for detailed documentation.
 */
@Component({
  selector: 'app-download-anchor',
  templateUrl: './download-anchor.component.html',
  styleUrls: ['./download-anchor.component.scss'],
  providers: []
})
export class DownloadAnchorComponent implements IDownloadAnchor {

  /**
   * @description A ref to our hosting element.
   * Note: for this 'downloadanchor'-string below to work, the target
   * element in the view must be marked as #noteeditor.
   */
  @ViewChild('downloadanchor', {}) button: ElementRef<HTMLAnchorElement>;
  // https://indepth.dev/exploring-angular-dom-manipulation-techniques-using-viewcontainerref/

  /** Returns the element to which data can be attached and which saves to a file on click.
   */
  public getElement(): ElementRef<HTMLAnchorElement> {
    return this.button;
  }
}
