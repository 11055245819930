import { ITask } from '../i-task';
import { TaskStatus } from '../task-status.enum';
import { Inject, Injectable } from '@angular/core';
import { ITaskFactory } from './itask-factory';
import { IDurationFactory } from '../../timing and duration/duration/iduration-factory';
import { ITimeFactory } from '../../timing and duration/time/itime-factory';
import { Task1 } from '../task1';
import { TimedTask1 } from '../timed-task1';
import { MyScheduleInterpreter } from '../../timing and duration/scheduleInterpreter/my-schedule-interpreter';
import { TimingPatternInfo } from '../../timing and duration/timingPattern/timing-pattern-info';
import { TimingPatternEnum } from '../../timing and duration/timingPattern/item-timing-pattern.enum';
import { ITKN_IDURATIONFACTORY, ITKN_ITIMEFACTORY } from 'src/app/application/injectionTokens';


@Injectable()
export class TaskFactory implements ITaskFactory {

  constructor(
    @Inject(ITKN_IDURATIONFACTORY) public durationFactory: IDurationFactory
    , @Inject(ITKN_ITIMEFACTORY) public timeFactory: ITimeFactory
  ) { }

  /** @description Creates a task and puts it in the initial status. */
  public createTask(): Task1 {
    const t = new Task1();
    t.dateCreated = new Date();
    t.status = TaskStatus.ToDo;
    t.order = null;
    t.parentId = null;
    t.content = null;
    t.note = null;
    t.dateStarted = null;
    t.dateDone = null;
    // , isTimed= false
    // , timerName= null
    // , timingPatternInfo= null
    // , timerSubscriptionId= null
    // , firstTimerTick= null
    // , nextTimerTick= null
    // , resumeDelay= null
    // , shiftingFirstTimerTick= null
    return t;
  }

  /** @description Creates a task and puts it in the initial status. */
  public createTimedTask(): TimedTask1 {

    const tbase = new Task1();
    const t = new TimedTask1(tbase);
    t.dateCreated = new Date();
    t.status = TaskStatus.ToDo;
    t.order = null;
    t.parentId = null;
    t.content = null;
    t.note = null;
    t.dateStarted = null;
    t.dateDone = null;
    t.timerName = null;
    t.timingPatternInfo = null;
    t.timerSubscriptionId = null;
    t.firstTimerTick = null;
    t.nextTimerTick = null;
    t.resumeDelay = null;
    t.shiftingFirstTimerTick = null;

    return t;
  }

  /** @description Initializes a new task with the given content. */
  public createTaskFromContent(content: string): ITask {
    return this.createTaskFromUserInputstring(content);   // delegate to the method that has the more informative name.
  }

  /** @description Constructs a task or timer from user textual input. */
  private createTaskFromUserInputstring(userInputString: string): ITask {

    // search for any timing info in the userinput
    const interpreter = new MyScheduleInterpreter(this.durationFactory, this.timeFactory);
    const timingpatternInfo: TimingPatternInfo = interpreter.recognizeTimingPattern(userInputString);

    if (timingpatternInfo.timingpattern.pattern === TimingPatternEnum.None) {

      const task: Task1 = this.createTask();
      // No timing info was found in the userinput, so we keep it a simple task.
      task.content = userInputString;

      return task;

    } else {

      const task: TimedTask1 = this.createTimedTask();
      // Store timing-info in the task.
      task.content = timingpatternInfo.outputTextArray[0];       // contents with timing info stripped off
      task.note = timingpatternInfo.outputTextArray[1];          // timing info is moved into the note.
      task.timingPatternInfo = timingpatternInfo;

      return task;
    }
  }
}
