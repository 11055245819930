<div id="timer-section" class="done repress breath cool">

	<div class="rij">
		<h4 id="titleTimers">timers &nbsp;</h4>
		<sup class="stack-counter">{{list.length}}</sup>&nbsp;
	</div>

	<div *ngFor="let item of list.items" [ngClass]="(item.status===postponedStatus)?'postponed':''">
		<span class="identifier"> {{item.id.shortID()}}
			<span class="parent-identifier" [hidden]="!item.parentId">({{item.parentId ? item.parentId.shortID() : ''}})</span>
		</span>
		<button class="invisible ms-button darkgray" (click)="showTaskEditor(item)"><span class="ms-button-text">edit</span></button>
		<button class="ms-button blue fixed-width-pause-btn stop" (click)="stop($event, item)"><span class="ms-button-text">{{item.status===doneStatus?'start':'&nbsp;stop'}}</span></button>
		<button class="ms-button blue fixed-width-pause-btn pause" (click)="pause($event, item)"><span class="ms-button-text">{{item.status===postponedStatus?'resume':'pause'}}</span></button>
		<button class="ms-button red" (click)="cancel(item)"><span class="ms-button-text">cancel</span></button>
		<button class="ms-button yellow" (click)="showNote(item)"><span class="ms-button-text" [ngClass]="{'noteHasContent' : item.note && item.note.length > 0 }" >note</span></button>

		<app-text-input [value]="item.content" [identifier]="item.id" [reference]="item" (onChange)="receiveContentChanged($event)"></app-text-input>

		<div class="note" *ngIf="isVisibleNote(item)">
			<app-note-editor
				[value]="item.note"
				[reference]="item"
				(onChange)="receiveNoteChange($event)"
			></app-note-editor>
		</div>

		<app-task-editor
				[item]="item"
				*ngIf="!isHiddenTaskEditor(item)"
				(onClose)="receiveCloseEventFromTaskEditor($event)"
				(onTaskChanged)="receiveTaskChanged($event)"
		></app-task-editor>
	</div>
</div>
