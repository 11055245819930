import { environment } from 'src/environments/environment';
import { ISoundService } from '../../usercontrols/main/contracts-for-plugins/sound.service.interface';

export class SoundService implements ISoundService {

  /** @description Volume, between 0 and 100
   */
  private defaultVolume = 20;

  constructor() {}

  public set DefaultVolume(volume: number) {
    if (0 <= volume && volume <= 100) {
      this.defaultVolume = volume;
    }
  }

  public get DefaultVolume(): number {
    return this.defaultVolume;
  }

  public playTimerAlarmSound(volume?: number): void {
    if (environment.production) {
      this.playSound('Elevator_Ding.mp3', volume);
    } else {
      this.playSound('ah201.mp3', volume);
    }
  }

  public playElevatorMoveSound(volume?: number): void {
    this.playSound('ElvMoov.mp3', volume);
  }

  /** @description play a file from the /assets/sounds folder. Parameter file must have filename and extension.
   */
  public playSound(file: string, volume?: number): void {
    const sound = new Audio();
    sound.src = '../../../assets/sounds/' + file;
    const volumeBase100: number = volume ? volume : this.defaultVolume;
    sound.volume = volumeBase100 / 100;
    sound.load();
    sound.play();
  }
}
