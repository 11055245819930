// extensions for number, using method from 'Jaques' at
// https://stackoverflow.com/questions/39877156/how-to-extend-string-prototype-and-use-it-next-in-typescript

Number.prototype.padZero = function(length: number): string {
  return String(this).padZero(length);
};

/**
 * @description Returns true if the value on which it is called is between the given boundaries
 * @param inclusive Default true, i.e. the method returns true if the value is equal to one of the boundaries; if set to false
 * this method returns false if the value is equal to or outside the given boundaries.
 */
Number.prototype.between = function(x: number, y: number, inclusive: boolean = true): boolean {    // , inclusive: boolean = true
  if (inclusive) {
    return x <= this && this <= y;
  } else {
    return x < this && this < y;
  }
};
