/* eslint-disable no-underscore-dangle */
import { ITask, ITimedTask } from '../../api/api';

export class TimedTaskSubscriptionInfo {

  private _timerName: string;
  get TimerName(): string {
    return this._timerName;
  }
  set TimerName(val: string) {
    this._timerName = val;
  }

  private _subscriptionID: string;
  get SubscriptionID(): string {
    return this._subscriptionID;
  }
  set SubscriptionID(val: string) {
    this._subscriptionID = val;
  }

  private _firstTimerTick: Date;
  get FirstTimerTick(): Date {
    return this._firstTimerTick;
  }
  set FirstTimerTick(val: Date) {
    this._firstTimerTick = val;
  }

  private _nextTimerTick: Date;
  get NextTimerTick(): Date {
    return this._nextTimerTick;
  }
  set NextTimerTick(val: Date) {
    this._nextTimerTick = val;
  }

  private _shiftingFirstTimerTick: Date;
  get ShiftingFirstTimerTick(): Date {
    return this._shiftingFirstTimerTick;
  }
  set ShiftingFirstTimerTick(val: Date) {
    this._shiftingFirstTimerTick = val;
  }

  private _note: string;
  get Note(): string {
    return this._note;
  }
  set Note(val: string) {
    this._note = val;
  }

  copyToTask(task: ITimedTask): void {
    if (this.FirstTimerTick) {
      task.firstTimerTick = this.FirstTimerTick;
    }
    if (this.NextTimerTick) {
      task.nextTimerTick = this.NextTimerTick;
    }
    if (this.Note) {
      task.note = this.Note;
    }
    if (this.ShiftingFirstTimerTick) {
      task.shiftingFirstTimerTick = this.ShiftingFirstTimerTick;
    }
    if (this.SubscriptionID) {
      task.timerSubscriptionId = this.SubscriptionID;
    }
    if (this.TimerName) {
      task.timerName = this.TimerName;
    }
  }
}
