import { ITask } from './i-task';

/** @description To copy values from one ITask to another ITask.
 */
export class TaskCopier {

  /**
   * @description Copies all, but not timer-, properties of a source task into a destination task.
   *
   * Note: does not copy the ID. This is a basic rule: id's are never copied into the id-field of another object.
   * If you want it anyway you do it by hand.
   *
   * Technique stone-age.
   */
  copyStoneAge = (source: ITask, destination: ITask): void => {

    // note: guid is not copied because it's readonly. Some other fields are readonly too but
    //    I do not mind if the user wants to tamper with them (dateCreated, dateStarted etc.).

    destination.content = source.content;
    destination.dateCreated = source.dateCreated;
    destination.dateDone = source.dateDone;
    destination.dateStarted = source.dateStarted;
    // destination.timerName = source.timerName;
    destination.note = source.note;
    destination.noteHeight = source.noteHeight;
    destination.order = source.order;
    destination.parentId = source.parentId;
    // destination.timingPatternInfo = source.timingPatternInfo;   // todo!
    destination.status = source.status;
    // Timer properties are not copied. Please solve it by a parameter if you need'm.
    // destination.isTimed
    // destination.timerSubscriptionId
    // destination.firstTimerTick
    // destination.shiftingFirstTimerTick
    // destination.nextTimerTick
    // destination.resumeDelay
  };

  /**
   * @description (NOT YET IMPLEMENTED) Copies all properties of a source task into a destination task.
   * Note: does not copy guid.
   */
  copy = (source: ITask, destination: ITask, exclude?: string[]): void => {
    throw new Error('Not yet implemented!');

    // destination = { ...source };   // usefull technique
  };
}
