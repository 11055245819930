/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGoogleAuthorizationService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';

// technical dependencies
import { ITKN_IGOOGLEAUTHORIZATIONSERVICE } from 'src/app/application/injectionTokens';

// mindstack dependencies
import { IStorageProvider$ } from '../../contracts-for-plugins/istorage-provider-$';

@Injectable({
  providedIn: 'root'
})
export class GoogleDriveStorageProvider implements IStorageProvider$ {

  constructor(
    @Inject(ITKN_IGOOGLEAUTHORIZATIONSERVICE) private googleAuthSvc: IGoogleAuthorizationService
  ) { }

  public emptyObservable(): Observable<unknown> {
    return new Observable<unknown>();
  }

  /** Get a new fileID from Google Drive */
  public generateFileId(): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {

      if (!this.googleAuthSvc.ACCESS_TOKEN) {

        observer.error('No access_token available. Sign in to Google first.');

      } else {

        const xhr = new XMLHttpRequest();

        xhr.open('GET',
          'https://www.googleapis.com/drive/v3/files/generateIds?count=1');

        xhr.setRequestHeader('Authorization', 'Bearer ' + String(this.googleAuthSvc.ACCESS_TOKEN));

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const newId = JSON.parse(xhr.response).ids[0];
            // └─response object, see https://developers.google.com/drive/api/v3/reference/files/generateIds#response
            console.log(`Got ID from google: ${String(newId)}`);
            observer.next(newId);
          } else if (xhr.readyState === 4 && xhr.status === 401) {
            // Token invalid, so prompt for user permission.
            observer.error(xhr.response);
          }
        };

        xhr.send();
      }
    });

    return observable;
  }

  /** Create a file on google drive */
  public create(name: string, contents: string, id?: string): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {

      // Based on examples of drive v2, combined with code instructions for drive v3. What a drama again ...

      // append extension
      const extension = '.json';
      if (!name.endsWith(extension)) {
        name = name.concat(extension);
      }

      // #region doc
      // Instructions from https://developers.google.com/drive/api/guides/manage-uploads#multipart, pick HTTP example.

      // Create the body of the request. Format the body according to the multipart/related content type [RFC 2387], which
      // contains two parts:

      // A. Metadata. The metadata must come first and must have a Content-Type header set to application/json; charset=UTF-8.
      // _    Add the file's metadata in JSON format.
      // B. Media. The media must come second and must have a Content-Type header of any MIME type. Add the file's
      // data to the media part.

      // Identify each part with a boundary string, preceded by two hyphens. In addition, add two hyphens after the
      // final boundary string.

      // Ik nam een voorbeel van drive v2: https://developers.google.com/drive/api/v2/reference/files/insert#examples (pick Javascript)
      // #endregion

      const boundary = '-------314159265358979323846';
      const delimiter = '\r\n--' + boundary + '\r\n';
      const close_delim = '\r\n--' + boundary + '--';

      const fileContents = [contents];
      const fileData = new File(fileContents, name);

      const reader = new FileReader();
      reader.readAsBinaryString(fileData);
      reader.onload = () => {

        // Setup Metadata for body
        const fileContentType = 'application/json; charset=UTF-8';
        // └─ warning: this is the type of the CONTENTS, not of the body of the request.
        const metadata = {
          id,
          name,
          mimeType: fileContentType
        };

        // Setup Media for body (i.e. contents)
        const base64Data = btoa(String(reader.result));   // String() toegevoegd

        const multipartRequestBody =
          delimiter +
          'Content-Type: application/json' +
          '\r\n\r\n' +
          JSON.stringify(metadata) +
          delimiter +
          'Content-Type: ' + fileContentType + '\r\n' +
          'Content-Transfer-Encoding: base64\r\n' +
          '\r\n' +
          base64Data +
          close_delim;

        if (this.googleAuthSvc.ACCESS_TOKEN) {

          const xhr = new XMLHttpRequest();

          xhr.open('POST',
            'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart');

          xhr.setRequestHeader('Authorization', 'Bearer ' + String(this.googleAuthSvc.ACCESS_TOKEN));

          xhr.setRequestHeader('Content-Type', 'multipart/mixed; boundary="' + boundary + '"');
          // _                                      └─multipart: i.e. metadata + contents.

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              observer.next(xhr.response);
            } else if (xhr.readyState === 4 && xhr.status === 401) {
              observer.error(xhr.response);   // Token invalid, so prompt for user permission.
            }
          };

          xhr.send(multipartRequestBody);
        } else {
          observer.error('No access_token available in google-drive provider.');
        }
      };
    });

    return observable;
  }

  /** Download a file from google drive, by fileID */
  public read(id: string): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {

      if (!this.googleAuthSvc.ACCESS_TOKEN) {

        observer.error(new Error('Cannot download file: no access_token available. Sign in to Google first.'));

      } else {

        const xhr = new XMLHttpRequest();

        xhr.open('GET',
          'https://www.googleapis.com/drive/v3/files/' + String(id) + '?alt=media');

        xhr.setRequestHeader('Authorization', 'Bearer ' + String(this.googleAuthSvc.ACCESS_TOKEN));
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

        xhr.onload = () => {
          // console.log('Download ended with response: ' + xhr.responseText);
        };
        xhr.onerror = (err) => {
          observer.error(err);
        };
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // const filecontents = JSON.parse(xhr.response);
            observer.next(String(xhr.response));
          } else if (xhr.readyState === 4 && xhr.status === 401) {
            // Token invalid, so prompt for user permission.
            observer.error('invalid token');
          }
        };
        xhr.send(null);
      }
    });

    return observable;
  }

  /** Update a file on google drive, by fileID */
  public update(id: string, contents: string): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {
      if (!this.googleAuthSvc.ACCESS_TOKEN) {

        observer.error('Cannot update file: no access_token available. Sign in to Google first.');

      } else {


        const boundary = '-------314159265358979323846';
        const delimiter = '\r\n--' + boundary + '\r\n';
        const close_delim = '\r\n--' + boundary + '--';

        const fileContents = [contents];
        const fileData = new File(fileContents, null);

        const reader = new FileReader();
        reader.readAsBinaryString(fileData);

        reader.onload = () => {

          // Setup Metadata for body
          const fileContentType = 'application/json';
          // └─ warning: this is the type of the CONTENTS, not of the body of the request.

          // note: adding metadata is forbidden: 403 response!

          // Setup Media for body (i.e. contents)
          const base64Data = btoa(String(reader.result));   // String() toegevoegd

          const multipartRequestBody =
            delimiter +
            'Content-Type: application/json' +
            '\r\n\r\n' +
            delimiter +
            'Content-Type: ' + fileContentType + '\r\n' +
            'Content-Transfer-Encoding: base64\r\n' +
            '\r\n' +
            base64Data +
            close_delim;

          const xhr = new XMLHttpRequest();

          xhr.open('PATCH',
            'https://www.googleapis.com/upload/drive/v3/files/' + id + '?uploadType=multipart');
          xhr.setRequestHeader('Authorization', 'Bearer ' + String(this.googleAuthSvc.ACCESS_TOKEN));
          xhr.setRequestHeader('Content-Type', 'multipart/mixed; boundary="' + boundary + '"');
          // _                                      └─multipart: i.e. metadata + contents.

          xhr.onreadystatechange = (e) => {
            if (xhr.readyState === 4 && xhr.status === 200) {
              observer.next(String(xhr.response) + ' at ' + String(new Date()));
            } else if (xhr.readyState === 4 && xhr.status === 401) {
              // Token invalid, so prompt for user permission.
              observer.error('Refresh access_token required.');
            }else if (xhr.readyState === 4 && xhr.status === 403) {
              observer.error(xhr.response);   // Forbidden
            }
          };

          xhr.send(multipartRequestBody);
        };
      }
    });

    return observable;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public delete(id: string): Observable<unknown> {
    throw new Error('Method _delete_ not implemented for Google Drive.');
  }

  public list(): Observable<string> {

    // https://developers.google.com/drive/api/v3/reference/files/list

    // Do not supply a request body with this method.
    // trashed=false
    // q=  query param
    // maxResults
    // orderBy=title asc
    // GET https://www.googleapis.com/drive/v3/files

    //  GET https://www.googleapis.com/drive/v2/files?trashed=false&maxResults=10&orderBy=title asc&q=


    const observable: Observable<string> = new Observable((observer) => {

      if (!this.googleAuthSvc.ACCESS_TOKEN) {

        observer.error(new Error('Cannot list file: no access_token available. Sign in to Google first.'));

      } else {

        const xhr = new XMLHttpRequest();

        // &fields=files(id, name)'

        const url = 'https://www.googleapis.com/drive/v3/files?'
        const queryFilter = 'trashed=false&orderBy=name asc&maxResults=50'; // &fields = "files(name)"
        const querySearch = '&q=mimeType = "application/json" and name contains ".json" and name contains "whatiamdoing"';
        const urlEncoded = encodeURI(url + queryFilter + querySearch);

        xhr.open('GET', urlEncoded);

        xhr.setRequestHeader('Authorization', 'Bearer ' + String(this.googleAuthSvc.ACCESS_TOKEN));
        // xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

        xhr.onload = () => {
          // console.log('Download ended with response: ' + xhr.responseText);
        };
        xhr.onerror = (err) => {
          observer.error(err);
        };
        xhr.onreadystatechange = (e) => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            observer.next(xhr.response);
          } else if (xhr.readyState === 4 && xhr.status === 401) {
            // Token invalid, so prompt for user permission.
            observer.error('invalid token');
          }
        };
        xhr.send(null);
      }
    });

    return observable;
  }
}
