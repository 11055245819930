/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
// import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from 'src/app/view/usercontrols/main/app.component';   // dependency on app.component
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// SK dec 2019: make extension methods available to whole app (maybe it can be declared on a higher level, but this works.)
import 'src/app/model/shared/string.extensions';
import 'src/app/model/shared/number.extensions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';       // needed for tree component
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { TopBarComponent } from 'src/app/view/usercontrols/top-bar/top-bar.component';
import { HelpComponent } from 'src/app/view/usercontrols/help/help.component';
import { TaskEditorComponent } from 'src/app/view/usercontrols/task-editor/task-editor.component';
import { DropdownComponent } from 'src/app/view/components/dropdown/dropdown.component';
import { HamburgerComponent } from 'src/app/view/components/hamburger/hamburger.component';
import { DoingComponent } from 'src/app/view/usercontrols/doing/doing.component';
import { DoneComponent } from 'src/app/view/usercontrols/done/done.component';
import { NoteEditorComponent } from 'src/app/view/usercontrols/note-editor/note-editor.component';
import { TimersComponent } from 'src/app/view/usercontrols/timers/timers.component';
import { DownloadAnchorComponent } from 'src/app/view/usercontrols/download-anchor/download-anchor.component';
import { LoadFileFromDiscComponent } from 'src/app/view/usercontrols/load-file-from-disk/load-file-from-disc.component';
import { TextInputComponent } from 'src/app/view/usercontrols/text-input/text-input.component';
import { TreenodeContentComponent } from 'src/app/view/components/treenode-content/treenode-content.component';
import {
  ITKN_IDATASERVICE,
  ITKN_IDEMODATASERVICE,
  ITKN_ILOGSERVICE,
  ITKN_ITASKFACTORYSERVICE,
  ITKN_IDURATIONFACTORY,
  ITKN_ITIMEFACTORY,
  ITKN_ITIMERSERVICE,
  ITKN_ITASKFACTORYSERVICE_for_DEMODATASERVICE,
  ITKN_ITASKFACTORYSERVICE_for_STORAGESERVICE,
  ITKN_IGOOGLEAUTHORIZATIONSERVICE,
  ITKN_ISTORAGESERVICE_GDRIVE,
  ITKN_ISTORAGESERVICE_LOCALDISC,
  ITKN_ISTORAGESERVICE_LOCALSTORAGE
} from './application/injectionTokens';
import { DataService1 } from './data/dataService/data.service.1';
import { TimerService1 } from 'src/app/model/timing and duration/timerservice/timer.service.1';
import { LoggerService } from './cross-cutting-concerns/api.cross-cutting-concerns';
import { TaskFactory, DurationFactory, TimeFactory } from './model/api/api';
import { DemoDataService1 } from './data/dataService/plugins/demo-data-services/demoDataService1/demo-data.service.1';
import { GoogleAuthorizationService1 } from './cross-cutting-concerns/authorization/implementation/google.authorization.service1';
import { DoingService } from './view/usercontrols/doing/doing.component.service';
import { RouterModule } from '@angular/router';
import { GoogleDriveStorageProvider } from './data/dataService/plugins/storage-providers/Google-Drive/google-drive-provider.service';
import { LocalDiskStorageProvider } from './data/dataService/plugins/storage-providers/LocalDisc/local-disk-provider.service';
import { LocalStorageStorageProvider } from './data/dataService/plugins/storage-providers/LocalStorage/local-storage-provider.service';
import { TreeComponent } from './view/components/tree/tree.component';
import { OkCancelTextInputDialogComponent } from './view/components/ok-cancel-text-input-dialog/ok-cancel-text-input-dialog.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatSliderModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatDialogModule,
    // MyLibComponent
    // RouterModule.forRoot([
    //   { path: '', component: MindStackComponent },
    // ])

    // solve error: https://stackoverflow.com/questions/49039457/angular-error-no-provider-for-activatedroute
    // Remark: because you haven't defined any routes, I have to pass an empty
    // route collection to forRoot, as the first parameter is mandatory.
    RouterModule.forRoot([])
  ],

  declarations: [
    AppComponent,
    TopBarComponent,
    HelpComponent,
    TaskEditorComponent,
    DropdownComponent,
    HamburgerComponent,
    DoingComponent,
    DoneComponent,
    NoteEditorComponent,
    TimersComponent,
    DownloadAnchorComponent,
    LoadFileFromDiscComponent,
    TextInputComponent,
    TreenodeContentComponent,
    TreeComponent
    , OkCancelTextInputDialogComponent
  ],

  // These are providers that can be used for injection by the DI-framework.
  providers: [
    // The shorthand way to use a class (below: SimpleTimer) as the DI-provider for itself.
    // SimpleTimer  --example

    //    APPLICATION WIRE-UP
    //
    //    ASSIGN CONCRETE IMPLEMENTATIONS TO GENERIC (interface) DEPENDENCIES
    //
    //    Below is the concretive-part of the wire-up of the application:
    //    dependencies (e.g. interfaces) that are injected into dependents
    //    (like components or services) are here, and only here, assigned
    //    a concrete implementation.
    //
    //    Wiring interfaces as dependencies of components, services and
    //    the like can be found in the constructors of those components and
    //    services.

    , { provide: ITKN_ILOGSERVICE, useClass: LoggerService }
    , { provide: ITKN_IDATASERVICE, useClass: DataService1 }
    , { provide: ITKN_IDEMODATASERVICE, useClass: DemoDataService1 }
    , { provide: ITKN_ISTORAGESERVICE_GDRIVE, useClass: GoogleDriveStorageProvider }
    , { provide: ITKN_ISTORAGESERVICE_LOCALDISC, useClass: LocalDiskStorageProvider }
    , { provide: ITKN_ISTORAGESERVICE_LOCALSTORAGE, useClass: LocalStorageStorageProvider }

    , { provide: ITKN_ITIMERSERVICE, useClass: TimerService1 }
    , { provide: ITKN_IGOOGLEAUTHORIZATIONSERVICE, useClass: GoogleAuthorizationService1 }


    // Note some strange implication of next line: this token is also used WITHIN the model for injecting
    //    a durationFactory into the (concrete) taskfactory. That means that from outside /model we can
    //    influence its internal (concrete) wiring. Does that make sense? As long as the model is programmed
    //    against interfaces, everything is fine. But those cases where a concrete factory is used within
    //    the model, shouldn't that be the same one as we are injecting here? In other words: if we inject
    //    the durationfactory into the model, should it be injected into everywhere in the model?
    //
    , { provide: ITKN_IDURATIONFACTORY, useClass: DurationFactory }
    , { provide: ITKN_ITIMEFACTORY, useClass: TimeFactory }

    // taskfactory (experimental)
    // different services, like the demodataservice, may get an alternative taskfactory
    //   implementation but note that the token is still for the same interface (although one cannot
    //  see that from here). The idea is that you can, if you whish, easily assign different
    //  concrete implementations although all must conform to that same one interface.
    //
    //  Example, maybe realistic: a timed task for a demoservice may be full blown with timers
    //  attached and ignited, while a timed task for a dataservice should be just in an initial
    //  state. Notice that this is not necessary, because the demodataservice itself is also
    //  candidate for doing all bells and whistles itself. But you get the idea.
    , { provide: ITKN_ITASKFACTORYSERVICE, useClass: TaskFactory }
    , { provide: ITKN_ITASKFACTORYSERVICE_for_DEMODATASERVICE, useClass: TaskFactory }
    , { provide: ITKN_ITASKFACTORYSERVICE_for_STORAGESERVICE, useClass: TaskFactory }

    , DoingService
    , Title
  ],

  // The component between the brackets is the root component of this application
  bootstrap: [AppComponent]
})
export class AppModule {

}
