import { IDuration } from '../duration/iduration';
import { ITime } from '../time/itime';
import { ArgumentOutOfRangeError } from 'rxjs';
import { TimingPatternEnum } from '../timingPattern/item-timing-pattern.enum';
import { ItemTimingPatternRegexp } from '../timingPattern/item-timing-pattern-regexp';
import { ITimingPattern } from '../timingPattern/itiming-pattern';
import { TimingPatternInfo } from '../timingPattern/timing-pattern-info';
import { Inject } from '@angular/core';
import { IDurationFactory } from '../duration/iduration-factory';
import { ITimeFactory } from '../time/itime-factory';
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { IPattern } from '../../shared/ipattern';
import { ITKN_IDURATIONFACTORY, ITKN_ITIMEFACTORY, ITKN_ILOGSERVICE } from 'src/app/application/injectionTokens';


export class MyScheduleInterpreter { // implements IScheduleInterpreter {

  constructor(
    @Inject(ITKN_IDURATIONFACTORY) public durationFactory: IDurationFactory
    , @Inject(ITKN_ITIMEFACTORY) public timeFactory: ITimeFactory
    , @Inject(ITKN_ILOGSERVICE) public logger?: ILogService
  ) { }

  /*

  without stop:
  0015                  Duration                                        (in 15 minutes)
  0015am                ClockTime                                       (at a quarter past midnight)
  rpt 0010              Repetitive Duration                             (every 10 minutes)
  0002 rpt 0015         Repetitive Duration With Duration Offset        (in 2 minutes, repeat every 15 minutes)
  0700am rpt 0100       Repetitive Duration With Clocktime Offset       (at 7 'o clock, repeate every hour)

  with stop (not yet implemented):
  0005 rpt 0045 times 8
  0005 rpt 0045 during 0200
  0005 rpt 0045 til 1700pm
  */


  /** @description Recognizes a timingpattern in userinput
   */
  public recognizeTimingPattern(userinput: string, base?: Date): TimingPatternInfo {

    // Assume 'now' if no base has been given
    const base2 = base ? new Date(base) : new Date();   // make a copy of the param, because it's a reference!

    const patterns: Array<ItemTimingPatternRegexp> = new Array<ItemTimingPatternRegexp>();

    // #region Some help on interpreting the regular expressions below:
    /*
        '/expression/' is equivalent to new 'Regexp(expression)'.

        \s = space
        \s? = optional space
          So, the ? always refers to its preceding expression.

        \d = digit

        $ = einde

        (x) = begrijp ik nog niet 100%, maar als je een OR wilt doen pas ze dan vooral toe, dus (rpt|repeat).
            Daarnaast is dit 'capturing', dus je kunt er naar terugverwijzen bij een replaceactie, en van alles dat binnen haakjes
            staat wordt een lijst opgeleverd met de matchende waardes. Daarvan maak ik hieronder gebruik want ik krijg dus keurig
            een lijstje terug met de hh, mm, ampm, rpt etc van een pattern als dit: "hh:mmPM repeat hh:mm" => [hh, mm, PM, repeat, hh, mm].
    */
    // #endregion

    // Important note: the order of the patterns is crucial because 'lower' patterns include 'higher' patterns.
    // For example: the Repetitive-Duration pattern is more specific than non-repetitive duration pattern.
    // So, apart from all non-repetitive duration patterns, Duration matches also all repetitive duration patterns.

    // 0700am rpt 0100: at 7 'o clock, repeate every hour
    patterns[0] = new ItemTimingPatternRegexp(TimingPatternEnum.RepetitiveDurationWithClockTimeOffset,
      /(\d?\d):?(\d\d)\s?(am|pm)\s?(rpt|repeat)\s?(\d?\d):?(\d\d)$/);

    // 0002 rpt 0015: in 2 minutes, repeat every 15 minutes
    patterns[1] = new ItemTimingPatternRegexp(TimingPatternEnum.RepetitiveDurationWithDurationOffset,
      /(\d?\d):?(\d\d)\s?(rpt|repeat)\s?(\d?\d):?(\d\d)$/);

    // 0002 rpt 0015: in 2 minutes, repeat every quarter.
    patterns[2] = new ItemTimingPatternRegexp(TimingPatternEnum.RepetitiveDuration, /(rpt|repeat)\s?(\d?\d):?(\d\d)$/);

    // 09:15am : at a quarter past nine.
    patterns[3] = new ItemTimingPatternRegexp(TimingPatternEnum.ClockTime, /(\d?\d):?(\d\d)(am|pm)$/);

    // 0030: in 30 minutes
    patterns[4] = new ItemTimingPatternRegexp(TimingPatternEnum.Duration, /(\d?\d):?(\d\d)$/);

    // Search for a pattern in the userinput
    // eslint-disable-next-line prefer-const
    for (let pattern of patterns) { // tslint:disable-line:prefer-const
      if (pattern.Regexp.test(userinput)) {
        return this.buildTimingPatternInfoFromUserinput(userinput, pattern, base2);
      }
    }

    // #region setup empty result
    // below: refactor this returning of an empty result into a factory of something like that.

    // when we end up here, no pattern matched.
    // Note, technical: by creating an anonymous object and assigning it to an interface we can return an object that
    // implements an interface without having to depend on an explicit class.
    const tp: ITimingPattern = {
      pattern: TimingPatternEnum.None,
      delay: this.durationFactory.createDuration(0, 0, 0),
      repeat: this.durationFactory.createDuration(0, 0, 0),
      hasDelay: false,
      isRepetitive: false
    };

    const tprr: TimingPatternInfo = new TimingPatternInfo();
    tprr.inputText = userinput;
    tprr.outputTextArray = [];
    tprr.recognizedPattern = { pattern: 0 } as IPattern;
    tprr.timingpattern = tp;
    // #endregion

    return tprr;
  }

  /**
   * @description helper-method for patternrecognition: assumes that the timing-pattern in
   * the userinput is known (recognized). It blindly applies the given regexp to the input
   * and builds a TimingPatternInfo on the results.
   */
  public buildTimingPatternInfoFromUserinput(
    userinput: string, itemTimingPatternRegexp: ItemTimingPatternRegexp , base?: Date ): TimingPatternInfo {

    // Assume 'now' if no base has been given
    const base2 = base ? new Date(base) : new Date();   // make a copy of the param, because it's a reference!

    // Initialize the pattern
    // Note, technical: by creating an anonymous object and assigning it to an interface we can return an object that implements
    // an interface without having to depend on an explicit class. Note, however, that properties that should contain logic must
    // be recreated! Therefor this pattern is in fact only suitable for dto-kind of objects.

    const tp: ITimingPattern = {
      pattern: TimingPatternEnum.None,
      delay: this.durationFactory.createDuration(0, 0, 0),
      repeat: this.durationFactory.createDuration(0, 0, 0),
      hasDelay: false,
      isRepetitive: false,
    };

    // the regexp has already determined what pattern it is so we rely on that.
    tp.pattern = itemTimingPatternRegexp.ItemTimingPattern;

    const regexpOutputArray: RegExpExecArray = itemTimingPatternRegexp.Regexp.exec(userinput);
    const outputNewTodoContents: string = userinput.replace(itemTimingPatternRegexp.Regexp, '').trim();

    // #region debug
    // console.log('patroon: ' + result.pattern.toString());
    // console.log(value + '=> ' + regexpOutputArray);
    // let v: string = regexpOutputArray[1];
    // let w: string = regexpOutputArray[2];
    // let x: string = regexpOutputArray[3];
    // let y: string = regexpOutputArray[4];
    // let z: string = regexpOutputArray[5];
    // console.log('1-' + v + ',   2-' + w + ',   3-' + x + ',   4-' + y + ',   5-' + z );
    // console.log('1-' + Number(v) + ',   2-' + Number(w) + ',   3-' + Number(x) + ',   4-' + Number(y) + ',   5-' + Number(z) );
    // #endregion

    // a few examples of regexpExecArrays, per pattern
    // pattern                                    [0]:userinput       Other array elements
    // Duration        ...................     => 0015                ,00,15
    // ClockTime          ................     => 00:15pm             ,00,15,pm
    // RepetitiveDuration       ..........     => rpt 0:15            ,rpt,0,15
    // RepetitiveDurationWithDurationOffset    => 0700rpt0100         ,07,00,rpt,01,00
    // RepetitiveDurationWithClockTimeOffset   => 0700pm repeat 0100  ,07,00,pm,repeat,01,00
    //
    // As you can see for each pattern a specific set of elements is returned in the array.


    let meridiemCode: string;     // note: 12:00am=midnight, 12:00pm=noon.
    let clocktime: ITime;

    switch (itemTimingPatternRegexp.ItemTimingPattern) {

    case TimingPatternEnum.Duration:
      tp.delay = this.durationFactory.createDuration(Number(regexpOutputArray[1]), Number(regexpOutputArray[2]), 0);
      break;

    case TimingPatternEnum.ClockTime:

      // Convert clocktime to a 'duration-until-that-clocktime'.
      clocktime = this.timeFactory.createTime(Number(regexpOutputArray[1]), Number(regexpOutputArray[2]), 0);
      meridiemCode = regexpOutputArray[3];
      tp.delay = this.calculateDurationUntilClocktime(clocktime, meridiemCode, this.durationFactory.createDuration(0, 0, 0), base2);
      break;

    case TimingPatternEnum.RepetitiveDuration:
      tp.repeat = this.durationFactory.createDuration(Number(regexpOutputArray[2]), Number(regexpOutputArray[3]), 0);
      break;

    case TimingPatternEnum.RepetitiveDurationWithDurationOffset:
      tp.delay = this.durationFactory.createDuration(Number(regexpOutputArray[1]), Number(regexpOutputArray[2]), 0);
      tp.repeat = this.durationFactory.createDuration(Number(regexpOutputArray[4]), Number(regexpOutputArray[5]), 0);
      break;

    case TimingPatternEnum.RepetitiveDurationWithClockTimeOffset:

      // Convert clocktime to a 'duration-until-that-clocktime'.
      clocktime = this.timeFactory.createTime(Number(regexpOutputArray[1]), Number(regexpOutputArray[2]), 0);
      meridiemCode = regexpOutputArray[3];
      tp.delay = this.calculateDurationUntilClocktime(clocktime, meridiemCode, this.durationFactory.createDuration(0, 0, 0), base2);
      tp.repeat = this.durationFactory.createDuration(Number(regexpOutputArray[5]), Number(regexpOutputArray[6]), 0);
      break;
    }

    tp.hasDelay = !tp.delay.isInitial;        // although these props are computed, that doesn't work for anonymous objects that
    // instantiate the interface (which we do in the first line of this method).
    tp.isRepetitive = !tp.repeat.isInitial;

    // setup some readeable info about the created timer
    const now = new Date();
    let outputTimingInfo: string = 'Timed todo, created: ' + String(now);
    if (tp.isRepetitive) {
      const repetition: string = tp.repeat.getFormatted(true);
      outputTimingInfo += '\n- Repeats each ' + repetition;
    }

    // fill in result
    const result: TimingPatternInfo = new TimingPatternInfo();
    result.inputText = userinput;
    result.outputTextArray = [outputNewTodoContents, outputTimingInfo];
    result.recognizedPattern = { pattern: tp.pattern } as IPattern;
    result.timingpattern = tp;

    return result;
  }

  /** @description Calculates how long it takes (=duration) until a given clocktime
   *  passes, from base (if no base is given, the current datetime is assumed).
   * - meridiem code = 'am' or 'pm'. Overrules hour quantity if inconsistent (13:00am
   *    becomes 01:00am).
   * - the caller should provide an IDuration object.
   */
  public calculateDurationUntilClocktime(clocktime: ITime, meridiemCode: string, durationINJECTED: IDuration, base?: Date): IDuration {

    // First, make it a 24h time
    if (meridiemCode === 'pm' && clocktime.hours < 12) {
      clocktime.hours += 12;
    }

    if (meridiemCode === 'am' && clocktime.hours >= 12) {
      clocktime.hours -= 12;
    }

    // Assume 'now' if no base has been given
    const base2 = base ? new Date(base) : new Date();   // make a copy of the param, because it's a reference!

    // Then, determine the full DateTime and calculate the duration between now and that datetime, in hh:mm.
    const deadline: Date =
      this.getNextClocktimeOccurrence(this.durationFactory.createDuration(clocktime.hours, clocktime.minutes, 0), base2);
    return this.getDurationBetweenDatetimes(base2, deadline, durationINJECTED);
  }

  /** @description Returns the number of hours/minutes between two datetimes. Returns an ITime which the caller must provide.
   * - low resolution, seconds and smaller units are lost.
   * - the caller should provide an IDuration object.
   */
  public getDurationBetweenDatetimes(start: Date, end: Date, durationINJECTED: IDuration): IDuration {

    // validate
    if (end < start) {
      throw new ArgumentOutOfRangeError();
    }

    const diffInMiliseconds: number = (end.getTime() - start.getTime());
    durationINJECTED.hours = Math.floor(diffInMiliseconds / 1000 / 3600);
    const rest: number = diffInMiliseconds - (durationINJECTED.hours * 1000 * 3600);
    durationINJECTED.minutes = Math.floor(rest / 1000 / 60);
    return durationINJECTED;
  }

  /** @description Given an ITime and a base-datetime this method adds Date-info such that it's
   *  the nearest occurence of that time in the future (with 'future' relative to 'base').
   *    If no base is given, the current datetime is assumed.
   *  It answers a question like: when will it be 19:15? (answer: if the current time
   *  is 18:00, then in 1 hour and 15 minutes; if the current time is 19:30, then you have to
   *  wait till tomorrow (23 hours and some minutes).
   */
  public getNextClocktimeOccurrence(clocktime: ITime, base?: Date): Date {

    // default (=base)
    const result: Date = base ? new Date(base) : new Date();   // make a copy of the param, because it's a reference!

    // determine whether the clocktime has passed today (if yes, set the date to tomorrow).
    let mustAddDay = false;
    if (clocktime.hours < base.getHours() || (clocktime.hours === base.getHours() && clocktime.minutes <= base.getMinutes())) {
      mustAddDay = true;
    }
    if (mustAddDay) {
      result.setDate(result.getDate() + 1);
    }

    result.setHours(clocktime.hours);
    result.setMinutes(clocktime.minutes);

    return result;
  }
}
