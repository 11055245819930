/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// #region imports

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-underscore-dangle */

// angular deps
import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UUID } from 'angular2-uuid';
import { Title } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

// ccc deps
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';

// internal deps
import { DoneComponent } from '../done/done.component';
import { PropertyChangedEventArgs } from '../shared/property-changed-event-args';
import { TaskChangedEventArgs } from '../shared/task-changed-event-args';
import { ISoundService } from './contracts-for-plugins/sound.service.interface';
import { FocusService } from '../../shared/focusService/focus.service';
import { SoundService } from '../../shared/soundService/sound.service';
import { ListOfTasksService } from '../../shared/listOfTasksService/listOfTasks.service';
import { DownloadAnchorComponent } from '../download-anchor/download-anchor.component';

// model deps
import { IImplements, Semantics } from 'src/app/model/api/api.shared';
import {
  ITask,
  IDataService,
  TaskStatusChangedEventArg,
  TaskStatus,
  TaskArrayHelper,
  ITimedTask,
  IDuration,
  TaskCopier,
  TimeComputer,
  IDurationFactory,
  ITimerService,
  ISettings,
  TimedTaskHelper
} from 'src/app/model/api/api';
import { SupportedStorageMedia } from 'src/app/model/supported-storage-media';

// application deps
import {
  ITKN_IDATASERVICE,
  ITKN_ILOGSERVICE,
  ITKN_IDURATIONFACTORY,
  ITKN_ISOUNDSERVICE,
  ITKN_IFOCUSSERVICE,
  ITKN_ITIMERSERVICE,
  ITKN_ILISTOFTASKS
} from 'src/app/application/injectionTokens';
import { TimedTaskSubscriptionInfo } from 'src/app/model/timing and duration/timedTaskHelper/timed-task-subscription-info';
import { IUserStorageMediaConfiguration } from './contracts-for-plugins/user-storage-media-configuration.interface';
import { TreeComponent } from '../../components/tree/tree.component';
// #endregion



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    { provide: ITKN_IFOCUSSERVICE, useClass: FocusService }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    , { provide: ITKN_ISOUNDSERVICE, useClass: SoundService }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    , { provide: ITKN_ILISTOFTASKS, useClass: ListOfTasksService }
  ]
})
/** This app is built according to the component-style architecture,
 * i.e. childcomponents do not change data themselves but they pro-
 * pagate requests for changes to their parent component. Ultimately
 * the main component, this one, applies the changes.
 * That's why you see @ViewChild decorators and a region 'process
 * events from childcomponents'. The current childcomponents are: a
 * top-bar, a help-screen, a doing-stack, a done-list and more will
 * follow.
 */
export class AppComponent implements AfterViewInit {


  // #region Main data -------------------------------------------------------------------------------------------------

  /** @description The main data of this app.
   */
  // private get data(): ITask[] { return this.publisher.value; }

  /** @description Publicist of new data.
   */
  private _dataPublisher: BehaviorSubject<ITask[]> = new BehaviorSubject([]);
  public dataPublisher: Observable<ITask[]> = this._dataPublisher.asObservable();

  /** @description Publicist of changed settings.
   */
  private _settingsPublisher: BehaviorSubject<ISettings> = new BehaviorSubject({} as ISettings);
  public settingsPublisher: Observable<ISettings> = this._settingsPublisher.asObservable(); // new Observable();
  // #endregion


  settings: ISettings;
  data: ITask[];
  init = false;

  /** the currently activated doing (it's not necessarily the one on top, but it's the one that has focus) */
  focussedDoing: ITask = null;


  // #region Child components -------------------------------------------------------------------------------------------------

  @ViewChild(DoneComponent, {}) doneComponent: DoneComponent;

  @ViewChild(DownloadAnchorComponent, {}) downloader: DownloadAnchorComponent;

  @ViewChild('todoTree', {}) todoTree: TreeComponent;

  @ViewChild('docuTree', {}) docuTree: TreeComponent;

  // #endregion



  // #region Init and main functions -------------------------------------------------------------------------------------------------

  constructor(
    @Inject(ITKN_ILOGSERVICE) public logger: ILogService
    , @Inject(ITKN_IDURATIONFACTORY) public durationFactory: IDurationFactory
    , @Inject(ITKN_IDATASERVICE) public dataservice: IDataService   // view goes directly to dataservice: that's wrong?
    , @Inject(ITKN_ITIMERSERVICE) public timerservice: ITimerService
    , @Inject(ITKN_ISOUNDSERVICE) public soundservice: ISoundService
    , private title: Title
    , private snackBar: MatSnackBar
  ) {
    dataservice.dataPublisher.subscribe(data => {

      // when we get an edition from the dataservice, we immediately
      //    publish it for our subcomponent subscribers
      this.data = data;

      this._dataPublisher.next(data);

      if (this.settings) {
        this.title.setTitle(this.settings.tag);
      }
    });

    dataservice.settingsPublisher.subscribe(settings => {
      this.settings = settings;
      this._settingsPublisher.next(settings);
      this.setBackgroundColor();
      this.soundservice.DefaultVolume = this.settings.soundVolume;  // pass the volume to the soundservice.
      this.title.setTitle(this.settings.tag);
    });
  }

  ngAfterViewInit(): void {

    this.init = true;
  }

  /** Applies the given background: the number refers to the colors defined in Styles.scss (color1, color2, ...).
   * If no color is given, the one from the settings is applied.
   */
  public setBackgroundColor(colorNr: number = this.settings.color): void {
    console.log('set bg color');

    const bd = document.getElementsByTagName('body')[0];
    // remove all possible classes
    let i = 1;
    while (i < 50){ // support up to 50 colors, defined in Styles.scss.
      bd.classList.remove('color' + String(i));
      i++;
    }
    // apply the one asked for.
    bd.classList.add('color' + String(colorNr));
  }


  // #endregion



  // #region Process events from childcomponents --------------------------------------------------------------------------------------


  // #region Respond to top-bar resize event

  /**
   * @description Represents the height of the top-bar: this height is
   * dynamic (depending on device or browser-window-resizing).
   */
  private topBarHeight: number;

  /**
   * @description Property that represents the height of the top-bar. This property is watched by an ngStyle on the wrapper element
   * in the .html, in order to vertically position the main elements under the top-bar.
   *
   * @see topBarHeight
   */
  public get TopBarHeight(): number {
    return this.topBarHeight;
  }

  private set TopBarHeight(value: number) {
    this.topBarHeight = value;
  }

  /**
   * @description Responds when the topbar notifies its new height.
   */
  receiveTopbarHeightChange(args: number): void {

    // A measure to prevent expressionchangedafterithasbeencheckederror.

    // the error is caused in commit 685860c8a988be47378561becb312925267d2291
    // i.e. "Dynamic top Doingstack when Topbar height change" (tag: release-9-6-2022-DynamicTop).

    // The cause of the error is that parent is initialized after its
    // children are initialized, and that in this case the children
    // change parent-properties. Although that is not against all rules,
    // it's clear that the parent changes during its initialization.
    // We solve that by applying a timeout which causes the update
    // to wait till next check-cycle.

    // Note: in theory the below can go a bit wrong when very fast init
    // changes from false to true while heights are coming in: because
    // of the timeout the first height may be applied last, but
    // I'll await the tests.

    if (!this.init) {
      setTimeout(() => {
        this.TopBarHeight = args;
      });
    } else {
      this.TopBarHeight = args;
    }
  }
  // #endregion


  /** @description Process changerequests for settings such as soundvolume.
   */
  receiveSettingChangeRequest(args: PropertyChangedEventArgs): void {

    // Todo: setting manipulation also via dataservice? Base that decision
    // on whether knowledge about settings (and their changes) will be
    // shared across components/application parts.

    // Process soundvolume changerequest
    if (args.propertyname === 'soundvolume') {
      if (args.newValue && Number(args.newValue) && Number(args.newValue) >= 0 && Number(args.newValue) <= 100) {
        this.settings.soundVolume = args.newValue;          // this one is saved on backup
        this.soundservice.DefaultVolume = args.newValue;    // this one is effective while running
      }
      else {
        this.logger.log('Soundvolume request out of boundaries. Must be between 0 and 1.0');
      }
    }
    else if (args.propertyname === 'hideDoneTasks') {
      this.dataservice.saveSetting$('hideDoneTasks', args.newValue, 'todo');
    }
    else if (args.propertyname === 'storagemediumconfiguration') {
      // this.settings.storageMedia = JSON.parse(args.newValue);
      console.log('Not yet supported setting: storagemediumconfiguration');
    }
    else if (args.propertyname === 'tag') {

      // only change if it's a valid tag
      const validFilenamePart = /^[\w-]+$/;    // match a-z, A-Z, numbers and _. Match also -.
      if (args.newValue && args.newValue.match(validFilenamePart)) {
        this.settings.tag = args.newValue;
        this.logger.log('New tag accepted.');
      } else {
        this.logger.log('Tag request denied: invalid tag (make sure the tag has no spaces and is valid as part of a filename): '
          + String(args.newValue));
      }
      this.logger.log('current app tag: ' + String(this.settings.tag));
    }
    else if (args.propertyname === 'expandedNodeSet') {
      // expandedNodeSet is updated, so save it to the given object (which is settings.todo or settings.documentation, both tree-objects).
      args.entity[args.propertyname] = args.newValue;
    }
  }

  /**
   * @description new stack loaded i.e. clean new data has been loaded, so do some default UI actions.
   */
  private afterNewStackLoaded(): void {
    this.hideHistory();
    this.restoreTreeComponentsExpansionState();
    this.initNewTimers();
  }


  onNotificationFromTopBar(event: any): void {
    if (event === 'help') {
      if (!this.isHelpscreenVisible) {
        this.showHelpscreen();
      } else {
        this.hideHelpscreen();
      }
    } else if (event === 'save') {
      this.saveMindstack();
    } else if (event === 'restore') {
      if (this.settings.tag === 'DEFAULT') {
        this.loadFromCache(true); // load ms with latest saved tag
      } else {
        this.loadFromCache();
      }
    } else if (event === 'darktheme') {
      document.getElementsByTagName('h1')[0].classList.add('color' + String(this.settings.color));

      // Het moet eigenlijk dit worden, al werkt het niet:
      // document.getElementById('taginput').classList.add('color' + String(this.settings.color));
    }
    else if (event === 'userSignsIntoGoogle' || event === 'userAboutToSave') {
      // console.log('app: got notification that either saves or signs into google, now saving expansion state of tree controls.');
      this.saveTreeComponentsExpansionState();
    }
    else if (event === 'newStackLoaded') {
      this.afterNewStackLoaded();
    }
    else if (event === 'setBackgroundColor') {
      this.setBackgroundColor();
    }
  }


  onNotificationFromHelpscreen(event: any): void {
    if (event === 'hideHelp') {
      this.hideHelpscreen();
    }
  }

  onNotificationTaskGotFocus($event: ITask): void {
    this.focussedDoing = $event;
  }

  /** @description Text is entered by the user and the receiving component
   * sends the data to here. Do some validations and preparations and add the
   * task to the main data.
   */
  receiveNewTask(args: TaskStatusChangedEventArg): void {

    const task = args.task;

    // if (task.status === TaskStatus.ToDo) {
    //   task.status = TaskStatus.Unknown; // put in unknown state to prevent timed tasks to be timed twice.
    // }

    // Validation
    // - 'done' or 'cancelled' tasks cannot get subtasked. => this is relevant when a task is created as subtask from the todo-tree?
    if (task.status === TaskStatus.Done ||
      task.status === TaskStatus.Cancelled ||
      TaskArrayHelper.getParentsRecursively(task, this.dataservice.data)
        .some(x => x.status === TaskStatus.Done || x.status === TaskStatus.Cancelled)) {


      this.logger.log('Validation-error: done/cancelled tasks are not allowed new subtasks. (BR-1001)');
      return;   // just return.
    }

    // most callers will set datecreated themselves, so only set
    // it when it's not been set before.
    if (!task.dateCreated) {
      task.dateCreated = new Date();    // task is not yet in the dataservice so we are allowed to change it here.
    }

    if (this.dataservice.create(task)) {

      // if a task is OK and added and it has timing info attached,
      //    we initiate a timer according to the settings in the task.
      if (IImplements.ITimedTask(task)) {
        this.dataservice.update(
          task
          , 'status'
          , TaskStatus.ToDo
        );  // push it to the timers-list

        this.initNewTimers();    // could create the timers here, but centralized code is better.
      }
    }
    this.saveToCache();
  }

  /** @description Subscribes the task to a timer (the timername, as all other
   * info about the timing, is supposed to be in the task itself).
   *
   * If timerName is not provided, the task's timerName is used.
   */
  private subscribeToTimer(task: ITimedTask, timerName?: string) {

    // First determine timername situation
    if (!timerName && !task.timerName) {
      // eslint-disable-next-line max-len
      throw new Error('TimerName is required for subscribing a task to a timer: either by param timername or by param task which has a timername property.');
    }

    const _timerName: string = timerName ?? task.timerName;

    // Now subscribe task to timer

    // Note that subscription happens in all current known timing parameter-
    //    scenario's: whether delayed, repetitive, paused or stopped.

    const callback: () => void = () => this.fireScheduledTask(task);

    // use a helper to create a timer, yet without subscribing
    const helper = new TimedTaskHelper(this.durationFactory, this.timerservice, this.logger);
    const info: TimedTaskSubscriptionInfo = helper.subscribeToTimer(task, callback, _timerName);

    // Now we copy all info to the task, and only the last one we trigger
    //  the update by the dataservice. This is cheating but alas.
    info.copyToTask(task);

    // fake update to trigger broadcast. -- TODO: test of dit echt nodig is, lijkt er nl niet op.
    this.dataservice.update(
      task
      , 'timerName'
      , task.timerName
    );
  }


  /** Callback function for timers: fires when a task must be executed.
   * Pushes a task to the doing-stack, either itself or a copy.
   */
  public fireScheduledTask(task: ITimedTask): void {

    if (!task.timerSubscriptionId) {
      console.error('AppComponent.firescheduledtask: no timing info set?');
      return;
    }

    if (!task.timingPatternInfo.timingpattern.isRepetitive) {

      // Fire-once tasks.

      // change the status of the task and remove its timing data.
      //    We keep some info for history.
      task.status = TaskStatus.Doing;
      task.dateStarted = new Date();
      this.timerservice.unsubscribe(task.timerSubscriptionId);
      this.timerservice.deleteTimer(task.timerName);
      task.timerSubscriptionId = null; // remove timer info, otherwise the doing-stack will not show this task.
      task.timerName = null;
      // To trigger a broadcast of the changes by the dataservice
      //  we call tryUpdate on a more ore less random property.
      this.dataservice.update(task, 'content', task.content);    // fake change

    } else {

      // Repetitive timer-tasks.

      // compute next tick
      const timecomputer = new TimeComputer(this.durationFactory, this.logger);
      task.nextTimerTick = timecomputer.addDurationToDate(new Date(), task.timingPatternInfo.timingpattern.repeat);

      // Add tick info to the note. We use a standard text which we continuously
      //    find-and-replace. The pattern starts with 'Next time ...' and ends
      //    with '... sharp.'. The 'sharp' just functions as an end-marker.
      if (task.note.match(/Next time occurs at.*\) sharp\./)) {
        task.note = task.note.replace(/Next time occurs at.*\) sharp\./, 'Next time occurs at: ' + String(task.nextTimerTick) + ' sharp.');
      } else {
        task.note = 'Next time occurs at: ' + String(task.nextTimerTick) + ' sharp.\n' + String(task.note);
      }

      // create a copy of the current task, 'push' it to the doing-stack.
      const newtask: ITask = this.dataservice.getNewTask();
      new TaskCopier().copyStoneAge(task, newtask);
      newtask.id = UUID.UUID();
      newtask.parentId = task.id;
      newtask.dateStarted = new Date();
      newtask.status = TaskStatus.Doing;
      newtask.note = 'Triggered by a timer at ' + String(newtask.dateStarted) + '.';

      // if (IImplements.ITimedTask(newtask)) {
      //   newtask.timerSubscriptionId = null;  // remove timer info, otherwise the doing-stack will not show this task.
      // }

      this.dataservice.create(newtask);
    }

    this.soundservice.playTimerAlarmSound();
  }


  /** @description Process event task-status-change by the user:
   * do some validations and notify the children.
   */
  receiveTaskStatusChanged = (event: TaskStatusChangedEventArg): void => {
    this.processTaskStatusChangeRequest(event.task, event.newStatus, event.reasonBlocked);
    this.saveToCache();
  };


  /** @description Process event task-change by the user: do some
   * validations and notify the children.
   */
  receiveTaskChanged = (event: TaskChangedEventArgs): void => {
    this.processTaskChangeRequest(event.originalTask, event.changedTask);
    this.saveToCache();
  };


  /** @description Process event task-property-change by the user: do some
   * validations and notify the children.
   */
  receiveTaskPropertyChanged = (event: PropertyChangedEventArgs): void => {
    this.processTaskPropertyChangeRequest(event);
    this.saveToCache();
  };


  /** @description Process mindstack-file opened by user (from disc or gDrive).
   */
  receiveFileOpenRequest = (args: any): void => {

    // first try to load the settings
    this.dataservice.importSettings(args);

    // then upload the data
    if (this.dataservice.importData(args)) {
      this.afterNewStackLoaded();
    }
  };

  // #endregion



  // #region Help-screen ------------------------------------------------------------------------------------------------------------------

  private _showHelpscreen = false;

  public get isHelpscreenVisible(): boolean {
    return this._showHelpscreen;
  }

  public showHelpscreen(): void {
    this._showHelpscreen = true;
  }

  public hideHelpscreen(): void {
    this._showHelpscreen = false;
  }
  // #endregion



  // #region Caching, restoring, download & upload ----------------------------------------------------------------------------------------

  /** @description Backup to the browser's localStorage.
   */
  public saveToCache(): void {
    this.dataservice.saveToCache();
  }

  /** @description Restore from the browser's localStorage.
   */
  public loadFromCache(latest = false): void {

    // upload the backup into the app
    if (this.dataservice.loadFromCache(latest)) {
      this.afterNewStackLoaded();
    } else {
      this.logger.log('Nothing restored.');
    }
  }

  /** Save the mindstack according to the storage-settings in the mindstack itself. */
  public saveMindstack(): void {

    // get storagesettings
    const storageSettings: IUserStorageMediaConfiguration = this.dataservice.getStorageSettings();

    switch (storageSettings.primaryProvider.medium.id) {
    case SupportedStorageMedia.GDrive.id: {

      // either create or update

      // First, we create a backup on the secondary storage medium (todo: make it more flexible)
      if (storageSettings.secondaryProvider.medium.id === SupportedStorageMedia.LocalDisc.id) {
        this.downloadMindstackToDisc();
      }

      if (!storageSettings.primaryProvider.externalId){

        this.dataservice.createOnDrive().subscribe({
          next: (response) => {
            const responseObj = JSON.parse(response);
            this.snackBar.open(`Created on drive: ${responseObj['name']}`, null, { duration: 3000, verticalPosition: 'top' });
            console.log(`Created on gdrive: ${String(response)}`);
          },
          error: (err) => {
            this.snackBar.open(`Failed create file on drive. Error: ${String(err)}`, null, { duration: 3000, verticalPosition: 'top' });
            console.log(`Error create file on gdrive. Fall back to saving to local disc. Error: ${String(err)}`);
            this.downloadMindstackToDisc();
          }
        });
      }
      else {

        this.dataservice.updateOnDrive(storageSettings.primaryProvider.externalId).subscribe({
          next: (response) => {
            this.snackBar.open('Saved to drive.', null, { duration: 3000, verticalPosition: 'top' });
            console.log(`Updated on gdrive: ${String(response)}`);

          },
          error: (err) => {
            this.snackBar.open(`Failed to save to drive. Error: ${String(err)}`, null, { duration: 3000, verticalPosition: 'top' });
            console.log(`Error update file on gdrive. Fall back to saving to local disc. Error: ${String(err)}`);
            this.downloadMindstackToDisc();
          }
        });
      }
      break;
    }
    default: {
      console.log('No storageprovider configured. Fall back to saving to local disc.');
      // fall through to SupportedStorageMedia.LocalDisc
    }
    case SupportedStorageMedia.LocalDisc.id: {
      this.downloadMindstackToDisc();
      break;
    }
    }

    // misschien wil ik hier eigenlijk: dataservice.save().
    //    dat later doen.
  }

  /** @description Download all current data to a file on your harddisc.
   */
  public downloadMindstackToDisc(): void {

    const filename: string =
      'whatiamdoing-backup-' +
      String(this.dataservice.getTimeStampString()) +
      '-' +
      String(this.settings.tag.toUpperCase()) +
      '.txt';

    this.dataservice.downloadToDisc(this.downloader, filename);
  }
  // #endregion



  // #region Process ChangeRequests -------------------------------------------------------------------------------------------------------

  /** @description Fires when a childcomponent throws up a request
   * to change a single property of a task.
   */
  private processTaskPropertyChangeRequest = (args: PropertyChangedEventArgs): void => {

    const task: ITask = this.dataservice.data.find(x => x.id === args.entity.id);
    if (task) {

      // handle 'sorting'
      // if (task.status === TaskStatus.ToDo && args.propertyname === 'order') {   // note: we no longer check for status 'todo'.
      if (args.propertyname === 'order') {                                        //    now a task of any state can be moved in the tree.

        // First, knowing that it's a todo, we copy level and parentId from the current
        //    task at the destination to the incoming task.
        // Note: oldValue and newValue are the old and new indices of the incoming task.
        const newLevel = this.dataservice.data[args.newValue].level;
        const newParentId = this.dataservice.data[args.newValue].parentId;
        // Another note: the cautious code-reader might have spotted that the levels of
        //    childnodes of the moved node are not accounted for. Well, spot on that is.
        //    These, however, are corrected by the todo-component itself that adjusts
        //    levels on refreshing. It is questionable whether that's good design but
        //    it is the current state of affairs.

        // splice: see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice

        // Remove task from old position
        this.dataservice.data.splice(args.oldValue, 1);

        // Insert task at the new position.
        this.dataservice.data.splice(args.newValue, 0, task);

        // update parent & level. Because calling the dataservice always leads to immediate publication of
        //    the changes, in case of a package of changes we only call it on the last change in order to
        //    publish a 'consistent state'. In this case parent & level should be consistent.
        task.parentId = newParentId;  // not via dataservice because we're in a inconsistent state.
        this.dataservice.update(task, 'level', newLevel);

      } else if (task[args.propertyname] === args.oldValue) {

        // handle any other property
        if (this.dataservice.update(task, args.propertyname, args.newValue)) {
          this.logger.log('task updated.');
        } else {
          this.logger.log('AppComponent.processTaskPropertyChangeRequest: Task update failed in dataservice.');
        }
      } else {
        this.logger.log('AppComponent.processTaskPropertyChangeRequest: Task not updated because oldvalue is incorrect.');
      }
    } else {
      this.logger.log('AppComponent.processTaskPropertyChangeRequest: Task not updated id not found: ' + String(args.entity));
    }
  };

  /** @description Fires when a childcomponent throws up a request
   * to change a task.
   * Note that the changedTask is an object that is not in the datasource (should not be, anyway).
   */
  private processTaskChangeRequest = (originalTask: ITask, changedTask: ITask): void => {

    // first we copy all changes. But because we want to handle 'status' separately
    //    we set aside the original value first, and reassign it after copying.
    const originalStatus: TaskStatus = originalTask.status;
    const copier = new TaskCopier();
    copier.copyStoneAge(changedTask, originalTask);       // copy all changes to the original task. Note that we do this independent
    originalTask.status = originalStatus;                 //    of whether the statuschanges is applied (or denied).

    // now handle state change.
    this.processTaskStatusChangeRequest(originalTask, changedTask.status);
  };


  /** @description Fires when a childcomponent throws upa request
   * to change the status of a task.
   */
  private processTaskStatusChangeRequest = (originalTask: ITask, newstatus: TaskStatus, reasonBlocked = ''): void => {

    // Remember the old status
    const oldstatus: TaskStatus = originalTask.status;

    // in case of documentation also children get their status changed.
    let mustAlsoChangeChildren = false;

    // A note on broadcasting changes:
    //  we only call dataservice.tryUpdate as the last
    //  step in this method, so only then the changes
    //  are published to the outer world. In case you
    //  change the code and return early you should
    //  make sure to publish them.

    // #region Handle delete-requests

    // Deletion is always allowed.
    //    If there are dependencies (children) they are deleted too.
    if (newstatus === TaskStatus.Deleted) {

      // check for dependencies: does it have any children?
      // let hasDependencies: boolean = !!this.data.find(x => x.parentId === originalTask.id);

      this.dataservice.delete(originalTask);
      this.logger.log('task and all its children deleted.');

      // if deletion failed, we must deny the delete-request
      //    and leave the task in its initial state.
      // So, we return without having applied the new status.
      return;
    }
    // #endregion

    // We split up the cases: either timed or not timed.
    if (!IImplements.ITimedTask(originalTask)) {

      // #region Non-timed tasks: set start- and finishdates.

      // set dateStarted if the task is started and dateStarted hasn't been set before.
      if (oldstatus === TaskStatus.ToDo && newstatus === TaskStatus.Doing && !originalTask.dateStarted) {
        originalTask.dateStarted = new Date();

      } else if (oldstatus !== TaskStatus.Cancelled && oldstatus !== TaskStatus.Done &&
        (newstatus === TaskStatus.Cancelled || newstatus === TaskStatus.Done)) {
        // set dateDone if a task was not done/cancelled, and is now done/cancelled
        originalTask.dateDone = new Date();
      }
      else if (newstatus === TaskStatus.Documentation) {
        mustAlsoChangeChildren = true;
      }
      else if (newstatus === TaskStatus.Blocked) {
        originalTask.reasonBlocked = reasonBlocked;
      }

      // #endregion
    } else {

      // #region  Timed tasks: stop/start timers, compute delays

      // Some remarkable things:
      //  - the timers-section only contain timers in status Todo, Done and PostPoned.
      //        That leads to the 6 state-transitions below.
      //  - I mean: cancelled timers are in the done-list, Doings in the doing list, so
      //         they're no longer in the timers' list.
      //  - Todo is the initial, running state of the timed task.
      //  - Done means the timer is stopped.
      //  - Postponed means the timer is paused.

      // Technical: a bit counterintuitive is the fact that for pause/resume we have to
      //    DELETE/CREATE a timer, while for stopping/starting we have to SUBSCRIBE or UN-
      //    SUBSCRIBE from them. The cause lays within the technical character of subscrip-
      //    tion which holds that on subscribing, the original delay is applied AGAIN. So
      //    subscribing does NOT mean that one steps smoothly into the original flow, and
      //    subscribing this way matches intuitively with stopping and starting a timer.
      //    In order to implement pause/resume the intuitive way, we have to compute our-
      //    selves the rhythm of the original flow, which is less hard than it sounds, by
      //    the way.

      const timecomputer = new TimeComputer(this.durationFactory, this.logger);

      // #region any => Cancelled (cancel)

      if (newstatus === TaskStatus.Cancelled) {
        this.timerservice.deleteTimer(originalTask.timerName);
      }
      // #endregion

      // #region Todo => Postponed (pause)
      // pause timer when a timed task is postponed.
      else if (oldstatus === TaskStatus.ToDo && newstatus === TaskStatus.Postponed) { // tslint:disable-line:one-line

        // Pausing means we have to delete the timer; resumption means we recreate it.
        this.timerservice.deleteTimer(originalTask.timerName);

        // When a task is paused, we prepare for resumption by computing the delay that
        //    should be used when resuming. Therefor we count when it would have fired
        //    first next time if it wouldn't have been postponed. Note that non-repe-
        //    titive tasks can never end up here.

        if (originalTask.nextTimerTick) {
          // Make sure on next resume we pick a delay such that it marches in exactly with
          //    the current cyclus.
          const computedDelay: IDuration = timecomputer.getDateDifferenceInHhMmSs(new Date(), originalTask.nextTimerTick);
          originalTask.resumeDelay = computedDelay.isNegative ?
            this.durationFactory.createDuration(0, 0, 0) :
            computedDelay;
        }
        else {
          this.logger.log('Warning: on pausing task: failed to compute delay because of empty nextTimerTick, of task ' +
            String(originalTask.id) + '. Delay will be 0.');
        }
      }
      // #endregion

      // #region Postponed => Todo (resume)

      // Recreate timer when a timed task resumed.
      //    Pausing means we have to delete the timer; resumption means we recreate it.
      else if (oldstatus === TaskStatus.Postponed && newstatus === TaskStatus.ToDo) { // tslint:disable-line:one-line

        // use a helper to create a timer
        const helper = new TimedTaskHelper(this.durationFactory, this.timerservice, this.logger);
        const timerName = helper.createTimerFromPattern(originalTask.timingPatternInfo, originalTask.resumeDelay);
        this.subscribeToTimer(originalTask, timerName);

        // some administration
        originalTask.resumeDelay = null;
      }
      // #endregion

      // #region Todo => Done (stop)
      // Stop timer-subscription when timed task is 'done'.
      //    We can use subscribe and unsubscribe, because on re-subscription, the
      //    original delay and repetition are freshly applied.
      //    Note that oldstatus may also be 'Postponed', so in a paused state you
      //    can stop a timer.
      else if (oldstatus === TaskStatus.ToDo && newstatus === TaskStatus.Done) { // tslint:disable-line:one-line
        this.timerservice.unsubscribe(originalTask.timerSubscriptionId);
      }

      // #endregion

      // #region Done => Todo (start)
      // Restart timer-subscription when timed task changes from done to todo.
      else if (oldstatus === TaskStatus.Done && newstatus === TaskStatus.ToDo) { // tslint:disable-line:one-line
        this.subscribeToTimer(originalTask);
      }
      // #endregion

      // #region Done => Postponed: prohibited! (pause a stopped task)
      // A user should not pause a stopped timed todo (forbidden status change).
      //    Note that resumption must also be prohibited in this situation, but we
      //    cannot detect that here because the status-change is equal to pressing
      //    start in that condition.
      else if (oldstatus === TaskStatus.Done && newstatus === TaskStatus.Postponed) { // tslint:disable-line:one-line
        newstatus = TaskStatus.Done;  // cancel status-change
      }
      // #endregion

      // #region Postponed => Done (stop a paused task)

      // This transition should be possible, but the timer in this case has
      //    been deleted (because of the pause) so we cannot unsubscribe.
      //    So, to bring the correct state about, we must do something a bit
      //    confusing: create the timer but not subscribe to it.
      else if (oldstatus === TaskStatus.Postponed && newstatus === TaskStatus.Done) { // tslint:disable-line:one-line
        // use a helper to create a timer, yet without subscribing
        const helper = new TimedTaskHelper(this.durationFactory, this.timerservice, this.logger);
        helper.createTimerFromPattern(originalTask.timingPatternInfo, originalTask.resumeDelay);
      }
      // #endregion

      // #region Doing => Done
      else if (oldstatus === TaskStatus.Doing && newstatus === TaskStatus.Done) { // tslint:disable-line:one-line
        originalTask.dateDone = new Date();
      }
      // #endregion

      // #endregion
    }

    if (mustAlsoChangeChildren) {

      // First detach node from its parent.
      this.dataservice.update(originalTask, 'parentId', null);

      this.updateTaskStatusRecursively(originalTask, newstatus);
    }
    else {
      this.dataservice.update(originalTask, 'status', newstatus);
    }
  };


  private updateTaskStatusRecursively(task: ITask, state: TaskStatus): void {

    if (task.children && task.children.length > 0) {
      this.dataservice.update(task, 'status', state);
      task.children.forEach(child => {
        this.updateTaskStatusRecursively(child, state);
      });
    }
    else {
      this.dataservice.update(task, 'status', state);
    };
  }

  // #endregion



  // #region Helper methods ---------------------------------------------------------------------------------------------------------------

  /** @description Checks the entire dataset for timers that not yet have been started and starts them.
   */
  private initNewTimers() {

    // get the list of timernames so we can see which tasks already are attached.
    const timernames = this.timerservice.getTimer();

    // See if any task has timinginfo and has yet to be started
    const timedTasksToBeStarted = this.data.filter(x =>
      IImplements.ITimedTask(x)                                // it's a timed task
      && !x.dateDone                                           // it has no date DONE
      && timernames.findIndex(t => t === x.timerName) === -1   // it's attached to an running timer (can be in a unsubscribed state, though)
      && (x.status as TaskStatus === TaskStatus.Done
        || x.status as TaskStatus === TaskStatus.ToDo)   // Done=stopped, todo=running.
    ); // .                                                 └Not for paused (Postponed) tasks: they get a timer when they are resumed.

    if (timedTasksToBeStarted.length !== 0) {

      // Note: how to instantiate a stopped, paused or running timer?
      // First: the status of the task determines whether it's a cancelled, paused, stopped or running timer.
      // 1. cancelled timer has status 4: dan geen timer createn. De timing-info is verder helemaal intact, voor historie.
      // 2. paused timer has status 2 (postponed); dan geen timer createn want bij resume wordt nieuwe timer verbonden.
      // 3. stopped timer has status3 (done); dan wel timer starten maar niet subscriben.
      // 4. running timer has status 0 (todo): timer starten en subscriben.

      this.logger.log('Init ' + String(timedTasksToBeStarted.length) + ' timer(s).');
      const helper = new TimedTaskHelper(this.durationFactory, this.timerservice, this.logger);
      timedTasksToBeStarted.forEach(el => {

        // use a helper to create a timer
        const timerName = helper.createTimerFromPattern((el as ITimedTask).timingPatternInfo, (el as ITimedTask).resumeDelay);
        (el as ITimedTask).timerName = timerName;

        if (el.status === TaskStatus.ToDo) { // only for running tasks; not for stopped tasks
          this.subscribeToTimer((el as ITimedTask), timerName);
        }
      });
    }
  }

  /** @description Calls a childcompoent to clear its filter (i.e.
   * show all).
   */
  private hideHistory(): void {
    if (this.doneComponent) {
      this.doneComponent.filterHistory('none');
    }
  }
  // #endregion



  public isRootDocTask = (task: ITask): boolean =>
    task && task.status === TaskStatus.Documentation && !task.parentId;

  public isRootTodoTask = (task: ITask): boolean =>
    task && !(Semantics.IsARunningTimer(task)) && task.status === TaskStatus.ToDo && !task.parentId;

  public saveTreeComponentsExpansionState(): void {

    this.todoTree.saveExpansionStateToSettings(this.settings.todo);
    this.docuTree.saveExpansionStateToSettings(this.settings.documentation);
    this.saveToCache();
  }

  private restoreTreeComponentsExpansionState(): void {

    this.todoTree.restoreExpansionStateFromSettings(this.settings.todo);
    this.docuTree.restoreExpansionStateFromSettings(this.settings.documentation);
  }
}
