/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// angular deps
import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

// internal deps
import { Settings1 } from './settings1/settings1';
import { IDemoDataService } from './plugins/contracts-for-plugins/idemo-data.service';
import { IStorageProvider } from './plugins/contracts-for-plugins/istorage-provider';
import { IStorageProvider$ } from './plugins/contracts-for-plugins/istorage-provider-$';
import { NullUserStorageMediaConfiguration } from './user-storage-media-configuration.null';

// model deps
import { ITask, ITimedTask, TaskStatus, TaskArrayHelper, ITaskFactory } from 'src/app/model/api/api.task';
import { IDataService, IDownloadAnchor, ISettings } from 'src/app/model/api/api.contracts-for-plugins';
import { SupportedStorageMedia } from 'src/app/model/supported-storage-media';

// ccc deps
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';

// application deps
import { ITKN_IDEMODATASERVICE, ITKN_ILOGSERVICE, ITKN_ISTORAGESERVICE_GDRIVE, ITKN_ISTORAGESERVICE_LOCALDISC,
  ITKN_ISTORAGESERVICE_LOCALSTORAGE, ITKN_ITASKFACTORYSERVICE
} from 'src/app/application/injectionTokens';
import { UUID } from 'angular2-uuid';
import { IImplements } from './plugins/contracts-for-plugins/taskapi';
import { MyDuration } from 'src/app/model/timing and duration/duration/my-duration';
import { IUserStorageMediaConfiguration } from 'src/app/view/usercontrols/main/contracts-for-plugins/user-storage-media-configuration.interface';



// Sources, are referred to from the code:
// [1] = https://blog.angular-university.io/how-to-build-angular2-apps-using-rxjs-observable-data-services-pitfalls-to-avoid/

// #region comment on injectable etc
// @Injectable({

// the @Injectable() decorator marks this class as
// a service that can be injected, but it still needs
// a provider to actually create an instance at runtime.

// We configured AppDataService as its own provider by
// adding it into the providers list in @NgModule.

// Below we declare that this service should be created
// by the root application injector.

// Note that this way it's a singleton: all consumers
// consume the same instance of the service!

// providedIn: 'root'      // providedIn determines which injectors will provide the injectable. In this case we use the root-injector.
//      In fact, @Injectable({providedIn:'root'}) says: this class is injectable by the root-injector.
// })
// #endregion



/** @description The data provider for this app.
 */
@Injectable() // the above way works; this line also works but combines with an entry in @NgModule's providers-array.
export class DataService1 implements IDataService {

  // #region  Main Members

  /** @description Returns the entire dataset.
   */
  public get data(): ITask[] { return this._dataPublisher.value; }

  /** @description Publishes changes in data to subscribers.
   * Private, because see [1]
   */
  private _dataPublisher: BehaviorSubject<ITask[]> = new BehaviorSubject([]);

  // public and readonly, because see [1]
  public readonly dataPublisher: Observable<ITask[]> = this._dataPublisher.asObservable();

  /** @description Returns the applicationsettings.
   */
  public get appSettings(): ISettings { return this._settingsPublisher.value; }

  /** @description Publishes changes in appsettings to subscribers.
   * Private, because see [1]
   */
  private _settingsPublisher: BehaviorSubject<ISettings> = new BehaviorSubject(new Settings1());

  public readonly settingsPublisher: Observable<ISettings> = this._settingsPublisher.asObservable();
  // #endregion



  constructor(
    @Inject(ITKN_IDEMODATASERVICE) public demoDataservice: IDemoDataService
    , @Inject(ITKN_ILOGSERVICE) public logger: ILogService
    , @Inject(ITKN_ITASKFACTORYSERVICE) public taskfactory: ITaskFactory
    , @Inject(ITKN_ISTORAGESERVICE_GDRIVE) public gdriveStorageProvider: IStorageProvider$
    , @Inject(ITKN_ISTORAGESERVICE_LOCALDISC) public localDiscStorageProvider: IStorageProvider
    , @Inject(ITKN_ISTORAGESERVICE_LOCALSTORAGE) public localStorageStorageProvider: IStorageProvider
    // Important! taskFactory must be loaded last, otherwise something very strange happens:
    //    The assignment of demodata-, backup- and taskfactory-services get mixed up!
    //    You can experiment if you like by putting taskfactory first an set a breakpoint
    //    within the constructorcode below.
  ) {

    // Initialize an empty db or get the demodata and publish it.
    let data: ITask[];
    let settings: ISettings;

    if (!environment.production) {
      data = [];
      this.demoDataservice.getDemoTasks().forEach(x => data.push(x));
      settings = this.demoDataservice.getDemoSettings();
    } else {
      data = [];
      settings = new Settings1();
    }

    // Notify the changes.
    this._dataPublisher.next(data);
    this._settingsPublisher.next(settings);
  }

  public getNewTask(): ITask {
    return this.taskfactory.createTask();
  }


  public getNewTimedTask(): ITimedTask {
    return this.taskfactory.createTimedTask();
  }


  // #region CRUD: data
  /** @description Tries to add a task to the datasource.
   * @returns an observable with true when adding succeeds; else returns false.
   */
  public create(task: ITask): Observable<boolean> {

    /* the reason for returning an observable is because although currently
       the code is local and fast, it may one day include remote call. The
       callers shouldn't be bothered by such a change so I made it async
       onbeforehand.
    */

    let result: boolean;

    if (this.data.find(x => x.id === task.id)) {
      this.logger.log('AppDataService.tryPush failed: task with ID ' + String(task.id) + ' already exists.');
      result = false;
    } else {
      if (task.status === TaskStatus.ToDo && !task.parentId) {
        // add ROOT todo's from the front of the array
        //    Because of performance we don't do this for all types.
        this.data.unshift(task);
      } else {
        this.data.push(task);   // add to the end of the array
      }

      // Notify the change.
      this._dataPublisher.next(this.data);
      result = true;
    }

    const obs: Observable<boolean> = new Observable((observer) => {
      observer.next(result);
      observer.complete();
    });

    return obs;
  }

  /**
   * @description Tries to delete a task and all its children, recursively.
   */
  public delete(task: ITask): boolean {

    if (!this.data.find(x => x.id === task.id)) {
      return false;
    } else {
      // get all children of the given task, but for safety we sort them
      //    in order from leaf to branch.
      const children = TaskArrayHelper.getChildrenRecursivelyDepthFirst(task, this.data).reverse();
      // Delete all childtasks
      children.forEach(x => this.delete(x));     // todo: stop deleting when any delete fails
      // Finally delete the task itself.                        //      to prevent 'data-corruption' (parentIds of non-existing tasks)
      this.tryDelete(task);
      return true;
    }
  }

  /**
   * @description Tries to delete a task from the datasource.
   * @returns true when deletion succeeds; else returns false.
   */
  private tryDelete(task: ITask): boolean {

    const index: number = this.data.findIndex(x => x.id === task.id);
    if (index === -1) {
      this.logger.log('AppDataService.tryDelete failed: task with ID ' + String(task.id) + ' not found.');
      return false;
    } else {
      this.data.splice(index, 1);
      this._dataPublisher.next(this.data);   // Notify the change. Note that on bulkdelete next is called for each single delete.
      return true;                      //    May be optimized?
    }
  }

  /** @description Tries to update a task in the datasource.
   * If you have a bulk of changes, you may apply all changes directly (without calling
   * this dataservice method) but the last one: on the last one you should call
   * this service in order to trigger a notification of the changes across the
   * application-parts.
   */
  public update(task: ITask, property: string, value: any): Observable<boolean>  {

    let result: boolean;

    if (!this.data.find(x => x.id === task.id)) {
      this.logger.log('AppDataService.tryUpdate failed: task with ID ' + String(task.id) + ' not found.');
      result = false;
    } else {
      // todo: validatie
      task[property] = value;
      this._dataPublisher.next(this.data);   // Notify the change.
      result = true;
    }

    const obs: Observable<boolean> = new Observable((observer) => {
      observer.next(result);
      observer.complete();
    });

    return obs;
  }
  // #endregion



  // #region CRUD: settings

  /** @description Saves setting to the settingsobject and publishes the change.
   */
  public saveSetting$(propertyName: string, value: any, settingPath?: string): Observable<boolean> {

    let settingsHaveEffectivelyBeenChanged = false;
    if (propertyName === 'hideDoneTasks') {
      if (settingPath.toLowerCase().trim() === 'todo') {
        this.appSettings.todo.hideDoneTasks = !!value;
        settingsHaveEffectivelyBeenChanged = true;
      }
    }

    // Only if some setting has been changed, publish the settings.
    if (settingsHaveEffectivelyBeenChanged) {
      this._settingsPublisher.next(this.appSettings);
    }

    const obs: Observable<boolean> = new Observable((observer) => {
      observer.next(settingsHaveEffectivelyBeenChanged);
      observer.complete();
    });

    return obs;
  }

  getStorageSettings(): IUserStorageMediaConfiguration {
    return this.appSettings.storageMedia;
  }

  // #endregion



  // #region Google Drive methods ----------------------------------------------------------------------------------------------------

  /**
   * @description Creates a file on google drive containing the current mindstack, using the optional filename.
   *
   * @param filename defaults to 'whatiamdoing-<tagname>.json'
   * @returns An observable with the full response from google drive.
   */
  public createOnDrive(filename?: string): Observable<string> {

    // summary:
    // first obtain a fileId from Google Drive; then
    // save that id to the settings; then
    // upload the whole to Google drive.

    if (!filename) {
      filename = `whatiamdoing-${String(this.appSettings.tag)}.json`;
    }

    const observable: Observable<string> = new Observable((observer) => {

      this.gdriveStorageProvider.generateFileId().subscribe({ // obtain fileId from gdrive
        next: (fileId) => {

          // save fileId to settings (either when gDrive is primary or secondary storagemedium).
          if (this.appSettings.storageMedia.primaryProvider.medium.id === SupportedStorageMedia.GDrive.id) {
            this.appSettings.storageMedia.primaryProvider.externalId = fileId;
          }
          else if (this.appSettings.storageMedia.secondaryProvider.medium.id === SupportedStorageMedia.GDrive.id) {
            this.appSettings.storageMedia.secondaryProvider.externalId = fileId;
          }
          else {
            console.warn('Warning: failed to save fileID to file on google drive.');
          }

          const data = this.gatherDataForBackup(true);
          const contents = JSON.stringify(data);
          this.repairChildArrays();

          this.gdriveStorageProvider.create(filename, contents, fileId).subscribe({
            next: (response) => {
              observer.next(response);
            },
            error: (err) => {
              observer.error(err);
            }
          });
        }
        , error: (err) => { observer.error(err); }
      });
    });

    return observable;
  }

  public updateOnDrive(id?: string, contents?: string): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {
      let jsoncontents: string;
      if (contents) {
        jsoncontents = JSON.stringify(contents);
      } else {
        jsoncontents = JSON.stringify(this.gatherDataForBackup(true));
      }
      this.repairChildArrays();

      this.gdriveStorageProvider.update(id, jsoncontents).subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: (err) => {
          observer.error(err);
        }
      });
    });

    return observable;
  }

  public readFromDrive(id: string): Observable<boolean> {

    const observable: Observable<boolean> = new Observable((observer) => {
      this.gdriveStorageProvider.read(id).subscribe({
        next: (response) => {
          this.import(response);
          observer.next(true);
        },
        error: (err) => {
          observer.error(err);
        }
      });
    });
    return observable;
  }

  public getListFromDrive(): Observable<string> {

    const observable: Observable<string> = new Observable((observer) => {
      this.gdriveStorageProvider.list().subscribe({
        next: (response) => {
          observer.next(String(response));
        },
        error: (err) => {
          observer.error(err);
        }
      });
    });
    return observable;
  }

  // #endregion


  private import(rawdata: string): void {
    const anonymousObject = JSON.parse(rawdata);
    this.importSettings(anonymousObject);
    this.importData(anonymousObject);
  }


  // #region Cache data ----------------------------------------------------------------------------------------------------

  private CACHE_KEY_BASE = 'whatiamdoing';

  /** Key for storing backups in the localStorage (key-value storage).
   */
  private get backupKeyCurrentMindstack(): string {

    // add the current tag for identification between multiple mindstacks.
    if (this.appSettings.tag) {
      return this.determineCacheKey();
    }
    else {
      throw new Error('Error: no tag found to create backupfile from.');
    }
  }

  /** Creates a backup in LocalStorage of the given key/value pair.
   * The key can be used to retrieve the value later.
   */
  public saveToCache(): void {

    const data4backup = this.gatherDataForBackup(false);
    // do NOT empty refs from child-arrays because redundant data in memory (versus disk) is no problem.

    this.writeToCache(this.backupKeyCurrentMindstack, data4backup);
  }

  public determineCacheKey(latest = false): string {
    let customKey: string;

    const latestTagValue: string = this.localStorageStorageProvider.read('latestTag'); // localstorage can be empty

    if (latest && latestTagValue) { // todo: this mechanism, and the logic of the construction of the key, refactor it more neatly.
      console.log('Selecting latest cached tag to load from cache.');
      customKey = JSON.parse(latestTagValue).toUpperCase();
    }
    else {
      console.log(`Selecting current tag "${String(this.appSettings.tag)}" to load from cache.`);
      customKey = this.appSettings.tag.toUpperCase();
    }

    const result = this.CACHE_KEY_BASE + '-' + customKey;
    return result;
  }

  /**
   * @description loads a full mindstack from the browsercache.
   *
   * @param latest: there can be more stacks in the cache. When latest, the latest used mindstack is loaded cache (determines latest (by tagname) from the cache itself, key 'latestTag').
   */
  public loadFromCache(latest = false): boolean {

    const key = this.determineCacheKey(latest);

    try {

      const rawdata = this.readFromCache(key);

      if (!rawdata){
        return false;
      }

      const settings: Settings1 = this.convertFromRawSettings(rawdata);
      this._settingsPublisher.next(settings);

      const data: ITask[] = this.convertFromRawData(rawdata);
      this._dataPublisher.next(data);

      return true;
    }
    catch (exception) {
      this.logger.log('Error loading from cache: ' + String(exception));
      return false;
    }
  }

  // #endregion



  /**
   * Prepare the current dataset for backing up to disk.
   *
   * @param removeDuplicates Set to true when preparing for saving to a file.
   * @returns an anymous object. You must serialize it yourself.
   */
  // made public for testing
  // eslint-disable-next-line @typescript-eslint/ban-types
  public gatherDataForBackup(removeDuplicates?: boolean): object {

    // prepare data for download
    if (removeDuplicates) {
      //   Remove children (to prevent duplicates, mainly of importance when the caller writes data to disk)
      this.data.forEach(x => x.children = null);
    }

    return {
      settings: this.appSettings,
      data: this.data
    };
  }

  private repairChildArrays() {
    // Trigger repair of the child-arrays. It's not a really good solution, I must think about it.
    this._dataPublisher.next(this.data);
  }


  // #region Upload and download ----------------------------------------------------------------------------------------------------

  /** Creates a backup on disc, via the storageservice, of the given data.
   */
  public downloadToDisc(downloader: IDownloadAnchor, filename: string): void {
    if (downloader && filename && this.appSettings.tag) {

      // Get data: pass argument true, to empty refs from child-arrays to prevent
      //    duplicate entries in the serialized file on disk (i.e. the tasks then
      //    also are serialized within the children-arrays). Note that on emptying
      //    refs we touch the app's REAL data, not a copy or something. That's why
      //    we must repair the child-relations afterwards.
      const data4backup = this.gatherDataForBackup(true);

      // Download to disk
      this.downloadToDisk(downloader, filename, data4backup);

      this.repairChildArrays();
    }
  }

  /** @description Tries to load any given data into the application.
   * It is meant as a generic insertion point of any mass-input
   * and should take care of conversions (of old to new versions)
   * if so required.
   */
  public importData(args: any): Observable<boolean> {

    let result = false;

    if (args) {
      const newData: ITask[] = this.convertFromRawData(args);
      if (newData) {
        this.overwriteAllData(newData);
        result = true;
      }
    }

    const obs: Observable<boolean> = new Observable((obr) => {
      obr.next(result);
      obr.complete();
    });

    return obs;
  }

  /** Tries to import settings from a fileupload broadcasts 'm to listeners.
   */
  public importSettings(args: any): any {
    this._settingsPublisher.next(this.convertFromRawSettings(args));
  }
  // #endregion



  // #region Helpers

  /** @description Overwrites the current data with new data. Used
   * for restore as well as upload.
   */
  private overwriteAllData(tasks: Array<ITask>): void {

    // Problem:
    //    If we  assign backupdata directly to this.data, the page won't
    //    refresh.
    // Solution:
    //    keep the memoryaddress of this.data intact so all angular
    //    watches keep on functioning. So instead of directly assigning
    //    this.data we just clear the array and repopulate it from the
    //    backup-array.

    // this.data.splice(0);                                // clear current array

    // (tasks).forEach(task => {                           // repopulate current array from backup
    //   this.data.push(task);
    // });

    // // Notify the change.
    // this._dataPublisher.next(this.data);
    // └─Voorstel: alle code weg uit deze func, en dan als enige: this.publisher.next(tasks); >> lijkt te werken! 20201022
    this._dataPublisher.next(tasks);
  }
  // #endregion


  // #region Was: storageservice, and before: backupservice
  // #region LocalStorage ------------------------------------------------------------------------------------------------------------

  public writeToCache(key: string, value: any): void {
    if (key) {
      this.logger.log('backup with key ' + key);

      // ook even latest-tag saven
      if (this.appSettings.tag !== 'DEFAULT') {
        this.localStorageStorageProvider.create('latestTag', this.appSettings.tag);
      }
      this.localStorageStorageProvider.create(key, value);
    }
  }

  /** @description Returns raw uninterpreted data from the cache, given a key.
   */
  public readFromCache(key: string): any {
    if (key) {
      this.logger.log('Try to restore with key ' + key);
      const rawdata = this.localStorageStorageProvider.read(key);
      if (rawdata) {
        const result = JSON.parse(rawdata);
        return result;
      }
      this.logger.log('Restore failed: key returned no data from localstorage.');
      return null;
    }
    else {
      throw new Error('Error: arg key is NULL in readFromCache(key)');
    }
  }
  // #endregion



  // #region Disk ------------------------------------------------------------------------------------------------------------

  /** @description Download all current data to a file on your hard-disc.
   *
   * This is a strange one: this backup-service requires an object that is able to return an existing
   * html-element to which to attach data for download.
   * By using an interface I prevented a hard dependency on the DownloadAnchorComponent.
   */
  public downloadToDisk(downloader: IDownloadAnchor, filename: string, downloaddata: any): void {
    const anchor = downloader.getElement();
    const contents = JSON.stringify(downloaddata);
    const file = new Blob([contents], { type: '.txt' });
    anchor.nativeElement.href = URL.createObjectURL(file);
    anchor.nativeElement.download = filename;
    anchor.nativeElement.click();  // download the file immediately.
  }
  // #endregion



  // Conversions must to separate ConversionService. SK 13 may 2020
  // Keep an eye on the axis of change: keep together what changes together (for the same reason).



  // #region Conversions

  /** @description Tries to read the settings from a file-upload
   * and return 'm to the caller.
   */
  public convertFromRawSettings(args: any): ISettings {

    const appSettings: ISettings = new Settings1();

    let readingSettingsFailed = false;

    let settings: any;

    if (args) {

      if (args.uploaddata && args.uploaddata.settings) {
        settings = args.uploaddata.settings;
      } else if (args.settings) {
        settings = args.settings;
      }

      if (settings) {

        if (settings.soundVolume) {
          appSettings.soundVolume = settings.soundVolume;
        }

        if (settings.tag) {
          appSettings.tag = settings.tag;
        }

        if (settings.color) {
          appSettings.color = settings.color;
        }

        if (settings.todo) {
          if (settings.todo.hideDoneTasks) {
            appSettings.todo.hideDoneTasks = settings.todo.hideDoneTasks;
          }
          if (settings.todo.expandedNodeSet) {
            appSettings.todo.expandedNodeSet = settings.todo.expandedNodeSet;
          }
        }

        if (settings.documentation) {
          if (settings.documentation.expandedNodeSet) {
            appSettings.documentation.expandedNodeSet = settings.documentation.expandedNodeSet;
          }
        }

        if (settings.storageMedia) {
          appSettings.storageMedia = settings.storageMedia;
        }
        else {
          appSettings.storageMedia = new NullUserStorageMediaConfiguration();
        }

      } else {
        readingSettingsFailed = true;
      }
    } else {
      readingSettingsFailed = true;
    }

    if (readingSettingsFailed) {
      this.logger.log('No settings found on import.');
    }

    return appSettings;
  }

  /** @description Tries to convert any given data to the current standard.
   * It is meant as a generic insertion point of any mass-input. At the moment,
   * supported types for args are (1) ITask[], (2) IOpenFileEventArgs.
   * @returns Null, if no data available or conversion failed.
   */
  public convertFromRawData(args: any): ITask[] {

    if (!args) {
      if (!environment.production) {
        console.error('Error (dev env only): storageservice.convert() is called with null argument.');
      }
      return null;
    }

    let result: ITask[];

    if (Array.isArray(args) && args[0] && (args[0] as ITask).id) {
      result = args as Array<ITask>;
      this.logger.log('import plain task-array.');
    } else {

      // we do not know what's in uploaddata, so
      // we try to detect it.

      let newData: any;

      // 'unwrap' tha data.
      // 'uploaddata' is a wrapper. If it's not there, we just interpret
      // it without the wrapper.
      if (args.uploaddata) {
        newData = args.uploaddata;
      } else {
        newData = args;
      }

      // Start feature-detection
      if (Array.isArray(newData)) {
        // feb 2020 format
        this.logger.log('import feb 2020 format.');
        result = newData;
      } else if (newData._autoTodoId) {
        // 2019 format
        this.logger.log('import 2019 format.');
        result = this.convert2019FormatToTaskArray(newData);
      } else if (newData.AutoTodoId) {
        // 14 jan 2020 format
        this.logger.log('import jan 14 2020 format.');
        result = this.convertJan2020FormatToTaskArray(newData);

        // 3 nov 2020: the importformat march-28 is no longer in use. The reason is
        // that of okt 2020 a hybrid format was unintentionally introduced that
        // has task fields some with underscore prefixed and some without (so either
        // id or _id). This was due to consequences of a change in Typescript 4.
        // As a result: from now on all files formatted as march 28 but now including
        // those with hybrid underscores, are imported in a new way i.e. by the
        // converter called after "okt 2020".
        // So, next lines are disabled:
        // } else if (newData['settings'] && newData['data'] && newData['data'][0]['id']) {
        //   // 28 maart 2020 format
        // this.logger.log('import march 28 2020 format.');
        // result = newData.data;
      } else if (newData.settings && newData.data) {
        // okt 2020 format, covers march28 2020 format also
        this.logger.log('import march 8 / okt 2020 format.');
        result = this.convertOkt2020FormatToTaskArray(newData);
      } else {
        this.logger.log('Error: storageservice.convert() is called with unknown dataformat in IOpenFileEventArgs.');
        return null;
      }
    }

    // Convert strings to dates
    result.forEach(task => {
      if (task) {
        task.dateCreated = task.dateCreated ? new Date(task.dateCreated) : null;
        task.dateStarted = task.dateStarted ? new Date(task.dateStarted) : null;
        task.dateDone = task.dateDone ? new Date(task.dateDone) : null;
        if (IImplements.ITimedTask(task)) {
          task.firstTimerTick = task.firstTimerTick ? new Date(task.firstTimerTick) : null;
          task.shiftingFirstTimerTick = task.shiftingFirstTimerTick ? new Date(task.shiftingFirstTimerTick) : null;
          task.nextTimerTick = task.nextTimerTick ? new Date(task.nextTimerTick) : null;

          // check durationInSeconds
          if (task.timingPatternInfo) {
            if (task.timingPatternInfo.timingpattern.hasDelay) {
              if (!task.timingPatternInfo.timingpattern.delay.durationInSeconds &&
                task.timingPatternInfo.timingpattern.delay.durationInSeconds !== 0) {
                console.error('Failed to get duration-in-seconds from Duration object.');
              }
            }
          }
        }
      }
    });

    return result;
  }


  // ------------------------------------------------------------------------
  //
  //  Important directive for conversions:
  //    avoid empty entries in the resulting array, otherwise we'll
  //    have to clutter up our component code with null checks.
  //
  //  The most common root-cause of null-entries is that in the old
  //    formats a single task could have duplicates on other stacks/lists.
  //    On importing we ignore the duplicates by returning null, but
  //    as said, these nulls should not end up in the array.
  //
  // ------------------------------------------------------------------------

  /** Converts the 'Okt 2020' as well as the 'march 28 2020' versions to an array of ITasks. <br>
   * The exportformat was unintentionally changed by okt 2020 as a result of a (justified imho) change in Typescript 4. <br>
   * Does not yet either convert or load TIMED tasks.
   *
   * @param okt2020data
   */
  private convertOkt2020FormatToTaskArray(okt2020data: any): ITask[] {

    //    SK: the okt-2020 format-change was not a deliberate choice: new Typescript version 4.0 demanded
    //    that fields in the baseclass cannot be disguised as properties in a derived class,
    //    so I had to make getters/setters + a underscored field for all properties which were
    //    formerly simple fields. No big deal, but I hadn't foreseen that that would affect
    //    the export as well and therefor also the import.
    //    So from now on the format is hybrid: some tasks have all underscore-prefixed fields;
    //    some none and some a few. Therefor we check both possibilities per field.

    //    Timed tasks are no longer imported
    //    This is different from 28 march, but it was pointless anyway because the timers
    //    were not started anyway.
    //    Note for future developer: when importing timed tasks beware that the timed task
    //    CONTAINS a (standard) task, instead of inheriting from it. That means that
    //    the ID for example has to be loaded from data.task.id instead of data.id.

    const result = new Array<ITask>();


    okt2020data.data.forEach(el => {

      // ▼ alleen inladen als id + name heeft (this way timedtasks are excluded because they have el.task.id)
      if ((el.id || el._id) && (el.content || el._content)) {
        const t: ITask = this.taskfactory.createTask();
        // ▼ for each field we check whether to import without (march28 format) or with (okt 2020 format) underscore.
        t.id = el.id ? el.id : el._id;
        t.parentId = el.parentId ? el.parentId : el._parentId;
        t.content = el.content ? el.content : el._content;
        t.dateCreated = el.dateCreated ? el.dateCreated : el._dateCreated;
        t.dateStarted = el.dateStarted ? el.dateStarted : el._dateStarted;
        t.status = el.status || el.status === 0 ? el.status : el._status;   // el.status gives false when it's 0.
        t.order = el.order || el.order === 0 ? el.order : el._order;
        t.note = el.note ? el.note : el._note;
        t.noteHeight = el.noteHeight ? el.noteHeight : el._noteHeight;
        t.dateDone = el.dateDone ? el.dateDone : el._dateDone;
        t.reasonBlocked = el.reasonBlocked ? el.reasonBlocked : el._reasonBlocked;
        result.push(t);

      } else if (el.task) {     // timed tasks have a contained task

        const t: ITimedTask = this.taskfactory.createTimedTask();
        // ▼ for each field we check whether to import without (march28 format) or with (okt 2020 format) underscore.
        t.id = el.task.id ?? el.task._id;
        t.parentId = el.task.parentId ?? el.task._parentId;
        t.content = el.task.content ?? el.task._content;
        t.dateCreated = el.task.dateCreated ?? el.task._dateCreated;
        t.dateStarted = el.task.dateStarted ?? el.task._dateStarted;
        t.status = el.task.status || el.task.status === 0 ? el.task.status : el.task._status;
        t.order = el.task.order || el.task.order === 0 ? el.task.order : el.task._order;
        t.note = el.task.note ?? el.task._note;
        t.noteHeight = el.task.noteHeight ?? el.task._noteHeight;
        t.dateDone = el.task.dateDone ?? el.task._dateDone;

        // timer related fields
        t.timerName = el.timerName ?? el._timerName;
        // ▼ niet op null zetten, want dan komt 'ie in de doing ipv de timers sectie
        t.timerSubscriptionId = el.timerSubscriptionId ?? el._timerSubscriptionId;
        t.firstTimerTick = el.firstTimerTick ?? el._firstTimerTick;
        t.nextTimerTick = el.nextTimerTick ?? el._nextTimerTick;
        t.resumeDelay = el.resumeDelay ?? el._resumeDelay;
        t.shiftingFirstTimerTick = el.shiftingFirstTimerTick ?? el._shiftingFirstTimerTick;

        t.timingPatternInfo = el.timingPatternInfo ?? el._timingPatternInfo;
        if (t.timingPatternInfo) {
          if (t.timingPatternInfo.timingpattern) {
            if (t.timingPatternInfo.timingpattern.delay) {
              // ▼ any type, because: it's a serialized MyDuration, so serialized are the fields durationHours, -Minutes, & -Seconds.
              const tDelay: any = t.timingPatternInfo.timingpattern.delay;
              t.timingPatternInfo.timingpattern.delay =
                new MyDuration(tDelay.durationHours, tDelay.durationMinutes, tDelay.durationSeconds);
            }
            if (t.timingPatternInfo.timingpattern.repeat) {
              const tRepeat: any = t.timingPatternInfo.timingpattern.repeat;
              t.timingPatternInfo.timingpattern.repeat =
                new MyDuration(tRepeat.durationHours, tRepeat.durationMinutes, tRepeat.durationSeconds);
            }
          }
        }

        result.push(t);
      }
    });

    return result;
  }

  /** @description Converts the '2019' version to the current version.
   *
   * I haven't done anything smart here. Even methodnames are disputable.
   * I'll invest in having a more robust versioning of the data.
   */
  private convert2019FormatToTaskArray(oldFormatData: any): ITask[] {

    // local array
    const data = new Array<ITask>();

    // Keep a dictionary that maps old id's (ints) to new (guid) ones, so we can
    //    assign parentId's afterwards.
    const newids = new Map<number, string>();

    // This local function converts an task in old format into a
    //    task in the new format, except for parentId's. It does
    //    not differentiate between cancelled/done; all are set to
    //    done.
    // Important: returns NULL when a task already exists in the result!
    const convertTask = (oldtask: any, status: TaskStatus, taskcreator: () => ITask): ITask => {
      // in previous versions of minstack, a copy of a task could occur in doing as well as done; only the first occurance is imported.
      if (newids.has(oldtask.id)) {
        return null;
      }
      const newtask: ITask = taskcreator();
      newtask.id = UUID.UUID();
      newids.set(oldtask.id, newtask.id); // update mapping
      newtask.shortId = oldtask.parentId; // save original parentId for later
      newtask.content = oldtask.name ? oldtask.name : (oldtask.content ? oldtask.content : null);
      newtask.note = oldtask.note;
      newtask.status = status;
      newtask.dateCreated = oldtask.dateCreated;
      newtask.dateDone = oldtask.dateFinished;
      newtask.parentId = null;
      return newtask;
    };

    // convert todo's
    if (oldFormatData._todo && oldFormatData._todo.items) {
      oldFormatData._todo.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.ToDo, this.taskfactory.createTask);
        if (newtask) {  // this way we avoid empty entries in the taskarray
          data.push(newtask);
        }
      });
    }

    // convert doings
    if (oldFormatData._doing && oldFormatData._doing.items) {
      oldFormatData._doing.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.Doing, this.taskfactory.createTask);
        if (newtask) {
          data.push(newtask);
        }
      });
    }

    // convert done
    if (oldFormatData._done && oldFormatData._done.items) {
      oldFormatData._done.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.Done, this.taskfactory.createTask);
        if (newtask) {
          data.push(newtask);  // i do not distinguish done or cancelled.
        }
      });
    }

    // convert done-filtered
    if (oldFormatData._done && oldFormatData._done._filtered) {
      oldFormatData._done._filtered.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.Done, this.taskfactory.createTask);
        if (newtask) {
          data.push(newtask);  // i do not distinguish done or cancelled.
        }
      });
    }

    // All tasks are available, but we don't have their parents linked yet.
    //    We marked save the original parentId in the shortId field and
    //    we kept a dictionary mapping from old to new ids, so:
    data.forEach(task => {
      if (task && !task.parentId && task.shortId) {
        task.parentId = newids.get(Number(task.shortId));
      }
    });

    return data;
  }

  /** @description Converts the 'january 2020' version to the current version.
   *
   * I haven't done anything smart here. Even methodnames are disputable.
   * I'll invest in having a more robust versioning of the data.
   *
   * Important: returns NULL when a task already exists in the result!
   */
  private convertJan2020FormatToTaskArray(oldFormatData: any): ITask[] {

    // local array
    const data = new Array<ITask>();

    // Keep a dictionary that maps old id's (ints) to new (guid) ones, so we can
    //    assign parentId's afterwards.
    const newids = new Map<number, string>();

    // This local function converts an task in old format into a
    //    task in the new format, except for parentId's. It does
    //    not differentiate between cancelled/done; all are set to
    //    done.
    const convertTask = (oldtask: any, status: TaskStatus, taskcreator: () => ITask): ITask => {
      // in previous versions of minstack, a copy of a task could occur in doing as well as done; only the first occurance is imported.
      if (newids.has(oldtask.id)) {
        return null;
      }
      const newtask: ITask = taskcreator();
      newtask.id = UUID.UUID();
      newids.set(oldtask.id, newtask.id); // update mapping
      newtask.shortId = oldtask.parentId; // save original parentId for later
      newtask.content = oldtask.name ? oldtask.name : (oldtask.content ? oldtask.content : null);
      newtask.note = oldtask.note;
      newtask.status = status;
      newtask.dateCreated = oldtask.dateCreated;
      newtask.dateDone = oldtask.dateFinished;
      return newtask;
    };

    // convert todo's
    if (oldFormatData.Todo && oldFormatData.Todo.items) {
      oldFormatData.Todo.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.ToDo, this.taskfactory.createTask);
        if (newtask) {  // convertTask may return null (when a task was already converted before), so this way we prevent empty entries.
          data.push(newtask);
        }
      });
    }

    // convert doings
    if (oldFormatData.Doing && oldFormatData.Doing.items) {
      oldFormatData.Doing.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.Doing, this.taskfactory.createTask);
        if (newtask) {
          data.push(newtask);
        }
      });
    }

    // convert done
    if (oldFormatData.Done && oldFormatData.Done.items) {
      oldFormatData.Done.items.forEach(oldtask => {
        const newtask = convertTask(oldtask, TaskStatus.Done, this.taskfactory.createTask);
        if (newtask) {
          data.push(newtask);
        } // i do not distinguish done or cancelled.
      });
    }

    // All tasks are available, but we don't have their parents linked yet.
    //    We marked save the original parentId in the shortId field and
    //    we kept a dictionary mapping from old to new ids, so:
    data.forEach(task => {
      if (!task.parentId && task.shortId) {
        task.parentId = newids.get(Number(task.shortId));
      }
    });

    return data;
  }

  // #endregion



  // #region Helpers

  /** @description Generates a stringified timestamp: year-month-day-hour-minute-second, which can be used in a filename.
   */
  public getTimeStampString(): string {
    const dt = new Date();
    const year: number = dt.getFullYear();
    const month: number = (dt.getMonth() + 1);
    const day: number = dt.getDate();
    const hour: number = dt.getHours();
    const minute: number = dt.getMinutes();
    const second: number = dt.getSeconds();

    // prefix zero's
    const sYear = year.toString();
    const sMonth: string = month.padZero(2);
    const sDay: string = day.padZero(2);
    const sHour: string = hour.padZero(2);
    const sMinute: string = minute.padZero(2);
    const sSecond: string = second.padZero(2);

    return sYear + sMonth + sDay + '-' + sHour + sMinute + '-' + sSecond;
  }
  // #endregion
}
