/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// angular deps
import { Component, OnInit, Input, Output, EventEmitter, Renderer2, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UUID } from 'angular2-uuid';

// model deps
import { ITask, ITimedTask, TaskStatusChangedEventArg, TaskStatus } from 'src/app/model/api/api.task';
import { IList } from 'src/app/model/api/api.collections';
import { Semantics } from 'src/app/model/api/api.shared';

// internal deps
import { TaskChangedEventArgs } from '../shared/task-changed-event-args';
import { PropertyChangedEventArgs } from '../shared/property-changed-event-args';
import { GenericEventArgs } from '../shared/generic-event-args';
import { IFocusService } from '../../shared/contracts-for-plugins/ifocus.service';

// ccc deps
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';

// application deps
import { ITKN_ILOGSERVICE, ITKN_IFOCUSSERVICE, ITKN_ILISTOFTASKS } from 'src/app/application/injectionTokens';

@Component({
  selector: 'app-timers',
  templateUrl: './timers.component.html',
  styleUrls: ['./timers.component.scss']
})
export class TimersComponent implements OnInit {

  // #region Main data -----------------------------------------------------------------------------------------------

  private data: ITask[];

  /** @description The main data, which harbours the data of the
   * view (a selection of items in this.data).
   */
  // public list: ListOfTasksService;

  // #endregion

  // #region In- and Outputs  -------------------------------------------------------------------------------------------------------------

  /**
   * @description the dataservice. Contains the entire dataset (not only
   * 'doings' but all tasks of all states).
   */
  @Input()
  dataservice: Observable<ITimedTask[]> = new Observable();

  @Output()
  onTaskStatusChanged = new EventEmitter<TaskStatusChangedEventArg>();

  @Output()
  onTaskChanged = new EventEmitter<TaskChangedEventArgs>();

  @Output()
  onTaskPropertyChanged = new EventEmitter<PropertyChangedEventArgs>();

  // #endregion

  // #region Init and main functions -------------------------------------------------------------------------------------------------

  /** @description Inject some items into this app and initialize it.
   */
  constructor(
    public renderer: Renderer2,
    @Inject(ITKN_ILOGSERVICE) public logger: ILogService,
    @Inject(ITKN_IFOCUSSERVICE) public focusservice: IFocusService,
    @Inject(ITKN_ILISTOFTASKS) public list: IList<ITask>) {

  }

  ngOnInit(): void {
    this.dataservice.subscribe(x => {
      this.data = x;
      this.filterAndSort();
    });
  }

  /** @description Filters and sorts the dataset for the current
   * view: the todo-list.
   */
  private filterAndSort(): void {

    // filter the incoming data to a list of only 'timed todo' items.
    const isTimedToDoTask = (task: ITask): boolean =>
      task &&
      Semantics.IsARunningTimer(task) &&
      task.status !== TaskStatus.Doing &&
      task.status !== TaskStatus.Cancelled
      ;
    this.list.items = this.data.filter(isTimedToDoTask);    // note: the items in the list point to the same objects that are in data!

    // sort, most recently added is on top (or the first to 'ring'?!?)
    const sorter = (task1: ITask, task2: ITask): number => { if (task1.dateCreated < task2.dateCreated) { return 1; } else { return -1; } };
    this.list.items = this.list.items.sort(sorter);
  }
  // #endregion

  // #region Process events from childcomponents --------------------------------------------------------------------------------------

  /** @description Processes a changerequest from a TextInputComponent. */
  public receiveContentChanged = (args: GenericEventArgs): void => {

    // We assign the task to the reference-input of the component, so args.reference
    //    refers to that task. The textinputcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the content-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'content', args.reference['content'], args.value); // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);
  };

  /** @description Processes a changerequest from a NoteEditorComponent. */
  public receiveNoteChange = (args: GenericEventArgs): void => {

    // We assign the task to the reference-input of the component, so args.reference
    //    refers to that task. The noteeditorcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the note-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'note', args.reference['note'], args.value); // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);
  };

  public receivePropertyChanged = (args: PropertyChangedEventArgs): void => {
    this.onTaskPropertyChanged.emit(args);
  };

  /**
   * @description The historyeditor can not close itself so it
   * throws an event, and here we catch it. And we catch other
   * events from there as well.
   */
  receiveCloseEventFromTaskEditor = (event: any): void => {
    this.hideTaskEditor();
  };

  receiveTaskChanged = (event: TaskChangedEventArgs): void => {
    this.onTaskChanged.emit(event);
  };
  // #endregion

  // #region TaskEditor --------------------------------------------------------------------------------------------------------------

  /**
   * @description Holds the ID of the currently selected StackItem
   * for which the noteeditor is shown.
   */
  public _showTaskEditorForTaskId: UUID = null;

  public isHiddenTaskEditor(task: ITask): boolean {
    return task.id !== this._showTaskEditorForTaskId;
  }

  public hideTaskEditor(): void {
    this._showTaskEditorForTaskId = null;
  }

  /** @description show or hide the editor for the given historystackitem. */
  public showTaskEditor(task: ITask): void {
    if (task) {
      // hide it if it's opened already.
      if (this._showTaskEditorForTaskId === task.id) {
        this.hideTaskEditor();
      } else {
        this._showTaskEditorForTaskId = task.id;
        // this.setFocus('nameEditor');  TODO !
      }
    } else {
      this.hideTaskEditor(); // hide all editors.
    }
  }
  // #endregion

  // #region Notes ----------------------------------------------------------------------------------------------------------------------

  /** @description Bookkeeping: contains the id of the task for
   * which a note is currently visible. Only one note at a time
   * is visible (or none).
   */
  public _showNoteId: UUID = null;

  /** @description Hide all notes
   */
  public hideNotes(): void {
    this._showNoteId = null;
  }

  /** @description Hide note on pressing escape in the note-editor
   */
  public note_onEsc(): void {
    this.hideNotes();
  }

  /** @description Shows or hides the note for the given task
   */
  public showNote(item: ITask): void {
    if (item) {
      if (this.isVisibleNote(item)) {
        this.hideNotes();
      } else {
        this._showNoteId = item.id;
        this.focusservice.setFocus('.timers #note-editor-' + String(item.id), this.renderer);
      }
    } else {
      this.hideNotes();  // hide all notes als leeg argument is opgegeven.
    }
  }

  /** @description Returns whether the note of the given task is
   * visible or not.
   */
  public isVisibleNote(item: ITask): boolean {
    return item && this._showNoteId === item.id;
  }
  // #endregion

  // #region Button clicks etc -----------------------------------------------------------

  /** @description Cancels the given timed task.
   */
  public cancel(task: ITask): void {
    const args = { task, newStatus: TaskStatus.Cancelled } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
    this.hideNotes();
  }

  /** @description Either stops or restarts the given timed task. */
  public stop(event: any, task: ITask): void {

    const newStatus = task.status === TaskStatus.Done ? TaskStatus.ToDo : TaskStatus.Done;
    const args = { task, newStatus } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
    this.hideNotes();
  }

  /** @description Either pauses or resumes the given timed task. */
  public pause(event: any, task: ITask): void {

    const newStatus = task.status === TaskStatus.Postponed ? TaskStatus.ToDo : TaskStatus.Postponed;
    const args = { task, newStatus } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
    this.hideNotes();
  }
  // #endregion

  // #region Specials ----------------------------------------------------------------------------------------------------------------

  /**
   * @description Make the done- and postponed-status values available to
   *the template (it is used for showing the pause/resume/stop/start-button).
   */
  postponedStatus = TaskStatus.Postponed;
  doneStatus = TaskStatus.Done;

  // #endregion
}
