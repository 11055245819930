import { TaskArrayContainer } from './task-array-container';
import { ITask } from './i-task';

export class FilteredTaskArrayContainer extends TaskArrayContainer {

  constructor() {
    super();
    this.filtered = new TaskArrayContainer();
  }

  /** @description Contains the filtered set (so less items than the entire set contains)
   */
  filtered: TaskArrayContainer;

  /** @description filterFunction: lambda, filterfunctie op de taskArray.
   */
  filterFunction: (task: ITask) => boolean;

  /** @description Past het huidige filter toe en bewaart het resultaat in de filtered-stack.
   */
  public applyFilter(): void {
    if (this.filterFunction) {
      this.filtered.items = this.items.filter(this.filterFunction);
    } else {
      this.filtered.items = this.items;
    }
  }

  /** @description Stelt het filter in maar past het nog niet toe
   */
  public setFilter(fltr: (task: ITask) => boolean): void {
    this.filterFunction = fltr;
  }

  public clearFilter(): void {
    this.filterFunction = null;
    this.applyFilter();
  }
}

