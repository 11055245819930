import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownEntry } from '../../components/dropdown/dropdown-entry';
import { TaskChangedEventArgs } from '../shared/task-changed-event-args';
import { ITask, TaskStatus, TaskCopier } from 'src/app/model/api/api.task';

@Component({
  selector: 'app-task-editor',
  templateUrl: './task-editor.component.html',
  styleUrls: ['./task-editor.component.scss']
})
/** Editor for a task.
 * Note: the editor doesn't change the task directly. It creates
 * a copy of the task, applies changes to that copy, and throws it
 * up to the parent when the user clicks 'save'.
 */
export class TaskEditorComponent implements OnInit {

  @Input() item: ITask;
  @Output() onClose: EventEmitter<string> = new EventEmitter<string>();
  @Output() onTaskChanged: EventEmitter<TaskChangedEventArgs> = new EventEmitter<TaskChangedEventArgs>();

  itemCopy: ITask;
  dropdownValues: DropdownEntry[];

  constructor() {

    this.itemCopy = {} as ITask;

    this.dropdownValues = [
      new DropdownEntry(TaskStatus.ToDo.toString(), 'Todo'),
      new DropdownEntry(TaskStatus.Doing.toString(), 'Doing'),
      new DropdownEntry(TaskStatus.Done.toString(), 'Done'),
      new DropdownEntry(TaskStatus.Cancelled.toString(), 'Cancelled'),
      new DropdownEntry(TaskStatus.Postponed.toString(), 'Postponed')
    ];
  }

  ngOnInit() {
    const taskCopier = new TaskCopier();
    taskCopier.copyStoneAge(this.item, this.itemCopy);
    this.itemCopy.id = this.item.id;    // copy guid by hand because copier is not allowed to do so, by me :-)
  }

  /** @description Close this editor.
   */
  close(): void {
    this.onClose.emit('closeStackitemEditor');
  }

  /** @description Save the changes.
   */
  save(): void {
    const args = { originalTask: this.item, changedTask: this.itemCopy } as TaskChangedEventArgs;
    this.onTaskChanged.emit(args);
  }

  // #region Process child events ---------------------------------------------------------------------------------------------------------

  /** @description Fires when the value in the status-dropdown
   *  element has changed.
   *  @param event The value of the newly selected item (it's a
   *  string).
   */
  receiveTaskStatusChanged(event: any) {
    this.itemCopy.status = Number(event);     // we can set this value directly because it's a copy. On save it will be propagated.
  }

  // #endregion
}
