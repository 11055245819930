import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITask } from 'src/app/model/task/i-task';

export interface DialogData {
  userResponseText: string;
  title: string;
  question: string;
  label: string | null;
  okTitle: string | null;
  cancelTitle: string | null;
  task: ITask;
}

/**
 * @description Dialog with textinput and OK- and Cancelbuttons.
 */
// example from https://material.angular.io/components/dialog/overview
@Component({
  selector: 'app-ok-cancel-text-input-dialog',
  templateUrl: './ok-cancel-text-input-dialog.component.html',
  styleUrls: ['./ok-cancel-text-input-dialog.component.scss']
})
export class OkCancelTextInputDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OkCancelTextInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  cancel(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

}
