import { ATask } from './a-task';
import { ITimedTask } from './i-timed-task';
import { IDuration } from '../timing and duration/duration/iduration';
import { TimingPatternInfo } from '../timing and duration/timingPattern/timing-pattern-info';
import { ITask } from './i-task';
import { TaskStatus } from './task-status.enum';

/** A timed-task is a decorator for tasks. */
export class ATimedTask extends ATask implements ITimedTask {

  /** @description the decorated task */
  task: ITask;      // it should be private but it can't be private because it must be inherited

  // New properties

  timerName: string;
  timerSubscriptionId: string;
  firstTimerTick: Date;
  shiftingFirstTimerTick: Date;
  nextTimerTick: Date;
  resumeDelay: IDuration;
  timingPatternInfo: TimingPatternInfo;

  // Properties that exist on the decorated task are forwarded to that decorated task

  get id(): string {
    return this.task.id;
  }

  set id(val: string) {
    this.task.id = val;
  }

  get shortId(): string {
    return this.task.shortId;
  }

  set shortId(val: string) {
    this.task.shortId = val;
  }

  get order(): number {
    return this.task.order;
  }

  set order(val: number) {
    this.task.order = val;
  }

  get parentId(): string {
    return this.task.parentId;
  }

  set parentId(val: string) {
    this.task.parentId = val;
  }

  get content(): string {
    return this.task.content;
  }

  set content(val: string) {
    this.task.content = val;
  }

  get note(): string {
    return this.task.note;
  }

  set note(val: string) {
    this.task.note = val;
  }

  get noteHeight(): number {
    return this.task.noteHeight;
  }

  set noteHeight(val: number) {
    this.task.noteHeight = val;
  }

  get status(): TaskStatus {
    return this.task.status;
  }

  set status(val: TaskStatus) {
    this.task.status = val;
  }

  get dateCreated(): Date {
    return this.task.dateCreated;
  }

  set dateCreated(value: Date) {
    this.task.dateCreated = value;
  }

  get dateStarted(): Date {
    return this.task.dateStarted;
  }

  set dateStarted(value: Date) {
    this.task.dateStarted = value;
  }

  get dateDone(): Date {
    return this.task.dateDone;
  }

  set dateDone(value: Date) {
    this.task.dateDone = value;
  }

  get level(): number {
    return this.task.level;
  }

  set level(value: number) {
    this.task.level = value;
  }

  get children(): ITask[] {
    return this.task.children;
  }

  set children(value: ITask[]) {
    this.task.children = value;
  }



}
