/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { formatDate } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, DoCheck } from '@angular/core';
import { fromEvent } from 'rxjs';
import { IEntity } from 'src/app/model/shared/ientity';
import { GenericEventArgs } from '../shared/generic-event-args';

/**
 * @description Note editor. To edit notes.
 *
 * Functionalities:
 * - contents are saved on blur();
 * - there can be multiple notes (note-components) on a page;
 * - note-height is expandable/collapsable;
 * - note-height is saved;
 * - F12 inserts datetime;
 * - URL's are recognized and extracted from the note and shown below it as clickable hyperlinks;
 * - NOT YET IMPLEMENTED: inserted datetime is according to user preference 'locale';
 * - NOT YET IMPLEMENTED: Escape closes the note;
 */
@Component({
  selector: 'app-note-editor',
  templateUrl: './note-editor.component.html',
  styleUrls: ['./note-editor.component.scss']
})
export class NoteEditorComponent implements OnInit, AfterViewInit, DoCheck {

  /** @description a unique ID, used for uniqueness of some element-names
   * in case this component exists multiple times on the same page.
   */
  public localID: string;

  /**
   * @description Object the parent component needs in order to identify what entity a changed value belongs to.
   * Eslint complains about the 'any' type so I created the IEntity interface.
   */
  @Input() public reference: IEntity;

  /** @description the data this component is responsible for.
   */
  @Input() public value: string;

  @Output() onChange = new EventEmitter<GenericEventArgs>();

  @Output() onChangeHeight = new EventEmitter<GenericEventArgs>();

  /** @description Height of the note. To be set from outside.
   */
  @Input() height: string;

  /** @description A ref to our hosting element.
   * Note: for this 'noteeditor'-string below to work, the target
   * element in the view must be marked as #noteeditor.
   */
  @ViewChild('noteeditor', {}) inputElement: ElementRef;
  @ViewChild('hyperlinks', {}) hyperlinksElement: ElementRef;
  @ViewChild('renderedNote', {}) renderedHTMLElement: ElementRef;
  // └https://indepth.dev/exploring-angular-dom-manipulation-techniques-using-viewcontainerref/

  constructor() { }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
    this.localID = this.reference.id;
  }

  ngAfterViewInit(): void {

    // apply the saved height of a note.
    this.getElement().style.height = this.height + 'px';  // height is set by an @Input.

    // Monitor and save note-height
    new ResizeObserver(() => this.saveNoteHeight()).observe(this.getElement());

    this.fillHyperlinks();
    this.fillHTMLRenderArea();

    // Catch F12-key to insert current datetime
    const textarea: HTMLTextAreaElement = this.getElement();
    if (textarea) {
      fromEvent(textarea, 'keydown')
        .subscribe((event: KeyboardEvent) => {
          if (event.key.toLowerCase() === 'f12') {
            const datetimestring = formatDate(Date.now(), 'dd MMM yyyy HH:mm', 'en-US') + ': ';
            // todo: └ locale en format uit usersetting halen
            this.insertTextIntoTextareaElement(textarea, datetimestring);
            event.preventDefault();   // don't forward F12 (the browser dev-tools open/close)
          }
          else if (event.key.toLowerCase() === 'tab') {
            this.insertTextIntoTextareaElement(textarea, '\t');
            event.preventDefault();   // don't forward tab (it would leave the note)
          }
        });
    }
  }

  /**
   * @description Insert text at the current caretposition in the textarea-element, and move the caret accordingly.
   * @param textarea A reference to an HTMLTextAreaElement object
   * @param text The text to be inserted
   */
  private insertTextIntoTextareaElement = (textarea: HTMLTextAreaElement, text: string): void => {
    if (textarea.setRangeText) {
      // if setRangeText function is supported by current browser
      textarea.setRangeText(text);
    } else {
      document.execCommand('insertText', false, text);  // for old broswsers (false means /*no UI*/)
    }
    textarea.selectionStart = textarea.selectionStart + text.length;
  };

  /** @description Returns a 'handle' to the main element of this component: the textarea element.
   */
  public getElement(): HTMLTextAreaElement {

    const elem = this.inputElement.nativeElement as HTMLTextAreaElement;
    return elem;
  }

  public getElementForRenderedHTML(): HTMLPreElement {
    const elem = this.hyperlinksElement.nativeElement as HTMLPreElement;
    return elem;
  }

  /** @description gathers values together for sending with change-request-events. Does
   * NOT contain the new value!
   */
  public getNewEventArgs(): GenericEventArgs {
    return new GenericEventArgs(this.reference, null);
  }

  /** @description fires when a user leaves the textarea element.
   */
  public onBlur(): void {
    this.saveNoteContent();
    this.fillHyperlinks();
    this.fillHTMLRenderArea();
  }

  /** Fills the hyperlinks element with all links that can be extracted from the note contents. */
  private fillHyperlinks(): void {
    (this.hyperlinksElement.nativeElement as HTMLDivElement).innerHTML = this.getHyperlinksFromText(this.Contents);
  }

  /**
   * @description Recognizes url's from the text and compiles from them an html-injectable collection of hyperlinks.
   * @param text.
   * @param separator: default BR (tag). Used to join the links together.
   * @param target: default 'blank'. Target attribute for the hyperlinks.
   * @returns a collection of hyperlinks that can be directly injected into html.
   */
  getHyperlinksFromText(text: string, separator: string = '<br/>', target: string = 'blank'): string {
    const links = this.getHtmlLinksFromText(text, target);
    let result = '';
    if (links) {
      result = links.join(separator);
    }
    return result;
  }

  /**
   * @description Recognizes url's from the text and compiles from them an array of hyperlinks.
   * @param text.
   * @param target: default 'blank'. Target attribute for the hyperlinks.
   * @returns An array list of hyperlinks.
   */
  private getHtmlLinksFromText(text: string, target: string = 'blank'): string[] {

    const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    const urls: string[] = text.match(urlRegex);

    if (urls) {
      // manipulate array with foreach, see https://stackoverflow.com/questions/12482961/change-values-in-array-when-doing-foreach
      urls.forEach(function(url, index) {
        this[index] = '<a href="' + url + '" target="' + target + '">' + url + '</a>';
      }, urls); // use urls as 'this'

      return urls;
    }

    return [];
  }

  /** Renders basic HTML and injects it into the pre-element: bold, italic, h1, h2, h3, hyperlinks, newline, tab. */
  private fillHTMLRenderArea(): void {

    // hyperlinks
    let output: string = this.renderHyperlinksInText(this.Contents);

    // *bold* and _italics_
    const boldReg = /\*([^*]+)\*/g; // matches bolds *like this*. Original: /\*([^*><]+)\*/g, so includes NOT _, > and <, but then bold+italic is impossible.
    const italicReg = /_([^_]+)_/g; // matches italics _like this_. Original: /_([^_><]+)_/g, so includes NOT _, > and <, but then bold+italic is impossible.
    // └taken and adapted from: https://stackoverflow.com/questions/73002812/regex-accurately-match-bold-and-italics-items-from-the-input
    output = output.replace(boldReg, '<b>$1</b>').replace(italicReg, '<i>$1</i>');

    // #header1, ##header2, ...
    const h1Reg = /#\s*(.*)/g; // matches # at beginning of line, captures everything after the hash.
    const h2Reg = /##\s*(.*)/g;
    const h3Reg = /###\s*(.*)/g;
    output = output.replace(h3Reg, '<h3>$1</h3>');    // Match in reverse order, of course.
    output = output.replace(h2Reg, '<h2>$1</h2>');
    output = output.replace(h1Reg, '<h1>$1</h1>');

    // newline & tab is solved by making the element a <pre>. Tip from Matt, https://stackoverflow.com/questions/2665566/render-tab-characters-in-html
    (this.renderedHTMLElement.nativeElement as HTMLPreElement).innerHTML = output;
  }

  /**
   * @description Recognizes url's from the text and replaces them by clickable anchor elements.
   * @param text.
   * @param urlTarget: default 'blank'. Target attribute for the hyperlinks.
   * @returns a text with html anchors, if any.
   *
   * Note: you can test a regex and see its groups using exec, examples:
   *
   * - /(\[([a-zA-Z0-9\s+\-*&@#\/%\?=~_!^()\,.$]+)\])?((https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig.exec('[my link]http://vk.nl')
   *
   * - urlRegex.exec('[stefan]https://dev.azure.com/atlascopco-ctba/Smartlink%202.0/_sprints/backlog/Smartlink%202.0%20Team/Smartlink%202.0/Sprint%2072?workitem=62832')
   *
   */
  renderHyperlinksInText(text: string, urlTarget: string = 'blank'): string {

    // const onlyUrl = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    // matches http://www.abc.nl, but also [my link and many strange characters like %#*( ) =+,. etc]http://www.abc.nl
    const urlWithSquareBracketsPrependedRegexp = /(\[([a-zA-Z0-9\s+\-*&@#\/%\?=~_!^()\,.:;"`'|$─☺☻♥♦♣♠•◘○◙♂♀♪♫☼►◄↕‼¶§▬↨↑↓→←∟↔▲▼]+)\])?((https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

    // groups and subgroups in the resulting array of regexp.exec:
    // $0 is het totale patroon;
    // $1 is deel 1: de blokhaken sectie (soms leeg want optioneel);
    // $2 is sub van deel 1: wat binnen de blokhaken staat (soms leeg want optioneel);   ◄◄ WE NEED THIS ONE
    // $3 is deel 2: de url.                                                             ◄◄ AND THIS ONE
    // $4 is sub van deel 2: alleen het http-verb.
    // We kunnen voor de url (href) dus altijd $3 gebruiken. Voor de text van het anchor checken we @2: is die leeg dan wordt het gewoon $3 zelf.

    const result = text.replace(urlWithSquareBracketsPrependedRegexp, (g0, g1, g2, g3, g4): string => {
      let out: string;
      if (g2) {
        out = `<a href="${g3}" target="${urlTarget}">►${g2}</a>`;
      }
      else {
        out = `<a href="${g3}" target="${urlTarget}">►${g3}</a>`;
      }
      return out;
    });
    return result;
  }

  /** @description Saves the note to the datasource.
   */
  private saveNoteContent(): void {

    // send a data-change-request to the parent
    const args = this.getNewEventArgs();
    args.value = this.Contents;
    this.onChange.emit(args);
  }

  /** Current contents of the note */
  private get Contents(): string {
    return this.getElement().value;
  }

  /** @description Saves the height of the note to the task.
   */
  private saveNoteHeight(): void {

    // send a height-change-request to the parent
    const args = this.getNewEventArgs();
    args.value = (this.getElement().clientHeight + 10).toString();
    if (args.value && parseInt(args.value, 10) > 10) {
      this.onChangeHeight.emit(args);
    }
  }

  ngDoCheck(): void {                                       // ngDoCheck: google angular's lifecyle

    // detect note-changes from the outside world
    // const elem: HTMLTextAreaElement = this.getElement();

    // if the textbox' contents have diverged from the current value, then reset the contents to the value.
    // if (elem && elem.value !== this.value) {
    //        // note: the check on 'elem' is just for making tests succeed: it prevents the error cannot read property of null.
    //   elem.value = this.value;
    // }
  }

  /** @description Hide note on pressing escape in the note-editor
   */
  public note_onEsc(): void {
    console.warn('esc in note nog niet geimplementeerd!');
  }
}
