import { TimingPatternEnum } from './item-timing-pattern.enum';

/**
 * @description Holds a tuple of a timing pattern and it's corresponding regular expression. The pattern
 * is used for recognizing the pattern in userinput.
 */
export class ItemTimingPatternRegexp {

  /**
   * @description Type of timing-pattern
   */
  ItemTimingPattern: TimingPatternEnum;

  /**
   * @description Regular expression that would recognize the above type of pattern.
   */
  Regexp: RegExp;

  constructor(pattern: TimingPatternEnum, regexp: RegExp) {
    this.ItemTimingPattern = pattern;
    this.Regexp = regexp;
  }
}
