

<app-top-bar
    class="top-bar"
    (notify)="onNotificationFromTopBar($event)"
    (onFileOpenRequest)="receiveFileOpenRequest($event)"
    [soundVolume]="settings.soundVolume"
    (onSoundVolumeChangeRequest)="receiveSettingChangeRequest($event)"
    [tag]="settings.tag"
    (onTagChangeRequest)="receiveSettingChangeRequest($event)"
    (onHeightChangeChange)="receiveTopbarHeightChange($event)"
    >
</app-top-bar>

<app-help
    *ngIf="isHelpscreenVisible"
    (notify)="onNotificationFromHelpscreen($event)"
    >
</app-help>

<app-download-anchor></app-download-anchor>

<div class="wrapper" [ngStyle]="{'top.px': TopBarHeight}">

  <div id="section1">
    <app-doing
        [data]="dataPublisher"
        class="doing"
        (onNewTask)="receiveNewTask($event)"
        (onTaskStatusChanged)="receiveTaskStatusChanged($event)"
        (onTaskPropertyChanged)="receiveTaskPropertyChanged($event)"
        (onTaskGotFocus)="onNotificationTaskGotFocus($event)"
    ></app-doing>

    <app-timers
        [dataservice]="dataPublisher"
        class="timers"
        (onTaskStatusChanged)="receiveTaskStatusChanged($event)"
        (onTaskChanged)="receiveTaskChanged($event)"
        (onTaskPropertyChanged)="receiveTaskPropertyChanged($event)"
    ></app-timers>

    <app-done
        [data]="dataPublisher"
        class="done"
        (onTaskChanged)="receiveTaskChanged($event)"
    ></app-done>

    <!-- documentation -->
    <app-tree
      #docuTree
      [showStatusButton]="false"
      [activatedTask]=""
      [hidden]="false"
      [title]="'docu'"
      [showTotalSize]="false"
      [showSubFilterCheckbox]="false"
      defaultNewTaskContents="Dus zo zit het"
      defaultNewTaskStatus="7"
      [filter]="isRootDocTask"
      [tasks]="dataPublisher"
      [settings]="settingsPublisher"
      [showDocumentationButton]="false"
      [showDoNowButton]="false"
      class="todo"
      (onSettingChanged)="receiveSettingChangeRequest($event)"
      (onNewTask)="receiveNewTask($event)"
      (onTaskStatusChanged)="receiveTaskStatusChanged($event)"
      (onTaskPropertyChanged)="receiveTaskPropertyChanged($event)"
    ></app-tree>
  </div>

  <div id="section2">

    <!-- To-do tree -->
    <app-tree
      #todoTree
      [showStatusButton]="true"
      [activatedTask]="focussedDoing"
      [hidden]="false"
      [title]="'to do'"
      [showTotalSize]="true"
      [showSubFilterCheckbox]="true"
      [showDocumentationButton]="true"
      [showDoNowButton]="true"
      defaultNewTaskContents="New_todo"
      defaultNewTaskStatus="0"
      [filter]="isRootTodoTask"
      [tasks]="dataPublisher"
      [settings]="settingsPublisher"
      class="todo"
      (onSettingChanged)="receiveSettingChangeRequest($event)"
      (onNewTask)="receiveNewTask($event)"
      (onTaskStatusChanged)="receiveTaskStatusChanged($event)"
      (onTaskPropertyChanged)="receiveTaskPropertyChanged($event)"
    ></app-tree>

  </div>

</div>
