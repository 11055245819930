import { ITimingPattern } from './itiming-pattern';
import { IPattern } from 'src/app/model/shared/ipattern';
import { IPatternRecognitionResult } from 'src/app/model/shared/ipattern-recognition-result';


/** @description Represents the result of recognizing a timing-command-pattern in userinput.
 *
 */
export class TimingPatternInfo implements IPatternRecognitionResult {

  inputText: string;

  recognizedPattern: IPattern;

  outputTextArray: string[];

  timingpattern: ITimingPattern;
}
