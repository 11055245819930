<div id="doing-section" class="doing repress breath cool">

      <div class="rij">
        <h4>doing &nbsp;</h4>
        <sup class="stack-counter">{{stack.items.length}}</sup>
        <!-- <label (click)="resetParent_onClick()">new</label> -->
        <label id="parent-doing" class="controlpanel-parent-id">{{_activeParentId ? _activeParentId.shortID(): ''}}</label>
    </div>

    <label for="new-doing" style="display: none;">Type what you are doing and press enter</label>
    <input type="text" id="new-doing" class="controlpanel-input mat-app-background"
        placeholder="Type here what you are currently doing, then press Enter ..."
        (keyup.enter)="taskInsertionField_onEnter($event.target)">

    <!-- alles met cdk ervoor is gerelateerd aan dragndrop van angular material. De Trigger-dingen zijn van een andere lib-->
    <div *ngFor="let item of stack.items" class="offset" [ngClass]="stack.isTop(item) ? '' : 'inactive'" >

        <!-- <span id="animation1" [@newTop]="(isTop(item) && item.state!=3) ? 'isTop' : 'isNotTop'"> -->
        <span class="identifier"> {{item.id.shortID()}} <span class="parent-identifier"
                [hidden]="!item.parentId">({{item.parentId ? item.parentId.shortID() : ''}})</span></span>

        <!-- documentation -->
        <button
          mat-raised-button
          class="button"
          matTooltip="Create documentation from this task"
          (click)="doctate(item)">

          <span class="material-symbols-outlined">bookmark</span>
        </button>

        <!-- organize/subtask-->
        <button
          mat-raised-button
          matTooltip="Organize (make this task a subtask)"
          class="button"
          (click)="organize(item)">

          <span class="material-symbols-outlined">low_priority</span>
        </button>

        <!-- mark task as done or cancelled -->
        <span [hidden]="!stack.isTop(item)">
            <span>

                <button mat-raised-button matTooltip="Mark task as done" class="button" (click)="done($event, item)"><span class="material-symbols-outlined">done</span></button>

                <button mat-raised-button matTooltip="Cancel this task" class="button" (click)="cancel($event, item)"><span class="material-symbols-outlined">close</span></button>

            </span>
        </span>

        <!-- note (button)-->
        <button
          mat-raised-button
          matTooltip="Toggle note visibility"
          class="button"
          (click)="showNote(item)">

          <span
            class="material-symbols-outlined">{{item.note && item.note.length > 0 ? 'description': 'note'}}
          </span>
        </button>

        <!-- postpone task-->
        <span [hidden]="!stack.isTop(item)">
            <button
              mat-raised-button
              matTooltip="postpone"
              class="button"
              (click)="postpone(item)">
              <span class="material-symbols-outlined">clear_night</span>
            </button>
        </span>

        <!-- resume this postponed task -->
        <span [hidden]="item.status !== postponedStatus">
            <button
              mat-raised-button
              class="button"
              (click)="reopen(item)"
              matTooltip="resume"
              >
              <span class="material-symbols-outlined">restart_alt</span>
            </button>
        </span>

        <!-- block task-->
        <span [hidden]="!stack.isTop(item)">
          <button
            mat-raised-button
            matTooltip="block"
            class="button"
            (click)="block(item)">
            <span class="material-symbols-outlined">block</span>
          </button>
      </span>

      <!-- unblock this blocked task -->
      <span [hidden]="item.status !== blockedStatus">
          <button
            mat-raised-button
            class="button blocked"
            (click)="reopen(item)"
            matTooltip="unblock ({{item.reasonBlocked}})"
            >
            <span class="material-symbols-outlined">lock_open</span>
          </button>
      </span>


        <!-- the below span contains the icon for moving a todo around (copied from material.angular) -->
        <span [hidden]="!stack.isTop(item)" class="doing-item-draghandle" cdkDragHandle>
            <svg width="24px" fill="antiquewhite" viewBox="0 0 24 24">
                <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                </path>
                <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
        </span>


      <a id="{{item.id}}" >
        <!-- task contents -->
        <app-text-input
          [value]="item.content"
          [identifier]="item.id"
          [reference]="item"
          (onChange)="receiveContentChanged($event)"
          (click)="inputEntered(item)"
        ></app-text-input>
      </a>

        <!-- note contents -->

        <div class="note" *ngIf="isVisibleNote(item)">
            <app-note-editor
              [value]="item.note"
              [height]="item.noteHeight"
              [reference]="item"
              (onChange)="receiveNoteChange($event)"
              (onChangeHeight)="receiveNoteHeightChange($event)"
              ></app-note-editor>
        </div>
    </div>

</div>
