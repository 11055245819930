/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITaskFlatNode } from '../tree/i-task-flat-node';
import { CancelEvent } from '../tree/cancel-event';
import { GenericEventArgs } from '../../usercontrols/shared/generic-event-args';
import { ITask, TaskStatus, TaskStatusChangedEventArg } from 'src/app/model/api/api.task';


/**
 * @description Parent or leaf in a tree, for todo-, documentatoin- or any other tree-component.
 *
 * It consists of some buttons, an inputfield and a note-area.
 *
 * I created this to avoid duplicate html.
 *
 * [IS THIS STILL TRUE?] This component is inseparably bound to the todo-component and is merely
 * a refactoring of duplicated html in the view (which arose because of implementing
 * the todo-list as a tree, as a result of which any task is either a leaf or a
 * expandable note. Voilà duplication of html).
 * Alas, it's a lot of wire up for just duplicate html so I wonder whether I
 * should have chosen a simpler solution.
 */
@Component({
  selector: 'app-treenode-content',
  templateUrl: './treenode-content.component.html',
  styleUrls: ['./treenode-content.component.scss']
})
export class TreenodeContentComponent implements OnInit {

  @Input() node: ITaskFlatNode;
  @Input() mustShowDoNowButton: boolean;
  @Input() mustDisableDoNowButton: boolean;
  @Input() doNowButtonText: string;
  @Input() mustShowCancelButton: boolean;
  @Input() mustDisableCancelButton: boolean;
  @Input() cancelButtonText: string;
  @Input() isNoteVisible: boolean;
  @Input() mustDisableSubtaskButton: boolean;
  @Input() showDocumentationButton: boolean;
  @Input() mustShowStatusButton: boolean;

  @Output() onSubtask = new EventEmitter<ITask>();
  @Output() onCancel = new EventEmitter<CancelEvent>();
  @Output() onDoNow = new EventEmitter<ITask>();
  @Output() onShowNote = new EventEmitter<ITask>();
  // eslint-disable-next-line max-len
  @Output() onDoctate = new EventEmitter<TaskStatusChangedEventArg>();  // user clicks 'Doc' button in order to convert a task into documentation
  @Output() onContentChange = new EventEmitter<GenericEventArgs>();
  @Output() onNoteChange = new EventEmitter<GenericEventArgs>();
  @Output() onNoteHeightChange = new EventEmitter<GenericEventArgs>();
  // especially for 'doctating': when a task is converted into documentation, its parent must be set to null.
  @Output() onParentChange = new EventEmitter<GenericEventArgs>();

  constructor() { }

  public ngOnInit = (): void => {
  };

  public subtask = (task: ITask): void => {
    this.onSubtask.emit(task);
  };

  public do = (task: ITask): void => {
    this.onDoNow.emit(task);
  };

  public cancel = (event: MouseEvent, task: ITask): void => {
    const evt = new CancelEvent(event, task);
    this.onCancel.emit(evt);
  };

  public showNote = (task: ITask): void => {
    this.onShowNote.emit(task);
  };

  public receiveNoteChange = (args: GenericEventArgs): void => {
    this.onNoteChange.emit(args);
  };

  public receiveNoteHeightChange = (args: GenericEventArgs): void => {
    this.onNoteHeightChange.emit(args);
  };

  public receiveContentChange = (args: GenericEventArgs): void => {
    this.onContentChange.emit(args);
  };

  /** user clicks 'doc' button to convert task into documentation (hence 'doctate') */
  public doctate = (task: ITask): void => {

    // note: when this task has children, that is handled further on in the call chain.
    const args = {
      task,
      newStatus: TaskStatus.Documentation
    } as TaskStatusChangedEventArg;

    this.onDoctate.emit(args);
  };
}
