// angular deps
import { Inject } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';

// application deps
import { ITKN_IDURATIONFACTORY, ITKN_ILOGSERVICE, ITKN_ITIMERSERVICE } from 'src/app/application/injectionTokens';

// ccc deps
import { ILogService } from 'src/app/cross-cutting-concerns/logger/i-logger.service';

// model deps (internal deps)
import { IDuration } from '../duration/iduration';
import { IDurationFactory } from '../duration/iduration-factory';
import { ITimedTask } from '../../task/i-timed-task';
import { ITimerService } from '../timerservice/itimer.service';
import { TimeComputer } from '../timecomputer/time-computer';
import { TimingPatternInfo } from '../timingPattern/timing-pattern-info';
import { ITimedTaskHelper } from './itimed-task-helper';
import { TimedTaskSubscriptionInfo } from './timed-task-subscription-info';

/** @description Helper for timedTasks: makes creating and subscribing to a task easy. */
export class TimedTaskHelper implements ITimedTaskHelper {
  constructor(
    @Inject(ITKN_IDURATIONFACTORY) public durationFactory: IDurationFactory
    , @Inject(ITKN_ITIMERSERVICE) public timerservice: ITimerService
    , @Inject(ITKN_ILOGSERVICE) public logger: ILogService
  ) {

  }

  /** @description Starts a timer given a timing-pattern, but without subscribing to it.
   */
  createTimerFromPattern(timingPatternInfo: TimingPatternInfo, overrulingDelay?: IDuration): string {

    // in test/debug situations, if debug is set to true (below), times are set to a few seconds.
    if (!environment.production) {

      const debug = false;  // feel free to modify

      if (debug) {
        console.warn('Overrule timingpattern with delay 10, repeat 6.');
        timingPatternInfo.timingpattern.delay = this.durationFactory.createDuration(0, 0, 10);
        timingPatternInfo.timingpattern.repeat = this.durationFactory.createDuration(0, 0, 6);
      }
    }

    // delay can be overruled by a parameter
    let delay = timingPatternInfo.timingpattern.delay;
    if (overrulingDelay) {
      delay = overrulingDelay;
    }

    const timerName: string = UUID.UUID();

    if (timingPatternInfo.timingpattern.isRepetitive) {

      // repetitive timer
      this.timerservice.newTimerCD(     // CD = Custom Delay
        timerName
        , timingPatternInfo.timingpattern.repeat.durationInSeconds
        , delay.durationInSeconds);
    } else {
      // non-repetitive

      const skipFirstTick = true;

      this.timerservice.newTimer(
        timerName
        , delay.durationInSeconds
        , skipFirstTick);
    }

    return timerName;
  }

  /** @description Subscribes the task to a timer. <br>
   * It doesn't change the task but instead returns an info-object with all suggested changes.
   *
   * If timerName is not provided, the task's timerName is used.
   */
  subscribeToTimer(task: ITimedTask, callback: () => void, timerName?: string): TimedTaskSubscriptionInfo {

    // First determine timername situation
    if (!timerName && !task.timerName) {
      throw new Error('TimerName is required for subscribing a task to a timer.');
    }

    const info = new TimedTaskSubscriptionInfo();
    info.TimerName = timerName ?? task.timerName;

    // Now subscribe task to timer

    // Note that subscription happens in all current known timing parameter-
    //    scenario's: whether delayed, repetitive, paused or stopped.

    info.SubscriptionID = this.timerservice.subscribe(info.TimerName, callback);

    if (!info.SubscriptionID) {
      console.error('Failed to subscribe with timername ' + info.TimerName);

      const ls = this.timerservice.getTimer();
      if (ls.length !== 0) {
        ls.forEach(element => {
          console.log('available timer: ' + element);
        });
      }
      else {
        console.log('no timers available');
      }
    }

    // Only now we know when the timer started and when it will fire next time.

    // init the timecomputer
    const timecomputer = new TimeComputer(this.durationFactory, this.logger);
    const now = new Date();

    // firstTimerTick is only set the very very first time.
    if (!task.firstTimerTick) {

      const v_firsttimertick: Date = task.timingPatternInfo.timingpattern.hasDelay ?
        timecomputer.addDurationToDate(now, task.timingPatternInfo.timingpattern.delay) :
        now;
      info.FirstTimerTick = v_firsttimertick;
      info.Note = String(task.note) + '\nFirst alarm is at: ' + String(info.FirstTimerTick);


    } else {
      // so here we have a paused timer that is resuming, or a stopped timer that is restarting.
      //  Therefor we set the shifting firsttimertick.

      const v_shiftingFirsttimertick: Date = task.timingPatternInfo.timingpattern.hasDelay ?
        timecomputer.addDurationToDate(now, task.timingPatternInfo.timingpattern.delay) :
        now;
      info.ShiftingFirstTimerTick = v_shiftingFirsttimertick;
      info.Note = String(task.note) + '\nNew first alarm is at: ' + String(info.ShiftingFirstTimerTick);
    }

    // For repetitive tasks: forecast next tick, it's for showing some info in the GUI.
    //   Note: we're subscribing, which only happens just before the first tick, even-
    //      tually after a task has been paused. So we don't have to reckon here with
    //      tasks just repeating.
    if (task.timingPatternInfo.timingpattern.isRepetitive) {   // next ticks are only relevant in case of repetitive tasks.
      let v_nexttimertick: Date;
      if (!info.ShiftingFirstTimerTick) {                      // if it's not a paused task (paused tasks have a shifting firsttimertick).
        // We're in the process of subscribing, so count next tick from the first tick

        v_nexttimertick = info.FirstTimerTick > now ?        // firstTimerTick can be in the future: i.e. when it has a delay.
          info.FirstTimerTick :
          new TimeComputer(this.durationFactory, this.logger)
            .addDurationToDate(info.FirstTimerTick, task.timingPatternInfo.timingpattern.repeat);

      } else {
        // We're in the process of re-subscribing because of resuming or restarting a timed
        //    task after a pause, of after having been stopped. In that case we make use
        //    of the shifting firsttimertick.

        v_nexttimertick = info.ShiftingFirstTimerTick > now ?
          info.ShiftingFirstTimerTick :
          new TimeComputer(this.durationFactory, this.logger)
            .addDurationToDate(info.ShiftingFirstTimerTick, task.timingPatternInfo.timingpattern.repeat);
      }
      info.NextTimerTick = v_nexttimertick;
    }

    return info;
  }

}
