import { ITask } from '../task/i-task';
import { IImplements } from './iimplements';
import { ITimedTask } from '../task/i-timed-task';

/**
 * @description Exoerimental, intent: to check not just implementation, but semantics do that not
 * exactly fit to interfaces or classes or their states.
 * Experimental, this is not the preferred way! (this code 'd better be placed in the related class).
 *
 * Example: a task can optionally be timed and then either is to fire a single time or repetitively. At a given
 * moment it may still have to fire or already has done so, or it is still repetitively firing or has ceased to do so.
 * Such a task contains all info about its timing. This is an obfuscation of several concepts, but we're not done yet.
 * In the timers-list we only want to show 'running timers', so timed tasks that still have to fire.
 * In the doing-stack we only want to show timed tasks if they have status 'doing' or 'postponed'.
 * In the done-stack we only want to show timed tasks if they have status 'done' or 'cancelled'.
 *
 */
export class Semantics {

  /** Check whether a task is a timer, i.e. whether is actually subscribed to a timerservice.
   * (we don't check the timer exists in the service, but we assume it does).
   * Current implementation (may change): timerName and timerSubscriptionId are both set.
   */
  static IsARunningTimer(task: ITask): boolean {
    return IImplements.ITimedTask(task) && !!(task).timerSubscriptionId;
    // implements iTimedTask AND it has a subscription (whether the timer has expired or not is not relevant)
  }
}
