<span
	id="text-input-contents-{{identifier}}"
	contenteditable="true"
	(blur)="onBlur($event)"
  class="mat-app-background"
  >
	{{value}}
</span>


