/* eslint-disable no-shadow */

/**
 * @description When saving or backing up a file you can overwrite the existing or create a new one with a unique timestamp.
 */
export enum StorageMethods {
  Overwrite = 1,
  TimeStamp = 2
  // OverwriteWithDataLossProtection = 3
}

