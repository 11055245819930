/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable guard-for-in */
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IGoogleAuthorizationService } from '../igoogle-authorization-service';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthorizationService1 implements IGoogleAuthorizationService {

  // #region Const

  // client id of the mindstack app at Google.
  public CLIENT_ID = '671766379639-kaq8ml3qevgbkevgcq16ganokvc5uc55.apps.googleusercontent.com';

  public AUTH_SERVER = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Is overruled in the constructor when env = develop!
  public REDIRECT_URI = 'https://whatiamdoing.net';
  // For local development use: 'http://localhost:4200';
  // For production use: 'https://whatiamdoing.net'
  // └─'whatiamdoing.net' => Fout 400: invalid_request; // 'https://whatiamdoing.net/' => Fout 400: redirect_uri_mismatch;

  // Authorization scopes required by the API; multiple scopes can be included, separated by spaces.
  public SCOPES = 'https://www.googleapis.com/auth/drive.file'; // https://www.googleapis.com/auth/drive.metadata.readonly';

  public ACCESS_TOKEN: string;

  // Discovery doc URL for APIs used by the quickstart
  // private DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest';

  // development key, is unrestricted so don't publish it!
  // private API_KEY = '';

  // For extra security?
  // private STATE = 'as9823hus99e8wueii';
  // #endregion


  constructor(
    private route: ActivatedRoute,
  ) {

    if (!environment.production) {
      this.REDIRECT_URI = 'http://localhost:4200';  // for correct settings see comment at REDIRECT_URI def.
    }
  }


  SignIn(): void {
    throw new Error('Method not implemented.');
  }


  SignOut(): void {
    throw new Error('Method not implemented.');
  }



  public oauthToGoogleDrive(): void {

    // SK: i think for SPA's, we (google) are using the IMPLICIT FLOW.
    // This I base on remarks on the implicit flow here: https://www.oauth.com/oauth2-servers/single-page-apps/
    // which says that "Some services use the alternative Implicit Flow for single-page apps, rather than allow
    // the app to use the Authorization Code flow with no secret. The Implicit Flow bypasses the code exchange
    // step, and instead the access token is returned in the query string fragment to the client immediately."

    // Google's OAuth 2.0 endpoint for requesting an access token
    const oauth2Endpoint = this.AUTH_SERVER;

    // Create <form> element to submit parameters to OAuth 2.0 endpoint.
    const form = document.createElement('form');
    form.setAttribute('method', 'GET'); // Send as a GET request.
    form.setAttribute('action', oauth2Endpoint);

    // Parameters to pass to OAuth 2.0 endpoint.
    const params = {
      client_id: this.CLIENT_ID,
      redirect_uri: this.REDIRECT_URI,
      response_type: 'token',
      scope: this.SCOPES,
      include_granted_scopes: 'true',
      state: 'pass-through value'
    };

    // Add form parameters as hidden input values.
    // eslint-disable-next-line prefer-const
    for (let p in params) {
      // eslint-disable-next-line prefer-const
      let input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p]);
      form.appendChild(input);
    }

    // Add form to page and submit it to open the OAuth 2.0 endpoint.
    document.body.appendChild(form);
    form.submit();
  }


  /** Returns observable<true> if an access-token has been found. */
  public detectAccessTokenAfterGoogleRedirectAfterUserSignedIn$(): Observable<boolean> {

    // If the user was redirected after Google-Sign-In, Google added the accesstoken to the URL in the addressbar.
    // Below we extract that access_token: it's hidden behind a hash (#) like a html anchor.
    // Note: this way of doing it I also found at a google example, so it's okay.
    // Example url with fragment (note the #hash):
    //    http://localhost:4200/#state=pass-through%20value&access_token=ya29.A0AVA9y1v06a_mZ7Ie52pkBZDvV0ZhuDPrNRqDV8Ecn4 ... etc.
    // Solution from:
    //    https://stackoverflow.com/questions/36665234/retrieve-hash-fragment-from-url-with-angular2

    const observable: Observable<boolean> = new Observable((observer) => {

      this.route.fragment
        .subscribe(frag => {
          if (frag) {
            const fragments = frag.split('&');
            // eslint-disable-next-line prefer-const
            let fragmentObject = {};
            fragments.forEach(x => {
              let y = x.split('=');
              fragmentObject[y[0]] = y[1];
            });

            // eslint-disable-next-line max-len
            // eslint-disable-next-line @typescript-eslint/dot-notation, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
            this.ACCESS_TOKEN = fragmentObject['access_token'];
            observer.next(true);
          } else {
            observer.next(false);
          }
        });
    });

    return observable;
  }

  /**
   * generateCodeVerifier generates a security code that must be passed to the various steps.
   * This is used for 'PKCE' which is an advanced security feature.
   *
   * It doesn't break servers that don't support it, but it makes servers that
   * so support it more secure.
   *
   * It's optional to pass this, but recommended.
   */
  // const codeVerifier = await generateCodeVerifier();


}
