import { IDurationFactory } from './iduration-factory';
import { IDuration } from './iduration';
import { MyDuration } from './my-duration';
import { isNumeric } from '../../shared/util';

export class DurationFactory implements IDurationFactory {



  public createDuration(hours: number, minutes: number, seconds?: number): IDuration {

    const seconds2: number = seconds ? seconds : 0;

    return new MyDuration(hours, minutes, seconds2);
  }



  /** Converts a string "x1243" or "12:43" into a duration object if it's a valid duration representation. Leaves it 00:00 otherwise.
   * It's not really forgiving: the only accepted formats are hhmm and hh:mm.
   */
  public createDurationFromString(hhmm: string): IDuration {

    const duration = this.createDuration(0, 0);

    // remove colons (independent of location within the string)
    hhmm = hhmm.replace(':', '');


    // the remaining characters must representa a numerical value. Garbage in is garbage out, so 9999 gives 00:00
    if (isNumeric(hhmm)) {
      duration.hours = +(hhmm.substring(0, 2));
      duration.minutes = +(hhmm.substring(2, 4));
    }

    return duration;
  }


}
