/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DropdownEntry } from './dropdown-entry';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() items: DropdownEntry[];
  @Input() selectedValue = '';
  @Output() onSelectionChanged: EventEmitter<string> = new EventEmitter();

  /** @description Represents the collapsed/expanded state of
   * the dropdown. Expanded means 'dropped down', collapsed
   * the reverse.
   */
  private isExpanded = false;

  constructor() {

  }

  ngOnInit() {
  }

  // #region Data-logic ------------------------------------------------------------------------------------------

  /** @description Returns the currently selected item */
  public get selectedItem(): DropdownEntry {

    if (this.items && this.selectedValue) {
      const temp = this.items.find(x => x.value === this.selectedValue.toString());
      if (temp) {
        return temp;
      }
    }

    return new DropdownEntry('-1', '');
  }

  /**
   * @description Is called from the view when an item is picked
   * from the dropdown.
   * @param item The proposed item.
   */
  public selectItem(item: DropdownEntry): void {
    this.collapse();
    this.onSelectionChanged.emit(item.value);
  }
  // #endregion

  // #region View-logic ------------------------------------------------------------------------------------------

  /**
   * @description Collapses or expands the dropdownlist from the one
   * state to the other.
   */
  public switchExpansionState(): void {
    this.isExpanded = !this.isExpanded;
  }

  public get expanded(): boolean {
    return this.isExpanded;
  }

  public expand(): void {
    this.isExpanded = true;
  }

  public collapse(): void {
    this.isExpanded = false;
  }
  // #endregion

}
