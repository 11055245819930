<h1></h1>
<p></p>

<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.question}}</p>
  <mat-form-field appearance="fill">
    <mat-label>{{data.label}}</mat-label>
    <input matInput [(ngModel)]="data.userResponseText" cdkFocusInitial>
  </mat-form-field>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="data.userResponseText" >{{data.okTitle ?? "OK"}}</button>
  <button mat-button (click)="cancel()">{{data.cancelTitle ?? "Cancel"}}</button>
</div>
