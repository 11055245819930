// eslint-disable-next-line no-shadow
export enum TaskStatus {
  ToDo = 0
  , Doing = 1
  , Postponed = 2
  , Done = 3
  , Cancelled = 4
  , Deleted = 5
  // └─ I thought to remove this state and make deletes physical, but alas logical deletion
  // has already been implemented.
  , Derived = 6
  // └─ the todo- or doing-state of a parent-task is derived from the states of its children.
  // But whether the parent is done or cancelled is always decided by the user and never
  // automatically set (BR-1003 or BR-1004).
  , Documentation = 7
  // └─ by changing state into documentation, a task moves into a hall of fame (knowledge-base).
  // There will be a separate 'documentation' section, but I think a hierarhical organization
  // of documentation is killing. It should be more fluid and tag-like.

  /** Task is blocked by external factor or party. */
  , Blocked = 8
}
