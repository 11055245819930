<div style="display: flex;">
  <textarea
      #noteeditor
      id="note-editor-{{localID}}"
      name="note-editor-{{localID}}"
      class="note-editor"
      value="{{value ? value : ''}}"
      (keyup.esc)="note_onEsc()"
      (blur)="onBlur()"
      >
  </textarea>

  <pre #renderedNote class="renderedNote">
  </pre>

</div>

<div #hyperlinks class="hyperlinks">
</div>
