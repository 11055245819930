/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IStorageMediumConfiguration } from 'src/app/data/dataService/storage-medium-configuration.interface';
import { IUserStorageMediaConfiguration } from 'src/app/view/usercontrols/main/contracts-for-plugins/user-storage-media-configuration.interface';

export class UserStorageMediaConfiguration1 implements IUserStorageMediaConfiguration {

  primaryProvider: IStorageMediumConfiguration;

  secondaryProvider: IStorageMediumConfiguration;

  otherProviders: IStorageMediumConfiguration[];

  constructor(obj: IUserStorageMediaConfiguration) {

    this.primaryProvider = obj.primaryProvider;
    this.secondaryProvider = obj.secondaryProvider;
    this.otherProviders = obj.otherProviders;
  }
}
