/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { IStorageProvider } from '../../contracts-for-plugins/istorage-provider';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageStorageProvider implements IStorageProvider {

  constructor() { }

  create(name: string, contents: string, id?: string): void {

    if (!name) {
      throw new Error('Invalid param value for _name_. Cannot save to localStorage.');
    }

    localStorage.setItem(name, JSON.stringify(contents));
  }

  read(id: string): string {

    if (!id) {
      throw new Error('Invalid param value for _id_. Cannot load from localStorage.');
    }

    const data = localStorage.getItem(id);

    return data;
  }

  update(id: string, contents: string): void {
    throw new Error('Method not available for localStorage.');
  }

  delete(id: string): void {
    throw new Error('Method not available for localStorage.');
  }
}
