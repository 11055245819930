import { ITask } from './i-task';
import { TaskStatus } from './task-status.enum';

export class TaskStatusChangedEventArg {
  // todo: identifier voor de bron van de change (welke component 'm opgooit)
  task: ITask;
  newStatus: TaskStatus;

  /** When a user marks a task as blocked, he/she can provide a reason. */
  reasonBlocked: string;
}
