/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-underscore-dangle */

// #region imports
// angular deps
import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter, Renderer2, Inject, ViewChild, ElementRef } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { fromEvent, Observable } from 'rxjs';
import { filter, distinctUntilChanged, merge } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';

// internal deps
import { PropertyChangedEventArgs } from '../../usercontrols/shared/property-changed-event-args';
import { GenericEventArgs } from '../../usercontrols/shared/generic-event-args';
import { ITaskFlatNode } from './i-task-flat-node';
import { CancelEvent } from './cancel-event';
import { DropdownEntry } from '../dropdown/dropdown-entry';
import { IFocusService } from '../../shared/contracts-for-plugins/ifocus.service';

// ccc deps
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';

// model deps
import {
  ITask,
  TaskStatusChangedEventArg,
  TaskStatus,
  TaskArrayHelper,
  ITaskFactory
} from 'src/app/model/api/api.task';
import { ISettings, ITodoViewSettings } from 'src/app/model/api/api.contracts-for-plugins';

// application deps
import {
  ITKN_ILOGSERVICE,
  ITKN_ITASKFACTORYSERVICE,
  ITKN_IFOCUSSERVICE
} from 'src/app/application/injectionTokens';
import { IEntity } from 'src/app/model/shared/ientity';
// #endregion


@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit, AfterViewInit {


  // #region Main data -------------------------------------------------------------------------------------------------

  /** @description A filtered list of only todo-related tasks. */
  public _myTasks: ITask[] = [];

  /** @description app settings. */
  // eslint-disable-next-line max-len
  public _settings: ISettings = { tag: '', soundVolume: 0, todo: { hideDoneTasks: false, expandedNodeSet: new Array<string>() } as ITodoViewSettings} as ISettings;

  /**
   * @description A ref to our hosting element. for this 'apptree'-string below to work, the
   * target element in the view must be marked as #apptree.
   */
  @ViewChild('apptree', {}) inputElement: ElementRef;
  // https://indepth.dev/exploring-angular-dom-manipulation-techniques-using-viewcontainerref/


  // #endregion


  // #region In- and Outputs  -------------------------------------------------------------------------------------------------------------

  /** The title above the tree */
  @Input() title: string;

  /** show or hide the label above the tree, the that shows number of items in the tree */
  @Input() showTotalSize: boolean;

  /** the query or filter that selects the tasks that must be shown in the tree. See filterAndSort. */
  // @Input() filter: ((taskList: ITask[]) => ITask[]);
  @Input() filter: (t: ITask) => boolean;

  /** an optional checkbox to (de-) activate a subfilter, e.g. hide done tasks in the todo-tree. */
  @Input() showSubFilterCheckbox: boolean;

  // eslint-disable-next-line max-len
  /** whether to show the documentation-button (for converting a task into documentation). E.g. show it when it's a todo-tree, but not for the documentation-tree itself. */
  @Input() showDocumentationButton: boolean;

  /** whether to show the doNow-button. E.g. in a documentation-tree this has no application. */
  @Input() showDoNowButton: boolean;

  /** whether to show the status-button. E.g. in a documentation-tree this has no application. */
  @Input() showStatusButton: boolean;


  /** the default contents that appears when you add a new subtask (e.g. 'New task', or 'New documentation') */
  @Input() defaultNewTaskContents: string;

  /** the default status a new task gets, e.g. when you add a new subtask (e.g. todo, doing, or documentation) */
  @Input() defaultNewTaskStatus: TaskStatus;

  /**
   * @description Object the parent component needs in order to identify what entity a changed value belongs to.
   * Eslint complains about the 'any' type so I created the IEntity interface.
   * @see note-editor with the same (original) construction.
   */
  @Input() public reference: IEntity;

  /** @description a set of tasks passed in by the parent (can be not only
   * to-do's but all tasks of all states).
   */
  @Input() tasks: Observable<ITask[]> = new Observable();

  /** @description the settingsservice. */
  @Input() settings: Observable<ISettings> = new Observable();

  @Output() onNewTask = new EventEmitter<TaskStatusChangedEventArg>();

  @Output() onTaskStatusChanged = new EventEmitter<TaskStatusChangedEventArg>();

  @Output() onTaskPropertyChanged = new EventEmitter<PropertyChangedEventArgs>();

  @Output() onSettingChanged = new EventEmitter<PropertyChangedEventArgs>();


  private _activatedTask: ITask;

  /** if activatedTask is set from outside, we should mark the task visually (GUI) in the list */
  @Input() set activatedTask(value: ITask) {  // by making a getter/setter we can attach logic to changes to the input.
    this._activatedTask = value;
    this.makeTaskVisible(value);
  };

  get activatedTask(): ITask {
    return this._activatedTask;
  }
  // #endregion


  // #region Settings

  /** @description If true, done/cancelled tasks are not shown in the tree-view. */
  // hideDoneTasks = false;

  /**
   * @description SPECIFIC FOR TODO! // todo1234
   * Event: When a user checks or unchecks the 'hide done tasks' checkbox in order to show or
   * hide the tasks in the todo-tree that alreade are 'done' or 'cancelled'.
   */
  onHideDoneChanged = (): void => {
    const args2 =
      new PropertyChangedEventArgs(null, 'hideDoneTasks', this._settings.todo.hideDoneTasks, !this._settings.todo.hideDoneTasks);
    this.onSettingChanged.emit(args2);
  };
  // #endregion


  // #region Init and main functions -------------------------------------------------------------------------------------------------

  /** @description Counter in UI. Total number of tasks that are in todo-state. */
  public totalSize: number;

  /** @description Inject some items into this app and initialize it.
   */
  constructor(
    public renderer: Renderer2
    , @Inject(ITKN_ILOGSERVICE) public logger: ILogService
    , @Inject(ITKN_ITASKFACTORYSERVICE) public taskfactory: ITaskFactory
    , @Inject(ITKN_IFOCUSSERVICE) public focusservice: IFocusService) {

    // param for mapTreeFlatDatasource
    this.treeControl = new FlatTreeControl<ITaskFlatNode>(
      node => node.level, node => node.expandable);

    // second param for mapTreeFlatDatasource
    this.treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, node => node.children);

    // setup the datasource for the 'flat tree'.
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  ngOnInit(): void {

    // subscribe to the data- and settingsservices.

    this.settings.subscribe(x => {
      this._settings = x;

      // on some setting-changes the data must be refiltered.
      // if (this.settings.todo.hideDoneTasks !== this.hideDoneTasks) {
      // this.hideDoneTasks = !this.hideDoneTasks;
      this.filterAndSort(this._myTasks);
      // }
    });

    this.tasks.subscribe(x => {
      this._myTasks = x;
      this.filterAndSort(x);
    });
  }

  /**
   * @description Keeps track of whether the shift key is being pressed.
   * Used for detecting Shift-click user actions.
   */
  private isHoldingShift: boolean;

  ngAfterViewInit(): void {

    // #region  Wire up detection of the Shift keypress
    //    https://stackoverflow.com/questions/31206734/how-to-filter-keydowns-with-rxjs#44186764 (Waheed)
    const keyDowns = fromEvent(document, 'keydown');
    const keyUps = fromEvent(document, 'keyup');

    const keyPresses = keyDowns.pipe(
      merge(keyUps),                                    // tslint:disable-line:deprecation  -- 'merge' is deprecated, external code
      filter((e: KeyboardEvent) => e.keyCode === 16),   // tslint:disable-line:deprecation  -- 'keyCode' is deprecated, external code
      distinctUntilChanged((x, y) => x.type === y.type)
    );

    keyPresses.subscribe((shiftpress) => {
      this.isHoldingShift = shiftpress.type === 'keydown';
    });
    // #endregion
  }

  /** @description Filters and sorts the dataset for the current
   * view: the todo-list.
   */
  private filterAndSort(data: ITask[]): void {

    if (!data) {
      console.log('Tree: no data, no fitering.');
      return;
    }

    // For ALL DATA, we compute the list of children. Allthough that's some overkill, (for
    //    all tasks that are not in the todo-tree it's useless), we do it this way because
    //    we need the child-lists for constructing the tree. It is easier to do it this
    //    way than first to create the tree and then deduce the children.
    if (this.showSubFilterCheckbox && this._settings.todo.hideDoneTasks) {
      data.forEach(task =>
        task.children = data.filter(x => x.parentId === task.id && this.isTodoOrRunning(x))
      );
    } else {
      data.forEach(task =>
        task.children = data.filter(x => x.parentId === task.id)   // we assign pointers, no ID's.
      );
    }

    // First, filter the incoming data to a list of only 'root todo' items,
    //  so only todo's that are at the root of the tree. Children are
    //  selected in a later step.
    const rootList = data.filter(this.filter);    // note: the items in the list point to the same objects that are in data!

    // now add all children to the list (for children any state is
    //  allowed, so not only todo's unless stated otherwise).
    // let completeTodoTaskArray: ITask[];
    // let arrayOfTaskArrays = rootList.map(rootTask => this.data.getChildrenRecursivelyDepthFirst(rootTask));
    // if (arrayOfTaskArrays && arrayOfTaskArrays.length > 0) {
    //   // without this check tests throw a 'Reduce of empty array with no initial value thrown':
    //   //    in short: [].reduce((x,y)=> x.concat(y)) gives error; [[]].reduce((x,y)=> x.concat(y)) doesn't.
    //   completeTodoTaskArray = rootList.concat(arrayOfTaskArrays.reduce((x, y) => x.concat(y)));
    //   completeTodoTaskArray = completeTodoTaskArray.filter(x => x.status !== TaskStatus.Cancelled && x.status !== TaskStatus.Done);
    // }

    // no sorting: the user sorts manually.

    // compute levels for the tree-control
    data.forEach(task => {
      task.level = TaskArrayHelper.getParentsRecursively(task, data).length;
    });

    // remember states (e.g. tree expansion) before rebuilding the tree
    this.saveGUIStateToLocalVariable(); // this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);

    // bind only tasks at level 0 to the tree's datasource.
    this.dataSource.data = rootList; // completeTodoTaskArray.filter(x => !x.parentId);

    // restore expansionstate
    // eslint-disable-next-line max-len
    this.restoreGUIStateFromLocalVariable(); // this.restoreTreeExpansionState(this.treeControl.dataNodes, Array.from(this.expandedNodeSet));
    // this.expandedNodeSet.clear();

    // show size in the UI
    this.totalSize = data.filter(x => this.isTodoOrRunning(x)).length;
  }
  // #endregion


  // #region Process events from childcomponents ------------------------------------------------------------------------------------

  /** @description Processes a changerequest from a TextInputComponent. */
  public receiveContentChange = (args: GenericEventArgs): void => {

    // We assign the task to the reference-input of the component, so args.reference
    //    refers to that task. The textinputcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the content-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'content', args.reference['content'], args.value);  // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);
  };

  /** @description Processes a changerequest from a TextInputComponent. */
  public receiveNoteChange = (args: GenericEventArgs): void => {

    // We assigned the task to the reference-input of the component, so args.reference
    //    refers to that task. The noteeditorcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the note-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'note', args.reference['note'], args.value);  // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);
  };

  /** @description Processes a height-changerequest from the note-editor. */
  public receiveNoteHeightChange = (args: GenericEventArgs): void => {

    // we do not broadcast this change because data-consistency is not im frage.
    (args.reference as ITask).noteHeight = Number(args.value);
  };

  public receivePropertyChanged = (args: PropertyChangedEventArgs): void => {
    this.onTaskPropertyChanged.emit(args);
  };

  public receiveRequestToConvertTaskIntoDocumentation = (args: TaskStatusChangedEventArg): void => {
    this.onTaskStatusChanged.emit(args);
  };
  // #endregion


  // #region Insertion field  -------------------------------------------------------------------------------------------------------------

  /**
   * @description event from the view when user presses Enter in
   * the 'doing-inputbox'
   */
  public taskInsertionField_onEnter(elem): void {
    if (elem.value) {                       // negeer lege input
      const task: ITask = this.createNewTask(elem.value);
      this.emitNewTask(task);
      elem.value = '';                      // invoerveld leegmaken
    }
  }

  /** Create a new task and do some initializations typical for todo's.
   *
   * @param content the text
   */
  private createNewTask(content: string): ITask {
    const task: ITask = this.taskfactory.createTaskFromContent(content);
    task.status = Number(this.defaultNewTaskStatus) as TaskStatus ;
    return task;
  }

  /** @description Emits a task to the parent
   */
  private emitNewTask(task: ITask): void {
    const args = { task } as TaskStatusChangedEventArg;
    this.onNewTask.emit(args);            // opgooien naar parentcomponent
  }

  public taskInsertionField_setFocus(): void {
    this.focusservice.setFocus('.todo #new-task', this.renderer);   // kan .todo hier weg? // todo1234
  }
  // #endregion


  // #region Button clicks etc -----------------------------------------------------------


  /** @description Delete a task (i.e. send delete-request to parent component) */
  private delete(task: ITask) {
    const args = { task, newStatus: TaskStatus.Deleted } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
  }


  /** @description Cancels the given task.
   */
  public cancel(event: CancelEvent): void {

    // if a user holds the Shift-key while clicking 'done' the task is deleted.
    if (event.mouseevent.shiftKey) {
      this.delete(event.task);
    } else {
      if (event.task.status === TaskStatus.Documentation) {
        return; // cancel does nothing, in documentation, only when shift is pressed too.
      }
      const args = { task: event.task, newStatus: TaskStatus.Cancelled } as TaskStatusChangedEventArg;
      this.onTaskStatusChanged.emit(args);
    }

    this.hideNotes(); // todo, ok, if it's a documentation task without shift, notes are hidden and that is a bug.
  }

  /** @description Creates a child-task for the task that is 'clicked' in the treeview. */
  public subtask(task: ITask): void {

    const parentTask: ITask = task;
    const childTask: ITask = this.createNewTask(this.defaultNewTaskContents);
    childTask.parentId = parentTask.id;
    this.emitNewTask(childTask);


    // ontwerp-fout! Hierboven wordt task aangemaakt, hieronder wordt'ie gebruikt terwijl het aanmaken    // todo1234
    //    mogelijk nog niet klaar is. Kan dit niet asynchroon gemaakt worden?

    // if the task has been successfully added to the datasource, expand
    //    the parent, so the newly created task is shown to the user.
    if (this._myTasks.find(x => x.id === childTask.id)) {

      this.expandNode(this.treeControl, parentTask);
      // below params naar setfocus: true, true == keep contents, select all text
      this.focusservice.setFocus('#text-input-contents-' + String(childTask.id), this.renderer, true, true);
    }
  }

  /** @description Do a task: the task changes into Doing-status. Also
   * handles the reopening of cancelled tasks: in that case the task
   * changes from cancelled- to todo-status. Also handles 'mark as done'
   * when it's a parent task.
   * todo1234 very todo-specific! How to fix this? Not urgent because invisible in docutree.
   */
  public do(task: ITask): void {

    let args: TaskStatusChangedEventArg;

    if (task.status === TaskStatus.Cancelled) {
      // when a task in the todo-tree has status 'Cancelled' then the 'do now'
      //    button functions as a 'un-cancel' button (i.e. reopen). So now we
      //    request to change the status to 'ToDo'.
      args = { task, newStatus: TaskStatus.ToDo } as TaskStatusChangedEventArg;
      this.onTaskStatusChanged.emit(args);
    }
    else {

      // if it's a parent, the button has title 'mark as done' and that's what we do.
      if (task.children && task.children.length > 0) {
        // first mark all children as done
        task.children.forEach(x => {
          if (x.status < 3) { // doing, todo or postponed, then to done.
            args = { task: x, newStatus: TaskStatus.Done } as TaskStatusChangedEventArg;
            this.onTaskStatusChanged.emit(args);
          }
        });
        // then mark the parent itself as done.
        args = { task, newStatus: TaskStatus.Done } as TaskStatusChangedEventArg;
        this.onTaskStatusChanged.emit(args);
      }
      else {  // if it's NOT a parent then just set into doing.
        args = { task, newStatus: TaskStatus.Doing } as TaskStatusChangedEventArg;
        this.onTaskStatusChanged.emit(args);
      }
    }

    this.hideNotes();
  }


  // #endregion


  // #region App options (nog niet in gebruik)

  // dit is een dll in de top-bar van de todo-component.
  //    De ddl wordt momenteel niet getoond. (5/5/2020)

  selectedApp = '-1';

  appOptionsList: DropdownEntry[] = [
    { value: '-1', label: 'application' }
    , { value: '1', label: 'help' }
    , { value: '2', label: 'releasenotes' }
    , { value: '3', label: 'settings' }
  ];

  onAppOptionChanged = (value: string): any => {
    this.logger.log('ddl selected: ' + value);
    this.selectedApp = value;
  }

  // #endregion


  // #region Save GUI state -----------------------------------------------------------

  private saveGUIStateToLocalVariable() {

    this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
  }

  private restoreGUIStateFromLocalVariable() {

    this.restoreTreeExpansionState(this.treeControl.dataNodes, this.expandedNodeSet);
  }

  /**
   * Save the expansionstate to the settings, so after file backup it can be restored.
   */
  public saveExpansionStateToSettings = (targetSettingParent: unknown): void => {
    this.rememberExpandedTreeNodes(this.treeControl, this.expandedNodeSet);
    const args =
      new PropertyChangedEventArgs(targetSettingParent, 'expandedNodeSet', undefined, this.expandedNodeSet);
    this.onSettingChanged.emit(args);
  };

  /**
   * Restore the saved expansionstate from settings, that may come from a file.
   */
  public restoreExpansionStateFromSettings = (targetSettingParent: unknown): void => {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.expandedNodeSet = targetSettingParent['expandedNodeSet'];
    this.restoreGUIStateFromLocalVariable();
  };

  // #endregion


  // #region Drag & drop -----------------------------------------------------------

  /**
   * @description Contains the id's (taskID's) of nodes in the tree
   * that are in the expanded state.
   */
  expandedNodeSet = new Array<string>();

  /** @description Returns true if the treenode is expandable. Used in the view. */
  hasChild = (_: number, nodeData: ITaskFlatNode) => nodeData.expandable;

  // eslint-disable-next-line max-len
  /** @description Returns true if the treenode has a task that is running or has not yet been started. */ // todo1234 very todo specific, but harmless?
  isTodoOrRunning = (task: ITask): boolean => task &&
    (task.status === TaskStatus.ToDo || task.status === TaskStatus.Doing || task.status === TaskStatus.Postponed);

  /**
   * @description Remembers the expanded state of the tree.
   * Note: it's the task-ID's that are remembered.
   * From: https://stackblitz.com/edit/mat-tree-with-drag-and-drop
   */
  private rememberExpandedTreeNodes(
    treeControl: FlatTreeControl<ITaskFlatNode>,
    expandedNodeSet: Array<string>
  ) {

    expandedNodeSet.length = 0; // this way we keep the objectreference intact, while removing all from the array.
    if (treeControl.dataNodes) {
      treeControl.dataNodes.forEach((node) => {
        if (treeControl.isExpandable(node) && treeControl.isExpanded(node)) {
          // capture latest expanded state
          expandedNodeSet.push(node.task.id);
        }
      });
    }
  }

  /**
   * @description Restores the expansion of the tree according to the
   * given list of expanded nodes (param ids) and ...
   * From: https://stackblitz.com/edit/mat-tree-with-drag-and-drop
   */
  private restoreTreeExpansionState(flatNodes: ITaskFlatNode[], ids: string[]) {

    if (!flatNodes || flatNodes.length === 0) {
      return;
    }
    const idSet = new Set(ids);
    return flatNodes.forEach((node) => {
      if (idSet.has(node.id)) {
        this.treeControl.expand(node);
        // Keep code below: it can come in handy, maybe as an option.
        //    If a childnode is in the expanded state, all its parents
        //    are expanded.
        // let parent = this.getParentNode(node);
        // while (parent) {
        //   this.treeControl.expand(parent);
        //   parent = this.getParentNode(parent);
        // }
      }
    });
  }

  /**
   * @description (Copied code) Returns the parent-treenode of the given treenode.
   * From: https://stackblitz.com/edit/mat-tree-with-drag-and-drop
   */
  private getParentNode(node: ITaskFlatNode): ITaskFlatNode | null {
    const currentLevel = node.level;
    if (currentLevel < 1) {
      return null;
    }
    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;
    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (currentNode.level < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * @description Constructs an array of nodes that matches the DOM,
   * and calls rememberExpandedTreeNodes to persist expand state.
   * From: https://stackblitz.com/edit/mat-tree-with-drag-and-drop
   */
  getVisibleNodes(): ITask[] {
    this.saveGUIStateToLocalVariable();
    const result = [];

    // visibility is determined in two steps: (1) what's in dataSource.data, and (2)
    //    by the expansionstate of all nodes from step 1, recursively. (SK)
    // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    function addNodeAndSubtreeVisibilityRecursively(node: ITask, expanded: Array<string>) {
      result.push(node);
      if (expanded.indexOf(node.id) > -1) {  // if node is expanded (then also its children are visible) (SK)
        node.children.map(child => addNodeAndSubtreeVisibilityRecursively(child, expanded));
      }
    }
    this.dataSource.data.forEach(node => {  // dataSource.data contains only the visible nodes
      addNodeAndSubtreeVisibilityRecursively(node, this.expandedNodeSet);
    });
    return result;
  }


  /**
   * @description OnDrop: currently only fires when the order of tasks is changed
   * by the user (not yet for dropping tasks from other stacks).
   * For drag 'n drop, zie https://material.angular.io/cdk/drag-drop/overview
   */
  tree_onDrop(args: CdkDragDrop<ITask[]>) {

    // #region What you must know
    //
    //  From: https://medium.com/briebug-blog/angular-implementing-drag-and-drop-in-a-material-tree-f96b9fe40f81
    //  The drop $event gives us previous index, current index, some list data and
    //  also our node data since we included [cdkDragData]="node" on the tree node markup.
    //  If we could just use the indexes to splice the node out of the array and into its
    //  new position it would be easy. But it’s not that simple, because the CDK is giving
    //  us indexes which relate to the DOM, which is a flat list, omitting children of
    //  collapsed nodes, not our actual data source of nested arrays. The tree needs to
    //  consume a data source; we can’t give it the flattened DOM list. So we need to
    //  mutate the data source from the drop event data which pertains to the flat list.
    // #endregion

    // Construct a list of visible nodes, this will match the DOM.
    // the cdkDragDrop event.currentIndex jives with visible nodes.
    // it calls rememberExpandedTreeNodes to persist expand state.
    const visibleNodes = this.getVisibleNodes();

    // determine where to insert the node (it returns a task)
    const taskAtDest = visibleNodes[args.currentIndex];

    // get the task that is being moved
    const movingTask = args.item.data.task;

    // determine indices in data (the entire app-data! includes doing's and done's)
    const movingTaskIndex = this._myTasks.findIndex(x => x.id === movingTask.id);
    const taskAtDestIndex = this._myTasks.findIndex(x => x.id === taskAtDest.id);

    // fill changerequest and emit it to the parent component
    const changeRequestArgs = {
      entity: movingTask
      , propertyname: 'order'
      , oldValue: movingTaskIndex
      , newValue: taskAtDestIndex
    } as PropertyChangedEventArgs;

    this.onTaskPropertyChanged.emit(changeRequestArgs);
  }
  // #endregion


  // #region Notes ----------------------------------------------------------------------------------------------------------------------

  /** @description Bookkeeping: contains the id of the task for
   * which a note is currently visible. Only one note at a time
   * is visible (or none).
   */
  public shownSingleNoteId: UUID = null;

  /**
   * @description Bookkeeping: contains the ids of the task for
   * which a note is currently visible. Multiple notes at a time
   * can be visible.
   */
  shownNoteIds: UUID[] = [];

  /** @description Hide all notes
   */
  public hideNotes(): void {
    this.shownSingleNoteId = null;
    this.shownNoteIds = [];
  }

  /** @description Hide note on pressing escape in the note-editor
   */
  public note_onEsc(): void {
    this.hideNotes();
  }

  /** @description Shows or hides the note for the given task
   */
  public showNote(task: ITask): void {

    // This code is a little bit complicated because these are the specs:
    //  When a user clicks a notebutton, the note is opened or closed depending
    //    on its current status. Only a single note can be open at a time.
    //  If the user holds the shift-key, multiple notes can be opened next to
    //    each other. Closing one of them keeps the others open.
    //  Only when opening a note without holding the shift key, the app jumps
    //    back into single-note modues, so all opened notes are closed and
    //    the clicked one is opened.

    if (!task) {
      return;
    }

    const mustShow = !this.isVisibleNote(task); // hide it when it's shown, and reverse.

    if (!this.isHoldingShift) {

      if (mustShow) {
        this.hideNotes(); // only one note is shown at a time, so we can hide 'm all safely.
        this.shownSingleNoteId = task.id;
        this.focusservice.setFocus('.todo #note-editor-' + String(task.id), this.renderer);
      } else {
        // must hide
        // if multiple notes are shown, we keep them open, even though the user didn't press SHIFT.
        if (this.shownNoteIds.length > 0) {
          this.removeNoteFromShownNotes(task);
        } else {
          this.shownSingleNoteId = null;
        }
      }
    } else {
      // SHIFT KEY is pressed.
      if (mustShow) {
        this.shownNoteIds.push(task.id);
        this.focusservice.setFocus('.todo #note-editor-' + String(task.id), this.renderer);   // todo: moet beter kunnen.
      } else {
        // must hide
        this.removeNoteFromShownNotes(task);
      }
    }

    this.filterAndSort(this._myTasks);
  }

  private removeNoteFromShownNotes(task: ITask) {
    this.shownNoteIds.splice(this.shownNoteIds.indexOf(task.id), 1);  // remove from array
  }

  /** @description Returns whether the note of the given task is
   * visible or not.
   */
  public isVisibleNote(item: ITask): boolean {
    return item && (this.shownSingleNoteId === item.id || this.shownNoteIds.some(x => x === item.id));
  }
  // #endregion


  // #region Tree data and functions

  // see: https://material.angular.io/components/tree/overview

  // onderstaande 3 methods worden 'gezet' in de constructor.
  treeControl: FlatTreeControl<ITaskFlatNode>;
  treeFlattener: MatTreeFlattener<ITask, ITaskFlatNode>;
  dataSource: MatTreeFlatDataSource<ITask, ITaskFlatNode>;


  /**
   * @description transforms an ITask into an ITaskFlatNode: a node that fits into
   * the datastructure needed for a tree-representation.
   */
  private transformer = (task: ITask, level: number): ITaskFlatNode => {
    const K = {
      expandable: !!task.children && task.children.length > 0,
      content: task.content,
      level,
      task,
      id: task.id
    };
    return K;
  };

  /**
   * @description Returns the first task that needs to be done.   // todo1234 very todo-specific
   * This is used when the user clicks 'do now' on a parent.
   */
  getChildTaskThatMustBeDoneFirst(task: ITask): ITask {

    // eslint-disable-next-line max-len
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    return TaskArrayHelper.getOrderOfWork(task, this._myTasks).find(x => !x.status);
    // om 1 of andere reden kan ik hier niet TaskStatus.Todo gebruiken.
  }

  /** @description Expands the node (visually) that the given task is attached to. */
  expandNode = (treeControl: FlatTreeControl<ITaskFlatNode>, task: ITask): void => {
    if (treeControl.dataNodes) {
      const node: ITaskFlatNode = treeControl.dataNodes.find(x => x.task.id === task.id);
      treeControl.expand(node);
    }
  };

  /** @description Collapses the node (visually) that the given task is attached to. */
  collapseNode = (treeControl: FlatTreeControl<ITaskFlatNode>, task: ITask): void => {
    if (treeControl.dataNodes) {
      const node: ITaskFlatNode = treeControl.dataNodes.find(x => x.task.id === task.id);
      treeControl.collapse(node);
    }
  };

  /** Makes a task (its corresponding node) visible in the tree */
  makeTaskVisible(task: ITask): void {

    if (!task) {
      return;
    }

    // find all the tasks' ancestors
    const ancestors: Array<ITask> = [];

    let t = task;
    let parent: ITask = null;

    while (t.parentId) {
      // find the parent in the data (not in the tree, because it may not be visible)
      parent = this._myTasks.find(x => x.id === t.parentId);

      if (parent) {
        ancestors.push(parent);
      }
      t = parent;
    }

    ancestors.forEach(p => this.expandNode(this.treeControl, p));
  }

  private _allExpanded = false;

  public toggleExpandEntireTree(): void {
    if (!this._allExpanded) {
      this.saveGUIStateToLocalVariable();
      this.treeControl.expandAll();
      this._allExpanded = true;
    } else {
      this.collapseEntireTree();
      this.restoreGUIStateFromLocalVariable();
      this._allExpanded = false;
    }
  }

  public collapseEntireTree(): void {
    this.treeControl.collapseAll();
  }

  // #endregion


  // #region Specials for GUI ----------------------------------------------------------------------------------------------------

  // todo1234 identify buttons ('cancelbutton') by function or by id (btn1, btn2 etc.)

  // todo1234 cancelbtn tekst zetten op basis van childnodes is very todo-specific.
  // and how to dynamically assign a name and function to it?
  getCancelButtonText(task: ITask): string {

    // texts: cancel / cancelled
    let result = 'Cancel';

    // Parentnodes can overrule the cancelled-state (only the buttontext, by
    //    the way) of their children. If any parent has state Cancelled, then
    //    this node gets text 'Cancelled' too.
    if (task.status === TaskStatus.Cancelled ||
      TaskArrayHelper.getParentsRecursively(task, this._myTasks).some(x => x.status === TaskStatus.Cancelled)) {
      result = 'Cancelled';
    }

    return result;
  }

  mustDisableCancelButton(task: ITask): boolean {
    return this.getCancelButtonText(task) !== 'Cancel';
    // todo1234 ─┴─ dit type logica waarbij gui-tekst iets bepaalt willen we natuurlijk niet.
  }

  mustShowCancelButton(task: ITask): boolean {
    let result = false;
    switch (task.status) {
    case undefined:
    case null:
    case TaskStatus.ToDo:
    case TaskStatus.Cancelled:
    case TaskStatus.Documentation:
      result = true;
    }

    // if any of its parents is cancelled, show it anyway
    if (!result) {
      result = TaskArrayHelper.getParentsRecursively(task, this._myTasks).some(x => x.status === TaskStatus.Cancelled);
    }

    return result;
  }

  getDoNowButtonText(task: ITask): string {

    // texts: do now / in progress / done / reopen
    let result = 'Do now';

    if (task.children && task.children.length === 0) {    // todo I added 'if task.children' maar nu klopt de else niet meer
      // leaf node
      switch (task.status) {
      case TaskStatus.Doing:
      case TaskStatus.Postponed:
        result = 'In progress';
        break;
      case TaskStatus.Cancelled:
        result = 'Reopen';
        break;
      case TaskStatus.Done:
        result = 'Done';
      }
    } else {
      // parent node
      switch (task.status) {
      case TaskStatus.Doing:
      case TaskStatus.Postponed:
        result = 'In progress';
        break;
      case TaskStatus.Done:
        result = 'Done';
        break;
      case TaskStatus.Cancelled:
        result = 'Reopen';
        break;
      case undefined:
      case null:
      case TaskStatus.ToDo:
        // When a parent is in 'initial' state, childnodes can overrule the
        //    state (only the buttontext, by the way) of their parents.
        // If any child has state > 0 (including cancelled) then this parent is 'In progress'.
        const children = TaskArrayHelper.getChildrenRecursively(task, this._myTasks);
        if (children.some(x => x.status > 0)) {
          result = 'In progress';
        }
        if (!children.some(x => !x.status)) {   // If all children are in progress, then the parent is available for execution also.
          result = 'Do now';                    // Note: !x.status returns false when status === todo (i.e. 0), that's the trick here.
        }
      }
    }
    return result;
  }

  mustDisableDoNowButton(task: ITask): boolean {
    return task.status === TaskStatus.Done;
  }

  mustShowDoNowButton(task: ITask): boolean {
    return !TaskArrayHelper.getParentsRecursively(task, this._myTasks).some(x => x.status === TaskStatus.Cancelled);
  }

  /** BR1001: disable subtasking of done/cancelled tasks. */
  mustDisableSubtaskButton(task: ITask): boolean {
    return task.status === TaskStatus.Done || task.status === TaskStatus.Cancelled ||
      TaskArrayHelper.getParentsRecursively(task, this._myTasks)
        .some(x => x.status === TaskStatus.Cancelled || x.status === TaskStatus.Done);
  }

  // #endregion
}
