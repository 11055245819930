// todo: nog betere naam verzinnen want cancelevent klinkt als het cancellen van een event

import { ITask } from 'src/app/model/api/api.task';

/** @description Represents a Node in the tree of tasks. Needs fields for the tree, but also all fields that the view uses. */
export class CancelEvent {
  mouseevent: MouseEvent;
  task: ITask;

  constructor(mouseevent: MouseEvent, task: ITask) {
    this.mouseevent = mouseevent;
    this.task = task;
  }
}
