// Maak classes uit model beschikbaar voor plugins (zoals storageservice)
//
//    Dit is een oplossing om te voorkomen dat de storageservice rechtstreekse dependencies op het model heeft; want we willen
//    dat de dataservice daar altijd tussen zit.
//    Ik vraag me alleen af of dit de manier is, maar de volgende overweging is enigszins overtuigend:
//        Punt (1): door deze truc is de storageservice niet meer dependent on the model maar alleen on de
//        dataservice; dat de dataservice classes beschikbaar stelt uit dubieuze bron is geen issue
//        voor de storageservice. De enige 'abnormale' consequentie van deze constructie is dat als
//        in het model iets wijzigt dit direct ook consequenties heeft voor indirecte dependencies,
//        maar dat is volgens mij geen principieel probleem.
//        Punt (2): als, want dat is waarop ik voorsorteer met het uiteenrafelen van de dependecies,
//        de datalayer (dataservice, storageservice, demodataservice) naar een remote server wordt
//        verplaatst dan blijft de storageservice gewoon compileren maar loopt de dataservice vast (in
//        werkelijkheid compileert de storageservice dan ook niet, maar goed). Het probleem moet dan
//        worden opgelost in de ds (en daarmee is het automatisch opgelost in de backupsvc) en dat is
//        precies wat we op het oog hebben.
//    SK 11 nov 2020

export { ITask, TaskStatus, ITaskFactory, ITimedTask } from 'src/app/model/api/api.task';
export { IImplements } from 'src/app/model/shared/iimplements';
export { ISettings, IDownloadAnchor } from 'src/app/model/api/api.contracts-for-plugins';
