import { ITimedTask } from '../task/i-timed-task';

/**
 * @description To check whether an object implements some interface.
 * Experimental! static methods this way are not preferrable.
 */
export class IImplements {

  /** Check whether object implements the ITimedTask interface.
   * Experimental.
   * Example use:
   * if (isTimedTask(obj)) {
   * (obj as ITimedTask).x;
   * }
   */
  static ITimedTask(arg: any): arg is ITimedTask {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return !!arg.timingPatternInfo;
  }

}
