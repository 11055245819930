import { ITimeFactory } from './itime-factory';
import { ITime } from './itime';
import { MyTime } from './my-time';
import { isNumeric } from '../../shared/util';

export class TimeFactory implements ITimeFactory {


  createTime(hours: number, minutes: number, seconds?: number): ITime {

    const seconds2 = seconds? seconds : 0;

    return new MyTime(hours, minutes, seconds2);
  }


  /** Converts a string "1243" or "12:43" into a time object if it's a valid time representation. Leaves it 00:00 otherwise.
   * It's not really forgiving: the only accepted formats are hhmm and hh:mm.
   */
  public createTimeFromString(hhmm: string): ITime {

    // remove colons (independent of location within the string)
    hhmm = hhmm.replace(':', '');

    const time: ITime = new MyTime(0, 0, 0) as ITime;

    // the remaining characters must represent a numerical value. Garbage in is garbage out, so 9999 gives 00:00
    if (isNumeric(hhmm)) {
      time.hours = +(hhmm.substring(0, 2));
      time.minutes = +(hhmm.substring(2, 4));
    }

    return time;
  }


}
