<div class="top-bar rij" >

  <div class="top-bar-title" #altimeter1>
    <h1 color="primary" i18n="appTitle|Application title.@@1" #altimeter4>What I'm doing</h1> <small *ngIf="saveSpace===false" color="accent">a free, anonymous productivity webtool</small>
  </div>

  <div class="" #altimeter2>


    <!-- tag -->
    <div id="mindstack-tag">
      <label for="taginput" id="taginput-label">tag: &nbsp;</label>
      <input
          class="mat-app-background"
          type="text"
          id="taginput"
          [value]="tag"
          (change)="receiveTagChangeRequest($event)"
          (blur)="syncModelToView()"
          matTooltip="Add a unique tag to the name of your downloaded file."
          matTooltipClass="top-bar-tooltip"
      />
    </div>


    <!-- volume -->
    <mat-slider
        min="1"
        max="100"
        step="1"
        id="top-bar-volume-slider"
        [value]="soundVolume"
        (change)="receiveSoundVolumeChangeRequest($event)"
        (blur)="syncModelToView()"
        matTooltip="Sound volume slider. To the right is volume up."
        matTooltipClass="top-bar-tooltip"

    ></mat-slider>

  </div>

  <div class="top-bar-controlpanel" #altimeter3>


    <!-- complex piece for 'open': when a user wants to open a file, first they must choose a storagemedium (from which to get the file.)
      Depending on the chosen medium either an explorer window is shown (local disc) or a filelist is shown (google drive) that the user can select from.
      Once a file has been opened, the storage-setting for SAVING can be read from the file itself.
    -->
    <span
      (mouseenter)="showStorageMediaDropdownList=true"
      (mouseleave)="showStorageMediaDropdownList=false"
      >


      <!-- open (load file) -->
      <label
        class="top-bar-button"
        style="position: relative; top: 6px; left: 8px;"
        id="open-file"
        matTooltip="Open a whatiamdoing-file."
        matTooltipClass="top-bar-tooltip"
        matTooltipPosition="right"
        (click)="open_onClick()"
        >
        <span *ngIf="mustDownloadToDisc"> <!-- this is the downloadanchor in case the user wants to download to disc -->
          <app-load-file-from-disc
            class="app-load-file-from-disc"
            (onLoadFileFromDisc)="receiveFileOpenRequest($event)"
          ></app-load-file-from-disc>
        </span>
        <span class="material-symbols-outlined">folder_open</span>

      <!-- the list of storage-media the user must choose from -->
      <app-dropdown
        *ngIf="showStorageMediaDropdownList"
        [items]="storageMediaDropdownList"
        [selectedValue]="selectedStorageMediumItem?.value"
        (onSelectionChanged)="storageMediumChanged($event)"
        class="storage-media-dropdown"
      ></app-dropdown>

      <!-- the list of googledrive files the user must choose from -->
      <app-dropdown
        *ngIf="showFileList"
        (mouseenter)="showFileList=true"
        (mouseleave)="showFileList=false"
        [items]="fileList"
        [selectedValue]="selectedFileItem?.value"
        (onSelectionChanged)="fileChanged($event)"
        class="storage-media-dropdown"
      ></app-dropdown>
    </label>
    </span>

    <!-- save -->
    <button
      mat-icon-button
      class="top-bar-button"
      (click)="save_onClick()"
      matTooltip="Save"
      matTooltipClass="top-bar-tooltip"
      (mouseenter)="notifyUserIsAboutToSave()"
      >
      <span class="material-symbols-outlined">save</span>
    </button>



    <!-- restore last session -->
    <button
      mat-icon-button
      class="top-bar-button"
      (click)="restore_onClick()"
      matTooltip="Restore the running backup from the browser's localStorage."
      matTooltipClass="top-bar-tooltip"
      >
      <span class="top-bar-button-text"
        i18n="restoreBackup|Restore data from localStorage into the application.@@2"></span>
        <span class="material-symbols-outlined">device_reset</span>
    </button>


    <!-- toggle dark theme -->
    <button
      mat-icon-button
      class="top-bar-button"
      (click)="toggleDarkTheme()"
      matTooltip="Toggle dark theme"
      matTooltipClass="top-bar-tooltip"
      aria-label="Toggle Dark Theme"
    >
      <span class="material-symbols-outlined">{{ isDark ? "light_mode" : "dark_mode" }}</span>
    </button>


    <!-- help -->
    <button
      mat-icon-button
      class="top-bar-button"
      (click)="help_onClick()"
      matTooltip="Show info and help about the application."
      matTooltipClass="top-bar-tooltip"
      >
      <span class="material-symbols-outlined">info</span>
  </button>


    <!-- google sign-in -->
    <label #googleSignInButton
      class="top-bar-button"
      matTooltip="Sign in to Google"
      matTooltipClass="top-bar-tooltip"
      style="position: relative; top: 23px;"
      (mouseenter)="notifyUserIsAboutToSignInToGoogle()"
      (mouseleave)="userLeftTheSignInToGoogleBuilding()"
      >
      sign in
    </label>

    <label #googleSignOutButton
      class="top-bar-button"
      matTooltip="Sign out of Google"
      matTooltipClass="top-bar-tooltip"
      style="position: relative; top: 23px; display: none;"
      >
      sign out
    </label>

  </div>

</div>

