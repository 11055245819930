
/** @description Generic applicable eventargs, without old-value.
 *     Contains a target value, and a reference in order for the parent to
 *     identify what entity/property the value belongs to.
 */
export class GenericEventArgs {

  /* This class is all about finding the correct way to implement a solid
    component-based architecture. The problem we address here is that when
    a childcomponent throws an event, the parentcomponent that receives the
    event should be able to determine what entity the change belongs to.
    Because: the childcomponent doesn't know.

    Therefor: a childevent that simply throws up a value is useless. But my
    previous solution to throw up a fully fledged structure of entity, pro-
    perty, old- and new value didn't feel right. It should be more basic.

    So, the idea behind this simplification (relative to the PropertyChangedEventArgs)
    is that the burden of wiring together of object, property, oldvalue and newvalue
    should be with the parentcomponent. The childcomponent should just manage
    a value and throw an event when the user changes the value, but it should
    hold, just hold, a key for the parent in order to identify the situation as
    just stated (object, prop, old- & new values).

    The parentcomponent should be able to determine when an event happens, what
    object's property is changing from what old- to what new value.
   */

  reference: any;

  value: string;

  constructor(reference: any, value: string) {
    this.reference = reference;
    this.value = value;
  }
}

