import { MyDuration } from '../duration/my-duration';
import { IDuration } from '../duration/iduration';


/** @description Represents a clocktime with hours and minutes (no seconds).
 *
 */
export class MyTime extends MyDuration implements IDuration {        // inherits from Duration: has some different validation in the setter

  public get hours(): number {
    // Probleem 20191204: alleen de setters zijn anders. Ik wil deze getters erven van
    // SimpleDuration maar dat werkt blijkbaar niet zo maar. Daarom hier duplicaten.

    return this.durationHours;
  }

  public set hours(value: number) {

    if (value.between(0, 23)) {
      this.durationHours = value;
    }
  }

  public get minutes(): number {
    return this.durationMinutes;
  }

  public set minutes(value: number) {
    if (value.between(0, 59)) {
      this.durationMinutes = value;
    }
  }

  public get seconds(): number {
    return this.durationSeconds;
  }

  public set seconds(value: number) {
    this.durationSeconds = value;
  }
}
