import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, DoCheck } from '@angular/core';
import { fromEvent } from 'rxjs';
import { GenericEventArgs } from '../shared/generic-event-args';

/** @description This component is in fact an editable span element, which has
 * the advantage over input- and textarea-elements that it is not rectangular in
 * nature but has the ability to flow around other elements.
 */
@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit, AfterViewInit, DoCheck {

  /**
   * @description a unique ID, used for uniqueness of element-names
   * in case this component exists multiple times on the same page.
   */
  @Input()
  public identifier: string;

  /**
   * @description Any object the parent component needs in order
   * to identify what entity a changed value belongs to.
   */
  @Input()
  public reference: any;

  /**
   * @description the text-value that is managed by this component
   */
  @Input()
  public value: string;

  /**
   * @description outputs change-requests created by the user of this component
   */
  @Output()
  onChange: EventEmitter<GenericEventArgs> = new EventEmitter<GenericEventArgs>();

  constructor() { }

  ngOnInit(): void {  }

  /**
   * @description In some cases we need the view have finished loading. For example, below we need access to
   * the ID of the input-element, but that ID is dynamically generated from data bound
   * to this component, so we have to wait until that has happened.
   */
  ngAfterViewInit(): void {

    // Catch Enter-key because, although it looks fine on insert, we cannot
    //    restore it from backup because on insertion it uses flat html
    //    <br> elements and we do not want to add DOM-structure only for
    //    this purpose.
    const inputElement: HTMLInputElement = this.getElement();
    if (inputElement) {
      fromEvent(inputElement, 'keydown')
        .subscribe((event: KeyboardEvent) => {
          if (event.key.toLowerCase() === 'enter') {
            console.log('kill enter-key in text-input-component.');
            event.preventDefault();   // the enter will not end up in the input.
          }
        });
    }
  }

  /** @description Returns a 'handle' to the main element of this component: the input element.
   */
  public getElement(): HTMLInputElement {

    const elem = document.querySelector('#text-input-contents-' + this.identifier) as HTMLInputElement;
    return elem;
  }

  /**
   * @description gathers values together for sending with change-request-events. Does
   * NOT contain the new value!
   */
  public getEventArgs(): GenericEventArgs {
    return new GenericEventArgs(this.reference, null);
  }

  /** @description fires when a user leaves the text-input component
   */
  public onBlur = (event: Event): void => {
    const args = this.getEventArgs();
    args.value = this.getElement().innerText;

    // send a change-request to the parent
    this.onChange.emit(args);
  };

  ngDoCheck(): void {                                       // ngDoCheck: google angular's lifecyle

    // detect note-changes from the outside world
    const elem: HTMLInputElement = this.getElement();

    // if the textbox' contents have diverged from the current value, then reset the contents to the value.
    if (elem && elem.value !== this.value) {
      // note: the check on 'elem' is just for making tests succeed: it prevents the error cannot read property of null.
      elem.value = this.value;
    }
  }
}
