import { IDuration } from './iduration';
import { Serializable } from 'typescript-json-serializer';

/** @description Represents a duration, in hours and minutes (no seconds).
 */
@Serializable()
export class MyDuration implements IDuration {

  sig = 'class: MyDuration';
  // └─just to recognize that this class, only known by our factory, ends up all over  the app, disguised as IDuration.

  protected durationHours = 0;
  protected durationMinutes = 0;
  protected durationSeconds = 0;

  constructor(hours: number, minutes: number, seconds: number) {
    this.durationHours = hours;
    this.durationMinutes = minutes;
    this.durationSeconds = seconds;
  }

  /** @description Returns true if both hours and minutes are zero
   */
  public get isInitial(): boolean {
    return !this.durationHours && !this.durationMinutes && !this.durationSeconds;
  }

  /** @description Returns true if both hours and minutes are zero
   */
  public get isNegative(): boolean {
    return !this.isInitial && (this.durationHours < 0 || this.durationMinutes < 0 || this.durationSeconds < 0);
  }

  /** @description Returns the total number of seconds in the hours/minutes combination.
   * This is useful for builtin js-functions that expect seconds as unit.
   */
  public get durationInSeconds(): number {
    if (!this.isInitial) {

      return (this.durationHours * 3600) + (this.durationMinutes * 60) + this.durationSeconds;

    } else {
      return 0;
    }
  }

  /** @description Formats the duration as hh:mm (optionally without the colon)
   */
  public getFormatted(includeColon: boolean): string {
    let hh: string = this.durationHours.padZero(2);     // prefixes zero's
    let mm: string = this.durationMinutes.padZero(2);
    const ss: string = this.durationSeconds.padZero(2);
    if (includeColon) {
      hh = hh + ':';
      mm = mm + ':';
    }
    return hh + mm + ss;
  }

  public get hours(): number {
    return this.durationHours;
  }

  public set hours(value: number) {
    if (value >= 0) {
      this.durationHours = value;
    }
  }

  public get seconds(): number {
    return this.durationSeconds;
  }

  public set seconds(value: number) {
    if (value.between(0, 59)) {
      this.durationSeconds = value;
    }
  }

  public get minutes(): number {
    return this.durationMinutes;
  }

  public set minutes(value: number) {
    if (value.between(0, 59)) {
      this.durationMinutes = value;
    }
  }
}
