import { ATimedTask } from './a-timed-task';
import { ATask } from './a-task';

export class TimedTask1 extends ATimedTask {

  sig = 'class: TimedTask1';
  // └─just to recognize that this class, only known by our factory, ends up all over  the app, disguised as ITask.

  constructor(task: ATask) {
    super();
    this.task = task;
  }
}
