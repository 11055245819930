<div
  #apptree
  id="tree-section"
  class="todo repress breath cool">

  <div class="" style="display: block">
    <div class="rij" style="float:left">
      <h4 (click)="toggleExpandEntireTree()">{{ title }} &nbsp;</h4>
      <sup
        class="stack-counter"
        matTooltip="The number of 'open' todo-tasks in the tree."
        [hidden]="!showTotalSize"

        matTooltipClass="top-bar-tooltip">{{ totalSize }}

      </sup>
    </div>
    <div class="rij" style="float:right;display: flex;">

      <span [hidden]="!showSubFilterCheckbox">
        <mat-checkbox [checked]="_settings.todo.hideDoneTasks" (change)="onHideDoneChanged()"
          matTooltip="Hide the done/cancelled tasks from the tree" matTooltipClass="top-bar-tooltip">Hide done tasks
        </mat-checkbox>
      </span>

      <!-- app dropdown is hidden, display=none. -->
      <app-dropdown #ddlAppOptions style="width:200px;height:35px;text-align: right;display:none;"
        [items]="appOptionsList" [selectedValue]="selectedApp" (onSelectionChanged)="onAppOptionChanged($event)"
        matTooltip="A dropdown for life" matTooltipClass="top-bar-tooltip"></app-dropdown>
    </div>
  </div>

  <label for="new-task" style="display: none;">Type new text here</label>
  <input type="text" id="new-task" class="controlpanel-input mat-app-background"
    placeholder="Type new text here, then press Enter ..."
    (keyup.enter)="taskInsertionField_onEnter($event.target)">

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList
    (cdkDropListDropped)="tree_onDrop($event)">



    <!-- This is the tree node template for leaf nodes ------------------------------------------------------------------------------------- L E A F  N O D E --------->

    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding cdkDrag [cdkDragData]="node">

      <div class="fullwidth" [ngStyle]="{ 'background-color': (node && node.task && activatedTask && node.task.id === activatedTask.id) ? 'antiquewhite' : 'transparent' }" >

        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>

        <!-- SK april 2020: alas, the dragndrop part cannot be moved into a subcomponent because that causes a
        "DOMException: Failed to execute 'replaceChild' on 'Node': The node to be removed is no longer a child of this node".
        So that part is still duplicated on this page. -->

        <div *cdkDragPlaceholder [hidden]="false"><button class="scarcely-visible"><span
              class="dragging-placeholder-text"></span></button></div>

        <span class="todo-item-draghandle" cdkDragHandle>
          <svg width="24px" fill="antiquewhite" viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </span>

        <app-treenode-content
          [node]="node"
          [mustShowStatusButton]="showStatusButton"
          [mustShowDoNowButton]="mustShowDoNowButton(node.task) && showDoNowButton"
          [mustDisableDoNowButton]="mustDisableDoNowButton(node.task)"
          [doNowButtonText]="getDoNowButtonText(node.task)"
          [mustShowCancelButton]="mustShowCancelButton(node.task)"
          [mustDisableCancelButton]="mustDisableCancelButton(node.task)"
          [cancelButtonText]="getCancelButtonText(node.task)"
          [isNoteVisible]="isVisibleNote(node.task)"
          [mustDisableSubtaskButton]="mustDisableSubtaskButton(node.task)"
          (onSubtask)="subtask($event)"
          (onDoNow)="do($event)"
          (onCancel)="cancel($event)"
          (onShowNote)="showNote($event)"
          (onContentChange)="receiveContentChange($event)"
          (onNoteChange)="receiveNoteChange($event)"
          (onNoteHeightChange)="receiveNoteHeightChange($event)"
          [showDocumentationButton]="showDocumentationButton"
          (onDoctate)="receiveRequestToConvertTaskIntoDocumentation($event)">
        </app-treenode-content>

      </div>
    </mat-tree-node>



    <!-- This is the tree node template for expandable nodes ---------------------------------------------------------------------------------- E X P A N D A B L E   N O D E ------------>
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding cdkDrag [cdkDragData]="node">

      <div class="fullwidth">

        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? '▼' : '►'}}
          </mat-icon>
        </button>

        <div *cdkDragPlaceholder [hidden]="false"><button class="scarcely-visible"><span
              class="dragging-placeholder-text"></span></button></div>

        <span class="todo-item-draghandle" cdkDragHandle>
          <svg width="24px" fill="antiquewhite" viewBox="0 0 24 24">
            <path
              d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
            </path>
            <path d="M0 0h24v24H0z" fill="none"></path>
          </svg>
        </span>

        <app-treenode-content
          [node]="node"
          [mustShowStatusButton]="showStatusButton"
          [mustShowDoNowButton]="mustShowDoNowButton(node.task) && showDoNowButton"
          [mustDisableDoNowButton]="mustDisableDoNowButton(node.task)"
          [doNowButtonText]="getDoNowButtonText(node.task)"
          [mustShowCancelButton]="mustShowCancelButton(node.task)"
          [mustDisableCancelButton]="mustDisableCancelButton(node.task)"
          [cancelButtonText]="getCancelButtonText(node.task)"
          [isNoteVisible]="isVisibleNote(node.task)"
          [mustDisableSubtaskButton]="mustDisableSubtaskButton(node.task)"
          (onSubtask)="subtask($event)"
          (onDoNow)="do($event)"
          (onCancel)="cancel($event)"
          (onShowNote)="showNote($event)"
          (onContentChange)="receiveContentChange($event)"
          (onNoteChange)="receiveNoteChange($event)"
          (onNoteHeightChange)="receiveNoteHeightChange($event)"
          [showDocumentationButton]="showDocumentationButton"
          (onDoctate)="receiveRequestToConvertTaskIntoDocumentation($event)">
        </app-treenode-content>

      </div>

    </mat-tree-node>



  </mat-tree>

</div>
