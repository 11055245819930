/* eslint-disable no-underscore-dangle */
import { TaskStatus } from './task-status.enum';
import { ITask } from './i-task';
import { UUID } from 'angular2-uuid';

/**
 * Abstract class, made for the decorator pattern, maybe it's not needed?
 */
export abstract class ATask implements ITask {

  // ---------------------------------------------------------
  private _id: string;

  get id(): string {
    if (!this._id) {
      this._id = UUID.UUID();
    }

    return this._id;
  }

  set id(val: string) {
    this._id = val;
  }

  // ---------------------------------------------------------

  private _shortId: string;
  get shortId(): string { return this._shortId; }
  set shortId(val: string) { this._shortId = val; }

  private _order: number;
  get order(): number { return this._order; }
  set order(val: number) { this._order = val; }

  private _parentId: string;
  get parentId(): string { return this._parentId; }
  set parentId(val: string) { this._parentId = val; }

  private _content: string;
  get content(): string { return this._content; }
  set content(val: string) { this._content = val; }

  private _note: string;
  get note(): string { return this._note; }
  set note(val: string) { this._note = val; }


  // ---------------------------------------------------------
  private _noteHeight: number;

  get noteHeight(): number {
    return this._noteHeight;
  }

  set noteHeight(value: number) {
    if (value >= 20) {  // minimum 20 px
      this._noteHeight = value;
    }
  }
  // ---------------------------------------------------------

  private _status: TaskStatus;
  get status(): TaskStatus { return this._status; };
  set status(val: TaskStatus) { this._status = val; }

  // ---------------------------------------------------------
  private _dateCreated: Date;

  get dateCreated(): Date {
    return this._dateCreated;
  }

  set dateCreated(value: Date) {
    // if (!this._dateCreated) {     // you can set it only once
    this._dateCreated = value;
    // } else {
    //   throw Error('Attempt to set dateCreated on task while dateCreated had already been set.');
    // }
  }
  // ---------------------------------------------------------

  // ---------------------------------------------------------
  private _dateStarted: Date;

  get dateStarted(): Date {
    return this._dateStarted;
  }

  set dateStarted(value: Date) {
    this._dateStarted = value;
  }
  // ---------------------------------------------------------

  // ---------------------------------------------------------
  private _dateDone: Date;

  get dateDone(): Date {
    return this._dateDone;
  }

  set dateDone(value: Date) {
    this._dateDone = value;
  }
  // ---------------------------------------------------------

  // 2020-07-16: properties below are not yet getters/setters: I'll await the first results of my experimenting
  // isTimed: boolean;
  // timerName: string;
  // timerSubscriptionId: string;
  // firstTimerTick: Date;
  // shiftingFirstTimerTick: Date;
  // nextTimerTick: Date;
  // resumeDelay: IDuration;
  // timingPatternInfo: TimingPatternInfo;

  private _level: number;
  get level(): number { return this._level; };
  set level(val: number) { this._level = val; }

  private _children: ITask[];
  get children(): ITask[] { return this._children; }
  set children(val: ITask[]) { this._children = val; }

  private _reasonBlocked: string;
  get reasonBlocked(): string { return this._reasonBlocked; }
  set reasonBlocked(val: string) { this._reasonBlocked = val; }
}
