// #region imports

/* eslint-disable guard-for-in */
/* eslint-disable no-trailing-spaces */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable prefer-arrow/prefer-arrow-functions */

// angular deps
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fromEvent, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

// 3party deps
import * as googleSignIn from 'signin-with-google-types';

// internal deps
import { PropertyChangedEventArgs } from '../shared/property-changed-event-args';
import { DropdownEntry } from '../../components/dropdown/dropdown-entry';
import { StyleManager } from '../../styles/style-manager.service';

// model deps
import { IDataService, IFileOpenEventArgs } from 'src/app/model/api/api.contracts-for-plugins';
import { ITask } from 'src/app/model/task/i-task';
import { ITKN_IDATASERVICE, ITKN_IGOOGLEAUTHORIZATIONSERVICE } from 'src/app/application/injectionTokens';
import { IGoogleAuthorizationService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { IStorageMedium } from 'src/app/model/storage-medium.interface';
import { SupportedStorageMedia } from 'src/app/model/supported-storage-media';
import { IIdName } from 'src/app/model/shared/idname.interface';


// #endregion


declare const google: any;

/**
 * @description Top-bar component
 *
 * Important (1): most commands given in the top-bar are forwarded to the parent component (by output.emit).
 *
 * Important (2): google signin function has some repercussions because it causes a redirect to our page i.e. a complete reload.
 * That means that we must recognize this situation and MUST RELOAD the mindstack that was active when the user started authen-
 * ticating with google (for this we store flag latestTag in the cache).
 * This reload (from cache) comes in handy for all kinds of situations (a user can press F5 and keep everything loaded) but it
 * also appears to be confusing in case multiple mindstacks are opened in different tabs of the same
 * browser (F5 then may reload a different mindstack (e.g. when a user presses F5 before he/she did any change to the current
 * mindstack)). Therefor sep/22 I'm working on restricting / guiding that reload-from-cache functionality.
 *
 * Must know's:
 * 1 - it publishes its height (-changes) to its parent so the parent kan respond to that. Complicating factor is that the height of
 *.     the top-bar is the max of the height of its three children.
 *
 * 2 - it directly calls the dataservice to load a mindstack after redirect from google auth (otherwise after auth the ms is empty);
 *
 * 3 - in order to prevent data- or settings-loss (and to do a reload of the data) when a user leaves the page to authentice to google,
 *.     we capture the mouse-enter event over the google sign-in button: so each time a user moves his/her mousecursor in the direction
 *.     of the google button, quickly some data and configuration is saved to the cache. In this same way also the flag to load-latest-
 *.     ms-from-cache is set, which instructs us on pageload to reload the latestTag-ms from cache.
 *
 * 4 - on mouseleave the flag to reload is removed!
 *
 * Functionalities:
 * - inputfield that shows the identifying tag of the loaded mindstack (editable);
 * - app title (on click it re-computes the top-bar heigth, meant as 'repair' for mobile devices);
 * - app subtitle (disappears on small screens);
 * - buttons to load or save mindstack files;
 * - ddl to select mindstack-source (disc, cache, gdrive);
 * - ddl to select file from the mindstack-source;
 *.   └─note that only files with 'whatiamdoing-' and '.json' in the name are loaded  (logic is mainly in the dataservice), and
 *.     that, to keep the names in the ddl short, 'whatiamdoing-' is not shown in the names.
 *.      Besides: only first 50 names are shown, removed files are ignored, order is a>z.
 * - button to load ms from cache (given a tag);
 * - button to switch dark/light mode;
 * - button to login to google;
 * - helpbutton;
 * - slider to change volume (of sound of e.g. timers);
 *
 */
@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, AfterViewInit {


  /** EXPERIMENTAL 17-9-22: automatic load latest ms from cache makes life easy but is sometimes confusing
   * Therefor I try to limit the application of it.
   */
  private autoRestoreLatestMindstackFromCache = false;

  // #region In- and Outputs

  @Input() data: Observable<ITask[]> = new Observable();   //  temporary, for Drive

  @Output() onFileOpenRequest = new EventEmitter<IFileOpenEventArgs>();

  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  /** @description Between 0 and 100. */
  @Input() soundVolume: number;

  /**
   * @description The tag (identifier for the stack to distinguish it from other
   * stacks), which can be set from the top-bar.
   */
  @Input() tag: string;

  @Output() onSoundVolumeChangeRequest = new EventEmitter<PropertyChangedEventArgs>();

  @Output() onTagChangeRequest = new EventEmitter<PropertyChangedEventArgs>();

  // https://www.themarketingtechnologist.co/building-nested-components-in-angular-2/

  /**
   * @description Any height-change of the top-bar is published so its container (subscriber)
   * can respond to it.
   */
  @Output() onHeightChangeChange = new EventEmitter<number>();

  // #endregion



  // #region Storage handling for opening and saving files

  // Note: there are 2 dropdowns: (1) dropdown with storagemedia, and if gdrive is chosen as
  // storagemedium a (2) dropdown with filenames from drive.

  /** if true, the dropdownlist with storagemedia is made visible */
  public showStorageMediaDropdownList = false;
  /** the list that is shown in the storage-media-dropdownlist */
  public storageMediaDropdownList: DropdownEntry[];
  public selectedStorageMediumItem: DropdownEntry = null;
  public selectedStorageMedium: IStorageMedium = null;
  /** if true, the dropdownlist with files from google drive is made visible */
  public showFileList = false;
  /** the list that is shown in the files-dropdownlist */
  public fileList: DropdownEntry[];
  public selectedFileItem: DropdownEntry = null;
  public selectedFile: IIdName = null;

  /** fires when the user selects a storagemedium from the dropdownlist */
  storageMediumChanged(name: string): void {
    this.selectedStorageMediumItem = this.storageMediaDropdownList.find(x => x.value === name);
    this.selectedStorageMedium = SupportedStorageMedia[name];
  }

  /** fires when the user selects a gdrive-file from the dropdownlist */
  fileChanged(name: string): void {
    this.selectedFileItem = this.fileList.find(x => x.value === name);
    this.dataservice.readFromDrive(this.selectedFileItem.value).subscribe({
      next: (result) => {
        console.log(`fileload ${this.selectedFileItem.label} successfull: ${String(result)}`);
      }
    });
  }

  public get mustDownloadToDisc(): boolean {
    return this.selectedStorageMedium &&  this.selectedStorageMedium.name === SupportedStorageMedia.LocalDisc.name;
  }
  // #endregion Storage handling



  // #region C'tor and inits

  constructor(
    private readonly elementRef: ElementRef,
    private renderer: Renderer2,  // needed for scriptinjection
    @Inject(ITKN_IDATASERVICE) public dataservice: IDataService,   // view goes directly to dataservice: that's wrong todo1234,  // temporary ivm drive
    @Inject(ITKN_IGOOGLEAUTHORIZATIONSERVICE) public googleAuthService: IGoogleAuthorizationService,
    private styleManager: StyleManager,
    private snackbar: MatSnackBar
  ){

    // initialize storage settings

    // init array
    if (!this.storageMediaDropdownList) {
      this.storageMediaDropdownList = [];
    }

    // Dynamically create a dropdownlist with supported storage-media (local disc, google drive, etc.).
    // Tech: because the supported-media are internally represented as a const, an object, we must
    // iterate over the properties of SupportedStorageMedia object to create a dropdownlist.
    // eslint-disable-next-line prefer-const
    for (let prop in SupportedStorageMedia) {   // prop is 'GDrive', 'LocalStorage' etc.
      this.storageMediaDropdownList.push(new DropdownEntry(SupportedStorageMedia[prop].name, SupportedStorageMedia[prop].name));
    }
  }


  ngOnInit(): void {

    this.saveSpace = false;

    this.injectScriptForGoogleSignIn();

    this.googleAuthService.detectAccessTokenAfterGoogleRedirectAfterUserSignedIn$().subscribe({
      next: (val) => {
        if (this.autoRestoreLatestMindstackFromCache) {
          this.autoRestoreLatestMindstackFromCache = false;
          if (this.dataservice.loadFromCache(true)) { // 'true' means: load latest
            this.notify.emit('newStackLoaded');
            console.log('Top-bar: loaded data from latest used tag');
          }
        }

        // onderstaande misschien niet hier doen maar notify emit doen, want
        // anders moet ik hier de user-storage-settings hebben.
        if (!val && this.dataservice.appSettings.storageMedia.primaryProvider.medium.id === SupportedStorageMedia.GDrive.id) {
          this.warnNotSignedIntoGoogle();
        }
      },
      error: (err) => {
        console.log('error detectAccessTokenAfterGoogleRedirectAfterUserSignedIn: ' + String(err));
      }
    });
  }

  private warnNotSignedIntoGoogle(): void {
    console.warn('top-bar: You have not been signed into Google.');
  }

  ngAfterViewInit(): void {

    this.initializeHeightPublishing();
  }
  // #endregion



  // #region Google Sign In

  /** Button: sign in/out to/from Google */
  @ViewChild('googleSignInButton', {}) googleSignInButton: ElementRef;
  @ViewChild('googleSignOutButton', {}) googleSignOutButton: ElementRef; // not in use yet

  public signed_in_to_Google = false;

  private injectScriptForGoogleSignIn() {

    const google_gsi_script_element = this.renderer.createElement('script');
    google_gsi_script_element.src = 'https://accounts.google.com/gsi/client';
    google_gsi_script_element.onload = () => {
      console.log('top-bar: Script injected: gsi (google sign in).');
      this.initialize_GoogleSignIn_button();
    };
    this.renderer.appendChild(this.elementRef.nativeElement, google_gsi_script_element);
  }


  /** Renders the Google sign-in button in the top-bar and wires up the code. */
  private initialize_GoogleSignIn_button() {

    // wire up creds + code for sign-in.
    google.accounts.id.initialize({
      client_id: this.googleAuthService.CLIENT_ID,
      callback: this.handleCredentialResponseAfterUserSignedInToGoogle,    // callback is called after user has clicked the Google sign-in button and has signed in (not if not signed in).
    });

    // When a user clicks the login button, a "Sign in with Google" popup screen is shown:
    // Either "Choose an account to continue to whatiamdoing.net" (if the account is already active on the device)
    // or a "Login ... to continue to whatiamdoing.net".
    // After signing in, Google responds with a CredentialResponse object, wherein resides a JWT, that is passed to the configured callback (see previous code).

    const googleButtonStyling = { theme: 'filled_black', shape: 'pill', size: 'medium', logo_alignment: 'right', type: 'icon', text: 'signin' };
    // └customization attributes, see https://developers.google.com/identity/gsi/web/reference/html-reference#attribute-types
    // Experiment at https://developers.google.com/identity/gsi/web/tools/configurator
    // themes: outline | filled_black | filled_blue, type: icon | standard, shape: circle | square

    google.accounts.id.renderButton(
      this.googleSignInButton.nativeElement,
      googleButtonStyling
    );

    // probleem: ik weet nog niet hoe ik uit kan signen (en of dat wel nodig is), en als button nu display=none heeft crasht de volgende code.
    // google.accounts.id.renderButton(
    //   this.googleSignOutButton.nativeElement,
    //   googleButtonStyling
    // );

    // google.accounts.id.prompt(); // also display the One Tap dialog
  }


  /** Called after user signed in to Google */
  public handleCredentialResponseAfterUserSignedInToGoogle = (credentialResponse: googleSignIn.CredentialResponse): void => {

    console.log('top-bar: handle creds after user signed in');

    this.signed_in_to_Google = true;  // heeft niet zoveel zin want waarde is false na redirect van oauth
    this.updateSigninStatus(true);    // idem

    // the ID token as a base64-encoded JSON Web Token (JWT) string
    // const jwt: string = credentialResponse.credential;
    // Description of the CredentialResponse class: https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse

    this.googleAuthService.oauthToGoogleDrive();
  };


  private updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      this.googleSignInButton.nativeElement.style.display = 'none';
      // this.googleSignOutButton.nativeElement.style.display = 'block';
      // console.log('User signed in.');
    } else {
      this.googleSignInButton.nativeElement.style.display = 'block';
      // this.googleSignInButton.nativeElement.style.display = 'none';
      // console.log('User signed out.');
    }
  }

  // #endregion



  // #region Publish element height

  // What: the top-bar emits its height when this changes.
  //
  // Why: the top-bar height is dynamic, so this way the parent (container) can respond to it
  // and reposition other elements if needed.
  //
  // How: the tricky part is that the topbar has no height of itself: its height is determined
  // by the highest of its children, plus eventually (fixed) padding and margin. Therefor we
  // monitor the height of all children, and when one changes we compute the max of the heights
  // and emit that value. In order to have a reference to these child-elements we created
  // templatevariables. We named them 'altimeter' because that's the only reason for naming.
  //
  // Note: because so many resize events fire, we decided to throttle it, or more specifical-
  // ly we use rxjs.distinctUntilChanged, so each change is only published once. This could
  // be optimized further but is good enough now.


  /** if true,  we save space by hiding the subtitle. Fix for small devices. */
  public saveSpace: boolean;


  /** @description A ref to our altimeter1 element. */
  @ViewChild('altimeter1', {}) altimeter1: ElementRef;
  /** @description A ref to our altimeter2 element. */
  @ViewChild('altimeter2', {}) altimeter2: ElementRef;
  /** @description A ref to our altimeter3 element. */
  @ViewChild('altimeter3', {}) altimeter3: ElementRef;
  /** @description A ref to our altimeter4 element. */
  @ViewChild('altimeter4', {}) altimeter4: ElementRef;

  // see: https://indepth.dev/exploring-angular-dom-manipulation-techniques-using-viewcontainerref/

  /** @description Returns a 'handle' to the element of this component from which we determine
   * the height (thereof _altimeter_) of the topbar.
   */
  public getAltimeter1Element(): HTMLDivElement {
    const elem = this.altimeter1.nativeElement as HTMLDivElement;
    return elem;
  }

  /** @description Returns a 'handle' to the element of this component from which we determine
   * the height (thereof _altimeter_) of the topbar.
   */
  public getAltimeter2Element(): HTMLDivElement {
    const elem = this.altimeter2.nativeElement as HTMLDivElement;
    return elem;
  }

  /** @description Returns a 'handle' to the element of this component from which we determine
   * the height (thereof _altimeter_) of the topbar.
   */
  public getAltimeter3Element(): HTMLDivElement {
    const elem = this.altimeter3.nativeElement as HTMLDivElement;
    return elem;
  }

  /** @description Returns a 'handle' to the element of this component.
   * This one is for deciding on hiding the subtitle.
   */
  public getAltimeter4Element(): HTMLDivElement {
    const elem = this.altimeter4.nativeElement as HTMLDivElement;
    return elem;
  }

  /**
   * @description Creates an observable from the ResizeObserver interface: by making it a true
   * observable we can pipe it with distinct, debounce etc.
   * @param elem Some element you want to observe
   * @returns Observable<unknown>
   * @copyright https://stackoverflow.com/questions/55091530/observe-resize-of-a-dom-element-using-rxjs
   */
  private resizeObservable(elem: Element): Observable<unknown> {
    return new Observable((subscriber) => {
      const ro = new ResizeObserver((entries) => {
        subscriber.next(entries);
      });

      // Observe one or multiple elements
      ro.observe(elem);

      // Can the below be replaced with 'return () => ro.unobserve(elem);' or is that a memory leak?
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      return function unsubscribe() {
        ro.unobserve(elem);
      };
    });
  }

  /**
   * @description Solution to make the app more responsive to small devices: we save some space in the
   * topbar by hiding non-necessary elements when we detect that the header expands (as a result of
   * the small screen).
   *
   * The technical challenge was that the top-bar has no height of its own: its height is determined by
   * its childelements, as a result of which we have to monitor these child-heights: as soon as the
   * max of these child-height changes we notify a height-change to the parent, which decides to hide
   * or show the elements.
   *
   * Should be called on initialization of the component. Initializes some observables
   * in order to detect when the element-height may change and publish this change so subscribers
   * can respond to it.
   */
  private initializeHeightPublishing() {

    // Observe heights of childelements of the top-bar (observe 3 divs)
    // and publish on any new height. Note that the number of times the
    // height is published could be further refined because it fires on
    // any height-change of any child-element, also when this does not
    // change the height of the top-bar.

    this.resizeObservable(this.getAltimeter1Element())
      .pipe(map(x => (x[0] as ResizeObserverEntry).borderBoxSize[0].blockSize))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.publishNewHeight();
      });

    this.resizeObservable(this.getAltimeter2Element())
      .pipe(map(x => (x[0] as ResizeObserverEntry).borderBoxSize[0].blockSize))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.publishNewHeight();
      });

    this.resizeObservable(this.getAltimeter3Element())
      .pipe(map(x => (x[0] as ResizeObserverEntry).borderBoxSize[0].blockSize))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.publishNewHeight();
      });

    this.resizeObservable(this.getAltimeter4Element())
      .pipe(map(x => (x[0] as ResizeObserverEntry).borderBoxSize[0].blockSize))
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.saveSpace = !!(this.getAltimeter4Element().clientHeight > 50);
        // └─Not sure whether the 50 works on all devices. By default the titel has height 38.
        // By doing this check we in fact check whether the main titletext has been wrapped.
        // In that case we want to save space (currently, the subtitle is hidden).
      });

    // make sure vertical alignment is OK directly after loading the page.
    this.publishNewHeight();

    // manual fallback: a user can click the title-area, when the page doesn't respond (quick enough).
    // Intended for mobile phone.
    fromEvent(this.getAltimeter1Element(), 'click').subscribe(() => this.publishNewHeight());
  }

  /**
   * @description emits the new height of this element.
   */
  publishNewHeight(): void {
    // the max of the heights of the 3 child divs determines the height of the parent-div.
    const height1 = this.getAltimeter1Element().clientHeight;
    const height2 = this.getAltimeter2Element().clientHeight;
    const height3 = this.getAltimeter3Element().clientHeight;

    const maxHeight = Math.max(height1, height2, height3) + 32; // 32, because we add top and bottom padding of both 16px.

    this.onHeightChangeChange.emit(maxHeight);
    // this.notify.emit('setBackgroundColor');   // 17/9/22: quick fix to also have a backdoor to repair background color
  }

  // #endregion



  // #region Handle buttonclicks



  public save_onClick(): void {
    this.notify.emit('save');
  }

  public open_onClick(): void {

    // If google Drive is set as the primary storage-provider, a filelist is requested from Drive and
    // presented to the user in a dropdownlist.

    // Note that for saving to local disc a different mechanism is used. See the documentation on that.

    if (this.selectedStorageMedium &&  this.selectedStorageMedium.name === SupportedStorageMedia.GDrive.name) {
      this.dataservice.getListFromDrive().subscribe({
        next: (data) => {
          // Fill dropdownlist.
          // First convert to id-name list
          const idNames: IIdName[] = JSON.parse(String(data)).files.map(x => ({ id: x.id, name: x.name }) as unknown as IIdName);
          this.fileList = [];
          // eslint-disable-next-line prefer-const
          idNames.forEach(x => this.fileList.push(new DropdownEntry(x.id, x.name.replace('whatiamdoing-', '')))); // shorten names!
          this.showFileList = true;
        },
        error: (err) => {
          console.log(err);
          this.snackbar.open(err , null, { duration: 3000, verticalPosition: 'top' });
        }
      });
    }
  }

  public restore_onClick(): void {
    this.notify.emit('restore');
  }

  /** @description Pipe through file-that-has-been-loaded-from-disc */
  receiveFileOpenRequest = (args: IFileOpenEventArgs): void => {
    this.onFileOpenRequest.emit(args);
  };

  public help_onClick(): void {
    this.notify.emit('help');
  }




  /** @description When a user changes the volume via the slider in the UI */
  public receiveTagChangeRequest(event: Event): void {
    console.log('received tag change request');
    const elem = document.getElementById('taginput') as HTMLInputElement;
    const args = new PropertyChangedEventArgs(null, 'tag', this.tag, elem.value);
    this.onTagChangeRequest.emit(args);
  }

  /** @description When a user changes the volume via the slider in the UI */
  public receiveSoundVolumeChangeRequest(event: MatSliderChange): void {
    console.log('received volume change request');
    const args = new PropertyChangedEventArgs(null, 'soundvolume', this.soundVolume, event.value);
    this.onSoundVolumeChangeRequest.emit(args);
    console.log('current top tag: ' + this.tag);
  }


  isDark = this.styleManager.isDark;

  toggleDarkTheme() {
    this.styleManager.toggleDarkTheme();
    this.isDark = !this.isDark;
    if (this.isDark) {
      this.notify.emit('darktheme');
    }
  }
  // #endregion Handle buttonclicks



  // #region Other

  /** @description Forces modelvalues to the view. Because even though we have one-way
   * binding from model to view, the view isn't updated when de model DOES NOT change,
   * which is logical. But a user can alter the view, and when the model rejects the
   * change, model and view get out of sync.
   */
  public syncModelToView(): void {

    // force tag
    const tagElem = document.getElementById('taginput') as HTMLInputElement;
    tagElem.value = this.tag;

    // force soundvolume
    const soundVolumeElem = document.getElementsByTagName('mat-slider')[0] as HTMLElement;
    // eslint-disable-next-line @typescript-eslint/dot-notation
    soundVolumeElem['value'] = this.soundVolume;  // tslint:disable-line:no-string-literal
  }


  public notifyUserIsAboutToSignInToGoogle(): void {
    this.autoRestoreLatestMindstackFromCache = true;    // load latest from cache? Yes, because google will redirect to us.
    this.notify.emit('userSignsIntoGoogle');
  }

  public userLeftTheSignInToGoogleBuilding(): void {
    this.autoRestoreLatestMindstackFromCache = false;     // load latest from cache? No, the user didn't talk to google in the end.
  }

  public notifyUserIsAboutToSave(): void {
    this.notify.emit('userAboutToSave');
  }

  // #endregion
}
