import { IDuration } from '../duration/iduration';
import { Inject } from '@angular/core';
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { ITKN_ILOGSERVICE, ITKN_IDURATIONFACTORY } from 'src/app/application/injectionTokens';
import { IDurationFactory } from '../duration/iduration-factory';

export class TimeComputer {

  constructor(
    @Inject(ITKN_IDURATIONFACTORY) public durationFactory: IDurationFactory
    , @Inject(ITKN_ILOGSERVICE) public logger?: ILogService) {
  }

  /** @description Returns the number of hours/minutes/seconds between two datetimes. Returns a MyDuration object.
   * - millisecond-differences are lost (rounded to seconds)
   */
  public getDateDifferenceInHhMmSs(start: Date, end: Date): IDuration {

    let endIsBeforeStart = false;

    // validate
    if (end < start) {
      endIsBeforeStart = true;
      const thirdVariable = start;
      start = end;
      end = thirdVariable;
      if (this.logger) {
        this.logger.log('getDateDifferenceInHhMmSs: end is before start, we compute the same duration anyway.');
      }
    }

    const duration = this.durationFactory.createDuration(0, 0, 0);
    const diffInMiliseconds: number = (end.getTime() - start.getTime());
    duration.hours = Math.floor(diffInMiliseconds / 1000 / 3600);
    const rest: number = diffInMiliseconds - (duration.hours * 1000 * 3600);
    duration.minutes = Math.floor(rest / 1000 / 60);
    const rest2: number = diffInMiliseconds - (duration.hours * 1000 * 3600) - (duration.minutes * 1000 * 60);
    duration.seconds = Math.floor(rest2 / 1000);

    if (endIsBeforeStart) {
      duration.seconds = -1 * duration.seconds;
      duration.minutes = -1 * duration.minutes;
      duration.hours = -1 * duration.hours;
    }

    return duration;
  }

  /** @description Computes a datetime, given a basedatetime and a duration to add.
   * - assumes seconds and minutes < 60
   */
  public addDurationToDate(base: Date, duration: IDuration): Date {

    if (duration.seconds > 59) {
      throw new Error('addDurationToDate: invalid seconds in duration parameter.');
    }

    if (duration.minutes > 59) {
      throw new Error('addDurationToDate: invalid minutes in duration parameter.');
    }

    // copy base into an editable date.
    const result: Date = new Date(
      base.getFullYear()
      , base.getMonth()
      , base.getDate()
      , base.getHours()
      , base.getMinutes()
      , base.getSeconds()
    );

    const baseSeconds: number = base.getSeconds();
    let newSeconds: number = baseSeconds + duration.seconds;
    let getsAdditionalMinuteFromSeconds = false;
    if (newSeconds >= 60) {
      newSeconds -= 60;
      getsAdditionalMinuteFromSeconds = true;
    }

    const baseMinutes: number = base.getMinutes();
    let newMinutes: number = baseMinutes + duration.minutes;
    if (getsAdditionalMinuteFromSeconds) {
      newMinutes++;
    }

    let getsAdditionalHourFromMinutes = false;
    if (newMinutes >= 60) {
      newMinutes -= 60;
      getsAdditionalHourFromMinutes = true;
    }

    const baseHours: number = base.getHours();
    let newHours: number = baseHours + duration.hours;
    if (getsAdditionalHourFromMinutes) {
      newHours++;
    }

    result.setHours(newHours);
    result.setMinutes(newMinutes);
    result.setSeconds(newSeconds);

    return result;
  }
}
