/**
 * @description Enumeration of timingpatterns that are supported (or not yet) by the current application.
 */
export enum TimingPatternEnum {
  None = 0,
  Duration = 1,                                   // in 20 min
  ClockTime = 2,                                  // at x 'o clock
  RepetitiveDuration = 3,                         // every 20 min
  RepetitiveDurationWithDurationOffset = 4,       // first in 5 minutes, then repeat every 45 min.
  RepetitiveDurationWithClockTimeOffset = 5       // first at x 'o clock, then repeat every 45 min.
}
