<!-- <label>{{node.task.id}}</label> -->

<!-- documentation -->
<button
  mat-raised-button
  matTooltip="Create documentation from this task"
  class="button"
  [ngStyle]="{ 'display': showDocumentationButton ? '' : 'none' }"
  (click)="doctate(node.task)">

    <span class="material-symbols-outlined">bookmark</span>

</button>


<!-- subtask -->
<button
  mat-raised-button
  class="button"
  matTooltip="Create a subitem"
  [disabled]="mustDisableSubtaskButton"
  (click)="subtask(node.task)">

  <span class="material-symbols-outlined">subdirectory_arrow_right</span>
</button>


<!-- do now / mark as done -->
<button
  mat-raised-button
  matTooltip="{{node.task && node.task.children && node.task.children.length > 0 ?
    (node.task.status === 4 ? 'Reopen' : 'Mark as done') :
    (node.task.status === 4 ? 'Reopen' : 'Execute this task')
  }}"
  class="button"
  *ngIf="mustShowDoNowButton" [disabled]="mustDisableDoNowButton"
  (click)="do(node.task)">
  <span class="material-symbols-outlined">{{node.task && node.task.children && node.task.children.length > 0 ? 'done' : 'directions_run' }}</span>
</button>


<!-- cancel -->
<button
  mat-raised-button
  matTooltip="Cancel this task"
  class="button"
  *ngIf="mustShowCancelButton"
  [disabled]="mustDisableCancelButton"
  (click)="cancel($event, node.task)"><span class="material-symbols-outlined">close</span></button>


<!-- note (-button) -->
<button
  mat-raised-button
  matTooltip="Toggle note visibility"
  class="button"
  (click)="showNote(node.task)">
    <span
      class="material-symbols-outlined">{{node.task.note && node.task.note.length > 0 ? 'description': 'note'}}
    </span>
</button>

<!-- status indicator -->
<button
  *ngIf="mustShowStatusButton"
  mat-raised-button
  matTooltip="Status of this task"
  class="button transparent">

  <!-- to do doing postponed done cancelled deleted derived documentation -->
  <span
    class="material-symbols-outlined">{{
      node.task.status == 0 ? 'radio_button_unchecked' :
      node.task.status == 1 ? 'directions_run' :
      node.task.status == 2 ? 'clear_night' :
      node.task.status == 3 ? 'done' :
      node.task.status == 4 ? 'close' :
      node.task.status == 5 ? 'delete' :
      node.task.status == 6 ? 'function' :
      node.task.status == 7 ? 'import_contacts' :
      node.task.status == 8 ? 'lock_open' :
      'help'}}
  </span>
</button>

<a id="{{node.task.id}}" >
  <!-- task contents -->
  <app-text-input [value]="node.task.content" [identifier]="node.task.id" [reference]="node.task"
    (onChange)="receiveContentChange($event)">
  </app-text-input>
</a>

<!-- note contents -->
<div class="note" *ngIf="isNoteVisible">
  <app-note-editor
    [value]="node.task.note"
    [height]="node.task.noteHeight"
    [reference]="node.task"
    (onChange)="receiveNoteChange($event)"
    (onChangeHeight)="receiveNoteHeightChange($event)"
    >
  </app-note-editor>
</div>
