/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IStorageMediumConfiguration } from 'src/app/data/dataService/storage-medium-configuration.interface';
import { NullStorageMediumConfiguration } from 'src/app/data/dataService/storage-medium-configuration.null';
import { IUserStorageMediaConfiguration } from 'src/app/view/usercontrols/main/contracts-for-plugins/user-storage-media-configuration.interface';

export class NullUserStorageMediaConfiguration implements IUserStorageMediaConfiguration {

  primaryProvider: IStorageMediumConfiguration;
  secondaryProvider: IStorageMediumConfiguration;
  otherProviders: IStorageMediumConfiguration[];

  constructor() {
    this.primaryProvider = new NullStorageMediumConfiguration();
    this.secondaryProvider = new NullStorageMediumConfiguration();
    this.otherProviders = [];
  }
}
