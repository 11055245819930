/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AItemStack } from './aitem-stack';
import { ITask, TaskStatus } from 'src/app/model/api/api.task';

/**
 * @description Represents a stack of ITasks. Concrete implementation of the abstract ItemStack class.
 * Supports a.o. push (but not pop, top, isTop, find, sort.
 */
export class TaskStack extends AItemStack<ITask> {

  constructor() {
    super();
    this.items = [];
  }

  pop(): ITask {
    // in this implementation we don't use pop().
    throw new Error('Method intentionally not implemented.');
  }

  /** @description Returns the item that is on top of the stack.
   * @returns Null if everything is postponed/blocked, or if the stack is empty.
   */
  public top(): ITask {
    let result: ITask = null;  // todo: NullStackItem object retourneren, zie ook pop

    if (this.items && this.items.length > 0) {
      result = this.items.find(x => x.status !== TaskStatus.Postponed && x.status !== TaskStatus.Blocked);
    }

    return result;
  }

  isTop(task: ITask): boolean {

    if (this.top()) {
      return task.id === this.top().id;
    }
    else {
      return false;
    }
  }
}
