// ng deps
import { Inject, Injectable } from '@angular/core';

// app deps
import { ITKN_ITASKFACTORYSERVICE } from 'src/app/application/injectionTokens';

// model deps
import { ITask, ITaskFactory } from 'src/app/model/api/api.task';
import { Task1 } from 'src/app/model/task/task1';

@Injectable()
export class DoingService {

  constructor(
    @Inject(ITKN_ITASKFACTORYSERVICE) public taskfactory: ITaskFactory,
  ) { }

  public getNewTaskFromContent(content: string): ITask {
    return this.taskfactory.createTaskFromContent(content);
  }

}
