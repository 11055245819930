//
// code copied from 'old' rxjs version, internal functions:
// https://github.com/ReactiveX/rxjs/blob/6.x/src/internal/util/isArray.ts
// https://github.com/ReactiveX/rxjs/blob/6.x/src/internal/util/isNumeric.ts
//

// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
export const isArray = (() => Array.isArray || (<T>(x: any): x is T[] => x && typeof x.length === 'number'))();

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isNumeric = (val: any): val is number | string => !isArray(val) && (val - parseFloat(val) + 1) >= 0;
// parseFloat NaNs numeric-cast false positives (null|true|false|"")
// ...but misinterprets leading-number strings, particularly hex literals ("0x...")
// subtraction forces infinities to NaN
// adding 1 corrects loss of precision from parseFloat (#15100)
