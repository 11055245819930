// extensions for string, using method from 'Jaques' at
// https://stackoverflow.com/questions/39877156/how-to-extend-string-prototype-and-use-it-next-in-typescript

/**
 * @description Prefixes zero's filling the given length.
 */
String.prototype.padZero = function(length: number): string {
  let s = String(this);
  while (s.length < length) {
    s = '0' + s;
  }
  return s;
};

/** shortID: meant for abbreviating guid ID's.
 */
String.prototype.shortID = function() {
  return String(this).substring(0, 3);
};
