import { ITask, IList } from 'src/app/model/api/api';

export class ListOfTasksService implements IList<ITask> {

  public items: Array<ITask>;

  constructor() {
    this.items = new Array<ITask>();
  }

  isEmpty: boolean;

  /**
   * @description Returns the number of tasks on the stack
   */
  public get length(): number {
    return this.items ? this.items.length : 0;
  }

  public add(item: ITask): boolean {

    if (!this.items.find(x => x.id === item.id)) {
      this.items.unshift(item); // unshift: adds to beginning of array
      return true;
    }
    return false;
  }

  public remove(task1: ITask): void {

    if (this.items && this.items.length > 0) {

      // zoek 'm op
      const idx: number = this.items.findIndex(
        x => x.id === task1.id
      );

      if (idx > -1) {
        // delete 'm
        this.items.splice(idx, 1);
      }
    }
  }
}
