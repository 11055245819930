<div id="helpscreen" class="" (click)="hideHelpscreen()">
    <div class="help-layer"></div>
    <div class="help-layer help-layer-inner">
        <div class="help-contents" (click)="catchMouseClick($event)">
            <!-- CatchMouseClick: prevent closing the helpscreen when a user clicks within the help; the screen should close when the user clicks outside of it. -->
            <h1 id="main">Help</h1>
            <small>
                <a href="#intro">intro</a> &nbsp;
                <a href="#privacy">privacy</a> &nbsp;
                <a href="#contact">contact</a> &nbsp;
                <!-- <a href="#shortkeys">shortkeys</a> &nbsp; -->
                <a href="#timers" (click)="showAdvancedHelpscreen()">timers</a> &nbsp;
                <a href="#relnotes" (click)="showAdvancedHelpscreen()">releasenotes</a>
            </small>
            <hr />

            <h2 id="intro">What I am doing: a free online productivity tool</h2>
            <hr />
            <p>When you are frequently interrupted in your work, this tool may help you keep track of what you are
                doing, were doing or wanted to do. It's a <b>doing</b>-stack, not a <i>todo-</i>list (although there is
                one).
            </p>

            <p>This tool is intended for people like myself who have trouble doing many things at the same time: it's a
                tool for
                single-taskers
                who find themselves in a multi-tasking jungle. It's not a perfect tool, however, as it requires
                some of your -supposedly scarce- mental resources. </p>

            <p>The main section of the tool is the <b>doing-stack</b>. It's a stack, and it assumes you're always
                working on the item on the top. But you can postpone it when you are interrupted by another task.
                On the doing-stack you type what you are doing or read back what you were doing.
                In case a task comes in that you don't want to deal with instantly, you can add it to the
                <i>todo-list</i> on the side.
                History can be found under 'done', filtered if you like.
            </p>

            <h2 id="privacy">Data and privacy</h2>
            <hr />
            <p>Nothing is tracked by this site itself (no data is stored, no cookies are installed), but it runs on
                Google servers so some data is collected by Google.
                The data you type doesn't leave your computer. What you type is stored into the browsers cache
                (<i>localStorage</i>) so if you accidentally refresh the page and everything
                is gone, you can restore it via the <b>Restore</b>-button (note however that everything in that cache is
                lost
                as soon
                as you start to make new changes!).
            </p>
            <p>
                <b>Download, Upload</b> In case you want to robustly backup your sweat-and-tears day of hard works'
                typings you can download
                everything to a file
                on your local computer by clicking the 'download'-button.
                In case you want to continue your slick materials of yesterday, just click the 'upload'-button and pick
                that downloadfile you created earlier.

            </p>

            <h2 id="contact">Contact</h2>
            <hr />
            If you have any questions, suggestions or comments you can e-mail me at <a
                href="mailto:sljmkoks@outlook.com">sljmkoks@outlook.com</a>

            <!-- <h2 id="shortkeys">Keyboard shortcuts</h2>
            <hr />
            <div class="help-shortcut-wrapper">
                <div>h : </div>
                <div>show this help</div>
                <div>/ : </div>
                <div>set focus to the doing insert-field</div>
                <div>Ctrl-/ : </div>
                <div>set focus to the todo insert-field</div>
            </div> -->

            <br /><br />

            <div id="showAdvancedHelp" (click)="showAdvancedHelpscreen()" [hidden]="isAdvancedHelpscreenVisible">
                <u>advanced</u>
            </div>
            <div id="hideAdvancedHelp" (click)="hideAdvancedHelpscreen()" [hidden]="!isAdvancedHelpscreenVisible">
                <u>hide advanced</u></div>

            <div #advancedhelp [hidden]="!isAdvancedHelpscreenVisible" class="advancedhelp">

                <h2>Timers</h2>
                <small>
                    <a href="#main">top</a>
                </small>
                <hr />

                <p><i>Currently, timers can only be created by text-input.</i></p>

                <p>Todo's can be timed, for example:
                    <li>"Call Bob in half an hour 0030"
                    <li>"Start night-shift 0030am"
                    <li>"Check midday mail 1330pm"
                    <li>"Write evening report 0600pm"
                </p>
                <p>You can time a todo in two ways: (1) by a time interval, say
                    30 minutes,
                    or (2) by a specific clocktime, say 11 o' clock.</p>

                <p>If you end a todo-tekst with a 4-digit number (with a colon optionally), the number is interpreted as
                    a time-interval (the
                    digits are interpreted as hh:mm).
                    After this interval the todo is automatically pushed to the top of the doing-stack.
                    Example: "Call Bob 00:20" will automatically be pushed to the top of the doing-stack 20 minutes from
                    now. </p>

                <p>If you append 'pm' or 'am' to the digits, the number is interpreted as a clock-time.
                    Example: "Call Bob 1230pm" will automatically be pushed to the top of the doing-stack at 12:30pm.
                </p>

                <p>If you append another 'rpt xx:yy' or 'repeat xxyy', the reminder is repeated each given xx:yy
                    interval.
                    Example: "Walk around the block 09:00am repeat 01:00" will fire at 9am the first time, and then
                    repeat each hour.
                </p>

                <p>It's also possible to start a repetitive timer without delay: "Sing a song repeat 0:08" will ask you
                    to sing a song each 8 minutes, starting from now. </p>

                <p>Remember: the timing-tekst must be at the end of your input.</p>

                <p>As of <b>march 2020</b> timers can be cancelled, paused/resumed or stopped/started by buttons. In the
                    note of a timer info about the timer is updated but this not yet fully functioning: you may
                    encounter
                    some bugs. </p>

                <br />
                <br />

                <h2 id="relnotes">Releasenotes</h2>
                <small>
                    <a href="#main">top</a>
                </small>
                <hr />

                <span style="font-size:80%;">

                    <h3>dec 11 2020: </h3>
                    <li>Feature: timers are rescheduled on import</li>
                    <li>Major architectural changes</li>
                    <li>Import/export format changed</li>

                    <h3>may 24 2020, maintenance: </h3>
                    <li>Feature: open multiple notes by holding shift-key</li>
                    <li>Feature: in todo-tree hide 'done' tasks (checkbox)</li>
                    <li>Bugfix: upload</li>
                    <li>Bugfix: width of note</li>
                    <li>Bugfix: correct stack-counters</li>
                    <li>Some extra tooltips</li>
                    <li>Under the hood: more automatic tests</li>

                    <h3>may 2 2020, maintenance: </h3>
                    <li>Remember note-size per task</li>
                    <li>Fixed unexpected tree-expansion</li>

                    <h3>april 30 2020, maintenance: </h3>
                    <li>Disallow subtasking done/cancelled tasks</li>
                    <li>Todo childstate 'cancelled' also puts parent in progress</li>
                    <li>Todo-tasks of any state can be moved in the tree</li>
                    <li>Note in todo-tree enlarged</li>
                    <li>Refactored duplicate code</li>

                    <h3>april 23 2020: </h3>
                    <li>implemented 'reopen' of cancelled todo</li>

                    <h3>april 13 2020: </h3>
                    <li>Todo-list is now a (hie&shy;rar&shy;chi&shy;cal) tree in&shy;stead of just a flat
                        or&shy;der&shy;ed list</li>
                    <li>Todo's can get sub-to&shy;do's.</li>
                    <li>Drag-n-drop sup&shy;port&shy;ed in the to&shy;do-tree</li>
                    <li>Takes care au&shy;to&shy;ma&shy;ti&shy;cal&shy;ly of de&shy;pen&shy;den&shy;cies when
                        star&shy;ting a task.</li>
                    <li>Example: when a task is de&shy;le&shy;ted, all its sub&shy;tasks are de&shy;le&shy;ted
                        al&shy;so.</li>


                    <h3>march 29 2020: </h3>
                    <li>new todo's are ad&shy;ded on top</li>
                    <li>soundvolume ad&shy;just&shy;a&shy;ble</li>
                    <li>you can tag your stacks</li>
                    <li>backup settings</li>
                    <li>delete items while hol&shy;ding shift</li>
                    <h4>already released a few days ear&shy;lier:</h4>
                    <li>edit texts direct&shy;ly</li>
                    <li>sort todo's</li>
                    <li>show timerinfo in notes</li>


                    <h3>march 15 2020: Under the hood</h3>
                    <li>app completely rewritten</li>
                    <li>separate section for timers</li>
                    <li>disabled animations</li>
                    <li>slight layout change</li>
                    <li>upload is backward compatible</li>


                    <h3>dec 29 2019: New URL</h3>
                    <li>New url: whatiamdoing.net</li>


                    <h3>nov 2 2019: Initial release</h3>
                    <li>url: mind-stack.net</li>
                    <li>3 sections: doing, done and todo. </li>
                    <li>Todo's can be sorted; done's can be fil&shy;te&shy;red. Doing func&shy;tions as a stack.
                    </li>
                    <li>Any task can have a note.</li>
                    <li>Timed todo's are pos&shy;sib&shy;le, re&shy;pe&shy;ti&shy;tive and/or de&shy;lay&shy;ed by
                        du&shy;ra&shy;tion or spe&shy;ci&shy;fic clock&shy;time.</li>
                    <li>Editor in the done-section.</li>
                    <li>Animations on status-changes.</li>

                </span>
            </div>
        </div>

    </div>
</div>
