import { ITask } from './i-task';

// use 'abstract' to mimick static class c# see https://stackoverflow.com/questions/13212521/typescript-static-classes
export abstract class TaskArrayHelper {

  /**
   * @description Returns a flat list (ordered, but without hierarchical structure) of
   * all children of a task in order of work (first items should be done first).
   * This logic is used when a use clicks 'do now' on a parent, in which case
   * children should be done first.
   */
  public static getOrderOfWork(task: ITask, taskArray: ITask[]): ITask[] {
    let result: ITask[] = [];
    if (!taskArray || taskArray.length === 0) {
      result = [];
    } else if (task && (!task.children || task.children.length === 0)) {
      result = [];
    } else {
      // note the position of the '.concat[x]' which makes the difference from
      // the DepthFirst variant above.
      result = task.children.map(x => this.getOrderOfWork(x, taskArray).concat([x])).reduce((u, t) => u.concat(t), []);
    }
    return result;
  }


  /**
   * @description Returns the parent-task of a task in the given array.
   * Used by getParents().
   */
  public static getParent(task: ITask, taskArray: ITask[]): ITask {
    if (task && task.parentId) {
      return taskArray.find(x => x.id === task.parentId);
    } else {
      return null;
    }
  }

  /**
   * @description Returns the entire array of parent-tasks of a task ([] if no parents) in the given array.
   * Used for computing the level of a todo-task in the tree.
   */
  public static getParentsRecursively(task: ITask, taskArray: ITask[]): ITask[] {
    if (!task || !task.parentId) {
      return [];
    } else {
      const result = this.getParentsRecursively(this.getParent(task, taskArray), taskArray);
      result.push(this.getParent(task, taskArray));
      return result;
    }
  }

  /**
   * @description Returns a flat list (without structure, no usefull order) of all children
   * of a task in the given array. Used a.o. for setting tree properties in the UI.
   */
  public static getChildrenRecursively(task: ITask, taskArray: ITask[]): ITask[] {
    let result: ITask[] = [];
    if (task && task.children && task.children.length !== 0) {
      result = task.children.map(x => this.getChildrenRecursively(x, taskArray)).reduce((x, y) => x.concat(y), task.children);
    }
    return result;
  }

  /**
   * @description Returns a flat list (ordered, but without hierarchical structure) of
   * all children of a task in the given array: the order is Depht First (as in depth-first-search).
   * This is in fact the order of presentation in the tree-control (up to down).
   * Used a.o. for setting tree properties in the UI.
   */
  public static getChildrenRecursivelyDepthFirst(task: ITask, taskArray: ITask[]): ITask[] {
    let result: ITask[] = [];
    if (task && task.children && task.children.length !== 0) {
      // tech: map, concat reduce. see google.
      // For each child its subtree is gathered; finally all result-arrays are concatenated into one long array.
      // The result is a depth-first ordering.
      result = task.children.map(x => [x].concat(this.getChildrenRecursivelyDepthFirst(x, taskArray))).reduce((x, y) => x.concat(y), []);
    }
    return result;
  }
}
