/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { IStorageMedium } from './storage-medium.interface';
import { StorageMethods } from './storage-methods.enum';



/** The currently supported storagemedia in Mindstack. */
export const SupportedStorageMedia = {

  LocalStorage: {
    id: 1,
    name: 'LocalStorage',
    description: 'browsercache',
    supportedMethods: [
      StorageMethods.Overwrite
    ]
  } as IStorageMedium,
  LocalDisc: {
    id: 2,
    name: 'LocalDisc',
    description: 'downloadfolder on local disc',
    supportedMethods: [
      StorageMethods.TimeStamp
    ]
  } as IStorageMedium,
  GDrive: {
    id: 3,
    name: 'GDrive',
    description: 'Google Drive',
    supportedMethods: [
      StorageMethods.Overwrite, StorageMethods.TimeStamp
    ]
  } as IStorageMedium
};
