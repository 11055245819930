/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import { ISettings, ITodoViewSettings } from 'src/app/model/api/api.contracts-for-plugins';
import { IDocuViewSettings } from 'src/app/model/contracts-for-plugins/docu-view-settings.interface';
import { IUserStorageMediaConfiguration } from 'src/app/view/usercontrols/main/contracts-for-plugins/user-storage-media-configuration.interface';

/** @description New settings in this object are automatically included in the backup-file, but
 * you should explicitly add them to the backup-service's importSettings-method in
 * order to load them on import.
 */
export class Settings1 implements ISettings {

  constructor() {   }


  /** @description (string) The 'name' of the stack in order to identify it amongst multiple stacks.
   * This tag is used as part of the key for backup and download.
   *
   * When is a tag important?
   * It is especially important when you open two tabs to whatiamdoing.net in a single browser,
   * because they will backup to the same key in local-storage. A restore would return wrong data
   * in one of the tabs. By including the tag in the backup-key these caches are kept separate.
   * A second advantage is that on downloading the tag is included in the filename which makes it
   * easier to recognize what file to upload.
   */
  tag = 'DEFAULT';

  /** @description (number, int) The volume of the sounds of the app: between 0 and 100.
   */
  soundVolume = 25;

  /** a color from 1 to 9, refers to classes color1, color2 etc. in Styling.scss */
  color: number;

  /** @description settings with respect to the todo/backlog-view
   */
  todo: ITodoViewSettings = {
    /** @description Show/hide tasks in the tree that are 'done' or 'cancelled'. Default false.
     * In a tree-view a branch is hidden only when all children and the parent are 'done'.
     * Keeping them all visible can trouble your view on the project.
     */
    hideDoneTasks: false,
    expandedNodeSet: new Array<string>()
  } as ITodoViewSettings;

  documentation: IDocuViewSettings = {
    expandedNodeSet: new Array<string>()
  } as IDocuViewSettings;

  storageMedia: IUserStorageMediaConfiguration;
}
