import { ILogService } from './i-logger.service';

export class LoggerService implements ILogService {

  logs: string[] = []; // capture logs for testing

  log(message: string): void {
    this.logs.push(message);
    console.log(message);
  }

  constructor() { }

  public getAll(separator?: string): string {

    if (!separator) {
      separator = '';
    }

    return this.logs.reduce((x, y) => x + separator + y);
  }
}

