/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { StorageMethods } from 'src/app/model/storage-methods.enum';
import { IStorageMedium } from 'src/app/model/storage-medium.interface';
import { IStorageMediumConfiguration } from './storage-medium-configuration.interface';
import { NullStorageMedium } from './storage-medium.null';

/**
 * @description A medium with its selected method.
 */
export class NullStorageMediumConfiguration implements IStorageMediumConfiguration {

  /** ref, ID of a SupportedStorageMedia-medium, such as LocalStorage, LocalDisc, GDrive, etc. */
  medium: IStorageMedium;

  /** Overwrite, Timestamp */
  method: StorageMethods;

  /** Unique identifier for blob/file on the medium */
  externalId: string;


  constructor() {
    this.medium = new NullStorageMedium();
    this.method = StorageMethods.Overwrite;
    this.externalId = '0';
  }
}
