import { StorageMethods } from 'src/app/model/storage-methods.enum';
import { IStorageMedium } from 'src/app/model/storage-medium.interface';

/**
 * @description Empty @see StorageMedium.
 */
export class NullStorageMedium implements IStorageMedium {

  id: number;

  name: string;

  description: string;

  supportedMethods: StorageMethods[];

  constructor() {
    this.id = 0;
    this.name = 'Null Medium';
    this.description = 'No description';
    this.supportedMethods = [];
  }
}
