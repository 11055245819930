/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { StorageMethods } from 'src/app/model/storage-methods.enum';
import { IStorageMediumConfiguration } from './storage-medium-configuration.interface';
import { IStorageMedium } from 'src/app/model/storage-medium.interface';

/**
 * @description A medium with its selected method.
 */
export class StorageMediumConfiguration1 implements IStorageMediumConfiguration {

  /** ref, ID of a SupportedStorageMedia-medium, such as LocalStorage, LocalDisc, GDrive, etc. */
  medium: IStorageMedium;

  /** Overwrite, Timestamp */
  method: StorageMethods;

  /** Unique identifier for blob/file on the medium */
  externalId: string;

  constructor(obj?: StorageMediumConfiguration1) {
    if (obj) {
      this.medium = obj.medium;
      this.method = obj.method;
      this.externalId = obj.externalId;
    }
  }
}
