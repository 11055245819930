import { Renderer2 } from '@angular/core';
import { IFocusService } from 'src/app/view/shared/contracts-for-plugins/ifocus.service';

/** @description Service that provides focus-setting functionality.
 */
export class FocusService implements IFocusService {

  constructor() { }

  /** @description Sets focus to the first element that matches the given selector.
   */
  public setFocus(selector: string, renderer: Renderer2, preserveContents = true, selectAllContents = false): void {

    // set focus: see https://stackoverflow.com/questions/46720611/how-to-use-angular4-to-set-focus-by-element-id
    //    Although I found out about preserveContents only later.
    const element = renderer.selectRootElement(selector, preserveContents);
    setTimeout(() => element.focus(), 0);

    if (preserveContents && selectAllContents) {
      window.getSelection().selectAllChildren(element);   // https://developer.mozilla.org/en-US/docs/Web/API/Selection/selectAllChildren
    }
  }
}
