import { InjectionToken } from '@angular/core';
import { IDemoDataService } from 'src/app/data/dataService/plugins/contracts-for-plugins/idemo-data.service';
import { ILogService, IGoogleAuthorizationService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { ISoundService } from 'src/app/view/usercontrols/main/contracts-for-plugins/sound.service.interface';
import { IFocusService } from 'src/app/view/shared/contracts-for-plugins/ifocus.service';
import {
  ITask,
  IDataService,
  IDurationFactory,
  ITimeFactory,
  ITimerService,
  IList,
  ITaskFactory
} from 'src/app/model/api/api';
// import { IGoogleAuthorizationService } from 'src/app/cross-cutting-concerns/authorization/igoogle-authorization-service';




/** ************************************************************************************
 * Define injectiontokens for interfaces.
 *
 * We do this because interfaces can themselves, unlike classes, not be used as
 * injectiontokens directly (because in typescript interfaces are not full blown
 * 'native' interfaces because as such they don't exist in javascript).
 *
 ************************************************************************************* */


export const ITKN_IDATASERVICE = new InjectionToken<IDataService>('idataservice');
export const ITKN_IDEMODATASERVICE = new InjectionToken<IDemoDataService>('idemodataservice');
export const ITKN_ISTORAGESERVICE_GDRIVE = new InjectionToken('istorageservicegdrive');
export const ITKN_ISTORAGESERVICE_LOCALDISC = new InjectionToken('istorageservicelocaldisc');
export const ITKN_ISTORAGESERVICE_LOCALSTORAGE = new InjectionToken('istorageservicelocalstorage');

export const ITKN_ILOGSERVICE = new InjectionToken<ILogService>('ilogservice');
export const ITKN_IDURATIONFACTORY = new InjectionToken<IDurationFactory>('idurationfactory');
export const ITKN_ITIMEFACTORY = new InjectionToken<ITimeFactory>('itimefactory');
export const ITKN_ISOUNDSERVICE = new InjectionToken<ISoundService>('isoundservice');
export const ITKN_IFOCUSSERVICE = new InjectionToken<IFocusService>('ifocusservice');
export const ITKN_ITIMERSERVICE = new InjectionToken<ITimerService>('itimerservice');
export const ITKN_ILISTOFTASKS = new InjectionToken<IList<ITask>>('ilistoftasks');
export const ITKN_IGOOGLEAUTHORIZATIONSERVICE = new InjectionToken<IGoogleAuthorizationService>('igoogleauthorizatoinservice');



// enigszins experimenteel: om makkelijk verschillende conrete implementaties aan de
//  verschillende services te hangen. Van belang is wel dat de interface overal gelijk blijft
//  zodat wisselen van concrete implementatie eenvoudig blijft.
export const ITKN_ITASKFACTORYSERVICE = new InjectionToken<ITaskFactory>('itaskfactoryservice');
export const ITKN_ITASKFACTORYSERVICE_for_DEMODATASERVICE = new InjectionToken<ITaskFactory>('itaskfactoryservicefordemodataservice');
export const ITKN_ITASKFACTORYSERVICE_for_STORAGESERVICE = new InjectionToken<ITaskFactory>('itaskfactoryserviceforstorageservice');
