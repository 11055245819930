/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { IStorageProvider } from '../../contracts-for-plugins/istorage-provider';

@Injectable({
  providedIn: 'root'
})
export class LocalDiskStorageProvider implements IStorageProvider {

  constructor() { }

  create(name: string, contents: string, id?: string): void {
    throw new Error('Method not implemented because too complicated for the moment. Use method _downloadToDisc_ in dataservice.');
  }

  read(id: string): string {
    throw new Error('Method not implemented because too complicated for the moment. Use load-file-from-disc component.');
  }

  update(id: string, contents: string): void {
    throw new Error('Method not available for disc.');
  }

  delete(id: string): void {
    throw new Error('Method not available for disc.');
  }
}
