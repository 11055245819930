<div class="container">
    <div (click)="switchExpansionState()" class="selected-item">
        {{selectedItem.label}}
        <app-hamburger-symbol></app-hamburger-symbol>
    </div>
    <div [hidden]="!expanded" class="list">
        <ul>
            <li *ngFor="let item of items" class="list-item" (click)="selectItem(item)">{{item.label}}</li>
        </ul>
    </div>
</div>