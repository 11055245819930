import { ITask } from './i-task';

export class TaskArrayContainer {

  constructor() {
    this.items = [];
  }

  items: ITask[];

  /** @description The size of the collection (number of elements in it)
   * (getter)
   */
  get size(): number {
    return this.items.length;
  }
}
