import { IStack, ILookup, ISortable } from 'src/app/model/api/api.collections';

/** @description An abstract item-stack with items of type T, so not yet specific for any kind of item
 *  It uses a simple array of items for implementation.
 *
 * @summary It's not very generic so regard it as specific for this application: that's why
 *  the axis of change directs the file into the doing folder.
 */
export abstract class AItemStack<T> implements IStack<T>, ILookup<T>, ISortable<T> {

  items: T[];

  push(x: T): void {
    this.items.push(x);
  }

  pop(): T {
    throw new Error("Method intentionally not implemented.");
  }

  isTop(x: T): boolean {
    throw new Error("Method not implemented.");
  }

  top(): T {
    // not implemented here because 'top' doesn't necessarily mean
    // the item at position 0.
    throw new Error("Method not implemented.");
  }

  get length(): number {
    return this.items.length;
  }

  get isEmpty(): boolean {
    return !this.items || this.items.length == 0;
  }

  find(predicate: (x: T) => boolean): T {
    return (<T[]>this.items).find(predicate);
  }

  sort(comparer: (x: T, y: T) => number): T[] {
    return this.items.sort(comparer);
  }
}
