export * from '../contracts-for-plugins/__export-contracts-for-plugins';

// experimental: export related classes.
//    The idea behind this is that a consumer only has to import one package.
//    disadvantage: a consumer may consume ITask from api.task, or from api.data. It is not necessarily
//    beknownst to him/her that these are one and the same.
//    So what is wise?
//    I think it's wise to not do this. aug 2020

// export { ITask, ITimedTask } from './api.task';
// export { IAppSettings, ITodoViewSettings } from './api.viewmodels';
