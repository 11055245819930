/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Injectable, Inject } from '@angular/core';
import { Settings1 } from 'src/app/data/dataService/settings1/settings1';
import { ISettings } from 'src/app/model/api/api.contracts-for-plugins';
import { ITask, ITaskFactory, TaskStatus, ITimedTask } from '../../contracts-for-plugins/taskapi';
import { ITKN_ITASKFACTORYSERVICE_for_DEMODATASERVICE } from 'src/app/application/injectionTokens';
import { IUserStorageMediaConfiguration } from 'src/app/view/usercontrols/main/contracts-for-plugins/user-storage-media-configuration.interface';
import { UserStorageMediaConfiguration1 } from '../../../user-storage-media-configuration1';
import { StorageMediumConfiguration1 } from '../../../storage-medium-configuration1';
import { SupportedStorageMedia } from 'src/app/model/supported-storage-media';
import { StorageMethods } from 'src/app/model/storage-methods.enum';
import { UUID } from 'angular2-uuid';
import { IDemoDataService } from '../../contracts-for-plugins/idemo-data.service';

/** @description Creates demo data.
 * This way our real dataservice is not polluted with demo-data logic and contains only an
 * explicit reference to this demodataservice.
 */
@Injectable()
export class DemoDataService1 implements IDemoDataService {

  constructor(
    @Inject(ITKN_ITASKFACTORYSERVICE_for_DEMODATASERVICE) public taskfactory: ITaskFactory
  ) { }

  public getDemoSettings(): ISettings {

    const settings = new Settings1();
    settings.soundVolume = 75;
    settings.tag = 'DEFAULT';
    settings.todo.hideDoneTasks = true;
    settings.storageMedia = this.getDemoStorageSettings();

    console.log('Loaded demo-mindstack with demo-settings.');

    return settings;
  }

  public getDemoTasks(): Array<ITask> {

    // #region DOING's

    // Some info:
    //    All statusses set to 'doing';
    //    - dateCreated and dateStarted have values; dateDone should be null but if it has a value, that value should be ignored;
    //    - dateStarted should be used for sorting on the stack: the latest one should be on top.
    //    - dateCreated is, for the doing-stack, of no importance.
    //
    //  Expected on the stack from top to bottom: 2, 1, 4, 3.
    //
    //  Note: month-numbers in js start from 0!   february = 1, march = 2, ....

    const task1: ITask = this.taskfactory.createTask();
    task1.parentId = null;
    task1.content = 'dummy taak doing - 1';
    task1.status = TaskStatus.Doing;
    task1.dateCreated = new Date(2020, 0, 15, 6, 30, 59);
    task1.dateStarted = new Date(2020, 1, 29, 6, 30, 59);
    task1.dateDone = null;
    task1.note = '##Vooraf2\nMET *USERS* EN PERSONAL *SETTINGS* (_MY SETTINGS_)\n(ALS JE DIT QUA OE OF USERS AANPAST, DAN OOK DIE ANDEREN AANPASSEN)\nlu: 15 Sep 2022\n\nhttp://nos.nl\n[_Het Nieuws_]http://nos.nl\n\n_*Dik en Schuin*_\n*_Schuin en Dik_*\n\n#  Org diagram *CUSTOMERS* Eskay in the China region:\n______________________________________________________________________\n\n\t\t\t\t\t\t_____________Company Root________\n\t\t\t\t\t\t/\t\t\t\t\t\t\t\t\\\n\t\t\tEskay Customer\t\t\t\t\t\t\t\tEskay ICONS Customer\n\t\t\tsljmkoks@outlook.com\t\t\t\t\t\tsljmkoks2@outlook.com\n\t\t\tRole: Customer__\t\t\t\t\t\t\t\tRole: _todo_\n\t\t\t/\t\t\t\t\\\t\t\t\t\t\t\t\t\\\t\nEskay Site Goofy\t\t\t\tEskay Site Donald D.\t\t\t\tEskay ICONS Site Supper\neskay1391@outlook.com\t\t..\t\t\t\t\t\t\t\teskay1392@outlook.com\ntimezone: Amsterdam\t\t..\t\t\t\t\t\t\t\tUTC\nlanguage: NL\t\t\t\t..\t\t\t\t\t\t\t\tEnglish\nmeasurementsystem: Metric\t..\t\t\t\t\t\t\t\tSI\nCOX402422\t\t\t\t\tWUX418030\t\t\t\t\t\tCOX214202\nCOX215003\t\t\t\t\tCOX215286\t\t\t\t\t\tCOX216021\nCOX214870\t\t\t\t\t\t\t\t\t\t\t\t\tCOX625665\n\n\n#Org diagram Eskay *SERVICEPROVIDERS* in the China region: (alleen ac/ict domains allowed)\n______________________________________________________________________\n\n\t\t________Service Company Root_____\n\t\t/\t\t\t\t\t\t\t\t\\\nEsKay CC\t\t\t\t\t\t\t\tEsKay ICONS CC\n(serves the AC sites)\t\t\t\t\t\t(serves the ICONS sites)\nstefan.koks@\t\t\t\t\t\t\teskay1591@outlook.com\t\n  humiqtrial.onmicrosoft.com\t\nrole: internal admin CC\t\t\t\t\t-internal admin CC\ntimezone: Amsterdam\t\t\t\t\t-UTC\nlanguage: NL\t\t\t\t\t\t\t-English\nmeasurementsystem: Metric\t\t\t\t-SI\n\n';
    // 'test https://www.vk.nl dus. \nDat is het he. Be *nice* to mummy, so *later* you will be rewarded as _King_.';
    task1.noteHeight = 750;


    // const task2: ITask = {
    // id: UUID.UUID()
    // , parentId: task1ID                   // <-- koppelen aan task1 !
    // , content: 'dummy taak doing - 2'
    // , status: TaskStatus.Doing
    // , dateCreated: new Date(2020, 1, 1, 12, 0, 23)
    // , dateStarted: new Date(2019, 10, 1, 12, 0, 23)
    // , dateDone: null
    // } as unknown as ITask;

    // const task3: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy taak doing - 3'
    // , status: TaskStatus.Doing
    // , dateCreated: new Date(2019, 11, 6, 14, 0, 0)
    // , dateStarted: new Date(2019, 11, 28, 16, 49, 23)
    // , dateDone: null
    // } as unknown as ITask;

    // const task4: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy taak doing - 4'
    // , status: TaskStatus.Doing
    // , dateCreated: new Date(2020, 0, 1, 7, 30, 0)
    // , dateStarted: new Date(2020, 0, 2, 8, 0, 3)
    // , dateDone: null
    // } as unknown as ITask;
    // #endregion

    // #region DONE's

    // Some info:
    //    All statusses set to 'done';
    //    - All dates have values
    //    - dateDone should be used for sorting on the stack: the most recent one should be on top.
    //    - dateCreated and dateStarted are, for the done-stack, of no importance.
    //
    //  Expected on the list from top to bottom: 4, 2, 3, 1.
    //
    //  Note: month-numbers in js start from 0!   february = 1, march = 2, ...

    // const task10: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy taak done - 1'
    // , status: TaskStatus.Done
    // , dateCreated: new Date(2020, 0, 10, 12, 0, 0)      // note: 0 = januari, 1 = februari
    // , dateStarted: new Date(2020, 0, 11, 12, 0, 0)
    // , dateDone: new Date(2020, 0, 12, 12, 0, 0)
    // , note: 'terrestial'
    // } as unknown as ITask;
    const task10: ITask = this.taskfactory.createTask();
    task10.content = 'dummy taak done - 1';
    task10.status = TaskStatus.Done;
    task10.dateCreated = new Date(2020, 0, 10, 12, 0, 0);      // note: 0 = januari, 1 = februari
    task10.dateStarted = new Date(2020, 0, 11, 12, 0, 0);
    task10.dateDone = new Date(2020, 0, 12, 12, 0, 0);
    task10.note = 'nog vernieuwen';

    // const task11: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy taak done - 2'
    // , status: TaskStatus.Cancelled
    // , dateCreated: new Date(2020, 1, 23, 12, 0, 0)
    // , dateStarted: new Date(2020, 1, 23, 12, 0, 0)
    // , dateDone: new Date(2020, 1, 27, 23, 35, 31)
    // } as unknown as ITask;

    // const task12: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy taak done - 3'
    // , status: TaskStatus.Done
    // , dateCreated: new Date(2020, 1, 16, 12, 0, 0)
    // , dateStarted: new Date(2020, 1, 19, 12, 0, 0)
    // , dateDone: new Date(2020, 1, 27, 23, 35, 2)
    // } as unknown as ITask;

    // const task13: ITask = {
    // id: UUID.UUID()
    // , parentId: task12.id
    // , content: 'dummy taak done - 4'
    // , status: TaskStatus.Done
    // , dateCreated: new Date(2019, 7, 15, 12, 0, 0)
    // , dateStarted: new Date(2019, 8, 19, 12, 0, 0)
    // , dateDone: new Date(2020, 2, 1, 7, 5, 2)
    // , note: 'yellow'
    // } as unknown as ITask;

    // #endregion

    // #region TODO's

    // Some info:
    //    All statusses set to 'todo';
    //    - Only dateCreated is set.
    //    - dateCreated is be used for sorting the list: the one most recently added one should be on top.
    //    - dateDone and dateStarted are, for the todo-list, of no importance.
    //
    //  Expected on the list from top to bottom: ?
    //
    //  Note: month-numbers in js start from 0!   february = 1, march = 2, ...

    const task21: ITask = this.taskfactory.createTask();
    task21.parentId = null;
    task21.content = 'dummy taak todo - 1';
    task21.status = TaskStatus.ToDo;
    task21.dateCreated = new Date(2020, 0, 1, 7, 0, 0);

    const task22: ITask = {
      id: UUID.UUID()
      , parentId: task21.id
      , content: 'dummy taak todo 8 - sub 1'
      , status: TaskStatus.ToDo
      , dateCreated: new Date(2020, 1, 1, 9, 15, 0)
      , note: 'nog vernieuwen' // task1.note // ref to task1, hoeft niet, nu makkelijk
    } as unknown as ITask;

    const task23: ITask = {
      id: UUID.UUID()
      , parentId: task21.id
      , content: 'dummy taak todo - 9, sub 1'
      , status: TaskStatus.ToDo
      , dateCreated: new Date(2020, 0, 27, 19, 30, 0)
    } as unknown as ITask;

    const task24: ITask = {
      id: UUID.UUID()
      , parentId: task23.id
      , content: 'dummy taak todo - 16, sub 9'
      , status: TaskStatus.ToDo
      , dateCreated: new Date(2020, 0, 27, 19, 30, 0)
    } as unknown as ITask;

    // const task25: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo - 2'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task26: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task25.id
    //   , content: 'dummy taak todo - 18, sub 2'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task27: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task25.id
    //   , content: 'dummy taak todo - 19, sub 2'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task28: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task25.id
    //   , content: 'dummy taak todo - 20, sub 2'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task29: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task27.id
    //   , content: 'dummy taak todo - 21, sub 19'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task30: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task27.id
    //   , content: 'dummy taak todo - 22, sub 19'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task31: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task27.id
    //   , content: 'dummy taak todo - 23, sub 19'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task32: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task30.id
    //   , content: 'dummy taak todo - 24, sub 22'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task33: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task32.id
    //   , content: 'dummy taak todo - 25, sub 24'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task34: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task33.id
    //   , content: 'dummy taak todo - 26, sub 25'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task35: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task34.id
    //   , content: 'dummy taak todo - 27, sub 26'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task36: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo - 28'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task37: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo - 29'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    //   , note: 'hello everybody'
    // } as unknown as ITask;

    // const task38: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task26.id
    //   , content: 'dummy taak todo - 30, sub 18'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task39: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task26.id
    //   , content: 'dummy taak todo - 31, sub 18'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // const task40: ITask = {
    //   id: UUID.UUID()
    //   , parentId: task38.id
    //   , content: 'dummy taak todo - 32, sub 30'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2020, 3, 7, 19, 41, 0)
    // } as unknown as ITask;

    // // paar extra tasks on level 0 om te testen met verticaal scrollen

    // const task41: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 41'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task42: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 42'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task43: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 43'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task44: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 44'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task45: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 45'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task46: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 46'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task47: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 47'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task48: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 48'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task49: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 49'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;

    // const task50: ITask = {
    //   id: UUID.UUID()
    //   , parentId: null
    //   , content: 'dummy taak todo 50'
    //   , status: TaskStatus.ToDo
    //   , dateCreated: new Date(2022, 5, 15, 15, 15, 0)
    // } as unknown as ITask;
    // #endregion

    // #region TIMED TODO's

    // Some info:
    //    All statusses set to 'todo';
    //    - timerName must be set to make it a TimedTask.
    //    - Only dateCreated is set.
    //    - dateCreated is (for the time being) used for sorting the list: the one most recently added one should be on top.
    //    - dateDone and dateStarted are, for the todo-list, of no importance.
    //
    //  Expected on the list from top to bottom: ?
    //
    //  Note: month-numbers in js start from 0!   february = 1, march = 2, ...

    // const task150 = new TimedTask1(new Task1());
    const task150: ITimedTask = this.taskfactory.createTimedTask();
    task150.parentId = '';
    task150.content = 'dummy TIMED todo - 1';
    task150.status = TaskStatus.ToDo;
    task150.dateCreated = new Date(2020, 2, 1, 9, 15, 0);
    task150.timerName = 'testme!';  // timername together with a subscriptionId qualify as a timed todo (Semantics.IsARunningTimer(task))
    task150.timerSubscriptionId = '2345';

    // const task151: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy TIMED todo - 2'
    // , status: TaskStatus.ToDo
    // , isTimed: true
    // , dateCreated: new Date(2020, 2, 1, 9, 15, 0)
    // } as unknown as ITask;

    // const task152: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy Cancelled TIMED todo - 3'
    // , status: TaskStatus.Cancelled
    // , isTimed: true
    // , dateCreated: new Date(2020, 0, 1, 19, 22, 47)
    // } as unknown as ITask;

    // const task153: ITask = {
    // id: UUID.UUID()
    // , parentId: 0
    // , content: 'dummy Postponed TIMED todo - 3'
    // , status: TaskStatus.Postponed
    // , isTimed: true
    // , dateCreated: new Date(2020, 0, 16, 6, 0, 12)
    // } as unknown as ITask;
    // #endregion

    const tasklist = new Array<ITask>();    // the order in which tasks are added should have no effect on the ordering of the stacks/lists.

    // doing 0-9
    tasklist.push(task1);
    // tasklist.push(task2);
    // tasklist.push(task3);
    // tasklist.push(task4);
    // tasklist.push(task5);
    // tasklist.push(task7);
    // tasklist.push(task6);

    // done 10-19
    tasklist.push(task10);
    // tasklist.push(task11);
    // tasklist.push(task12);
    // tasklist.push(task13);
    // tasklist.push(task14);
    // tasklist.push(task15);

    // todo 20-149
    tasklist.push(task21);
    tasklist.push(task22);
    tasklist.push(task23);
    tasklist.push(task24);
    // tasklist.push(task25);
    // tasklist.push(task26);
    // tasklist.push(task27);
    // tasklist.push(task28);
    // tasklist.push(task29);
    // tasklist.push(task30);
    // tasklist.push(task31);
    // tasklist.push(task32);
    // tasklist.push(task33);
    // tasklist.push(task34);
    // tasklist.push(task35);
    // tasklist.push(task36);
    // tasklist.push(task37);
    // tasklist.push(task38);
    // tasklist.push(task39);
    // tasklist.push(task40);
    // tasklist.push(task41);
    // tasklist.push(task42);
    // tasklist.push(task43);
    // tasklist.push(task44);
    // tasklist.push(task45);
    // tasklist.push(task46);
    // tasklist.push(task47);
    // tasklist.push(task48);
    // tasklist.push(task49);
    // tasklist.push(task50);

    // timed todo 150-
    tasklist.push(task150);

    // return new Array<ITask> ();
    return tasklist;
  }

  private getDemoStorageSettings(): IUserStorageMediaConfiguration {

    const usmc: IUserStorageMediaConfiguration = new UserStorageMediaConfiguration1({
      primaryProvider: new StorageMediumConfiguration1({
        medium: SupportedStorageMedia.LocalDisc,
        method: StorageMethods.Overwrite,
        externalId: null
      }),
      secondaryProvider: new StorageMediumConfiguration1({
        medium: SupportedStorageMedia.LocalStorage,
        method: StorageMethods.Overwrite,
        externalId: null
      }),
      otherProviders: [ ]
    });

    return usmc;
  }

}
