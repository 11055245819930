/* eslint-disable no-underscore-dangle */
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

/**
 * @description Component that contains the help-info for the
 * application. Basically it's only text. It has a button to
 * show/hide advanced help-topics such as about timed todo's.
 * @emits HelpComponent.notify event with value 'hideHelp' when
 * a user tries to close the help.
 * @summary Separated into a component because of the 100 lines
 * of html and 70 lines of css.
 */
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  @Output() notify: EventEmitter<string> = new EventEmitter<string>();
  // https://www.themarketingtechnologist.co/building-nested-components-in-angular-2/

  private _showAdvancedHelpscreen = false;

  constructor() { }

  ngOnInit(): void {
  }

  public get isAdvancedHelpscreenVisible(): boolean {
    return this._showAdvancedHelpscreen;
  }

  public showAdvancedHelpscreen(): void {
    this._showAdvancedHelpscreen = true;
  }

  public hideAdvancedHelpscreen(): void {
    this._showAdvancedHelpscreen = false;
  }

  public hideHelpscreen(): void {
    this.notify.emit('hideHelp');
  }

  // just to prevent a click from bubbling up to a parent.
  public catchMouseClick(event: any): void {
    event.stopPropagation();
  }
}
