/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit, Input, EventEmitter, Output, Renderer2, Inject } from '@angular/core';
import { TaskStack } from './stack/task-stack';
import { PropertyChangedEventArgs } from '../shared/property-changed-event-args';
import { GenericEventArgs } from '../shared/generic-event-args';
import { Observable } from 'rxjs';
import { ITask, TaskStatusChangedEventArg, TaskStatus } from 'src/app/model/api/api.task';
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { UUID } from 'angular2-uuid';
import { ITKN_ILOGSERVICE, ITKN_IFOCUSSERVICE } from 'src/app/application/injectionTokens';
import { IFocusService } from '../../shared/contracts-for-plugins/ifocus.service';
import { DoingService } from './doing.component.service';
import { IImplements } from 'src/app/model/api/api';
import { MatDialog } from '@angular/material/dialog';
import { OkCancelTextInputDialogComponent } from '../../components/ok-cancel-text-input-dialog/ok-cancel-text-input-dialog.component';

@Component({
  selector: 'app-doing',
  templateUrl: './doing.component.html',
  styleUrls: ['./doing.component.scss']
})
export class DoingComponent implements OnInit {

  // #region Main data -------------------------------------------------------------------------------------------------

  /** @description The main 'stack', which harbours the data
   * of the view (a selection of items in this.data).
   */
  public stack: TaskStack;

  // #endregion

  // #region In- and Outputs  -------------------------------------------------------------------------------------------------------------

  /** @description the dataservice. Contains the entire dataset (not only
   * 'doings' but all tasks of all states).
   */
  @Input() data: Observable<ITask[]> = new Observable();

  @Output() onNewTask = new EventEmitter<TaskStatusChangedEventArg>();

  @Output() onTaskStatusChanged = new EventEmitter<TaskStatusChangedEventArg>();

  @Output() onTaskPropertyChanged = new EventEmitter<PropertyChangedEventArgs>();

  @Output() onTaskGotFocus = new EventEmitter<ITask>();

  // #endregion

  // #region Init and main functions -------------------------------------------------------------------------------------------------

  /** @description Inject some items into this app and initialize it.
   */
  constructor(
    public renderer: Renderer2,
    public service: DoingService,
    @Inject(ITKN_ILOGSERVICE) public logger: ILogService,
    @Inject(ITKN_IFOCUSSERVICE) public focusservice: IFocusService
    , private dialog: MatDialog
    ) {

    this.stack = new TaskStack();
  }

  ngOnInit() {
    this.data.subscribe(x => {
      this.filterAndSort(x);
    });
  }

  private filterAndSort(data: ITask[]): void {
    const f_isRunningTask = (task: ITask): boolean => // tslint:disable-line:variable-name
      (
        task &&
          (
            // when it's not a timed task: only doings, postponed and blocked ones.
            (!IImplements.ITimedTask(task) && (task.status === TaskStatus.Doing  ||  task.status === TaskStatus.Postponed ||  task.status === TaskStatus.Blocked))
            ||
            // when it's a timed task: only doing ones.
            (IImplements.ITimedTask(task) && task.status === TaskStatus.Doing)
          )
      )
      ;
    this.stack.items = data.filter(f_isRunningTask);
    const f_sorter = (task1: ITask, task2: ITask): number => { // tslint:disable-line:variable-name
      if (task1.dateStarted < task2.dateStarted) {
        return 1;
      } else {
        return -1;
      }
    };
    this.stack.items = this.stack.items.sort(f_sorter);
  }
  // #endregion

  // #region Process events from childcomponents --------------------------------------------------------------------------------------

  public receiveNoteChange = (args: GenericEventArgs): void => {
    // We assign the task to the reference-input of the component, so args.reference
    //    refers to that task. The noteeditorcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the note-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'note', args.reference['note'], args.value); // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);
  };

  public receivePropertyChanged = (args: PropertyChangedEventArgs): void => {
    this.onTaskPropertyChanged.emit(args);
  };

  /** @description Processes a changerequest from a TextInputComponent.
   */
  public receiveContentChanged = (args: GenericEventArgs): void => {

    if (!args || !args.reference) {
      return;
    }

    // We assign the task to the reference-input of the component, so args.reference
    //    refers to that task. The textinputcomponent has no knowledge of what property's
    //    value it's managing, so here we tell our parent that it's the content-property.
    const fullArgs = new PropertyChangedEventArgs(
      args.reference, 'content', args.reference.content, args.value); // tslint:disable-line:no-string-literal

    this.onTaskPropertyChanged.emit(fullArgs);

    // some UI actions when the inputfield is blurred
    this.inputLeft(args.reference as ITask);
  };
  // #endregion

  // #region ParentId ----------------------------------------------------------------------------------------------------------------

  /** @description Bookkeeping: the parentId (Guid!) for next new
   * task. ParentId's are used just to link one task to the other.
   */
  _activeParentId: string = null;

  /** @description Sets the parent property of the doing-stack so
   * a new stackitem is linked to the correct parent.
   */
  public setParentId(parentId: string): void {
    this._activeParentId = parentId;
  }

  public resetParent_onClick(): void {
    this.setParentId(null);
    this.taskInsertionField_setFocus();
  }
  // #endregion

  // #region Insertion field  -------------------------------------------------------------------------------------------------------------

  /** @description event from the view when user presses
   * Enter in the 'doing-inputbox'
   */
  public taskInsertionField_onEnter = (elem): void => {
    if (elem.value) {                       // negeer lege input
      const task: ITask = this.createNewTask(elem.value);
      this.emitNewTask(task);
      elem.value = '';                      // invoerveld leegmaken
    }
  };

  /** @description Emits a task to the parent
   */
  private emitNewTask = (task: ITask): void => {
    const args = { task } as TaskStatusChangedEventArg;
    this.onNewTask.emit(args);            // opgooien naar parentcomponent
  };

  /** Create a new task and do some initializations typical for doings.
   *
   * @param content the text
   */
  private createNewTask = (content: string): ITask => {
    const task: ITask = this.service.getNewTaskFromContent(content);
    task.status = TaskStatus.Doing;
    task.parentId = this._activeParentId;
    task.dateStarted = new Date();
    return task;
  };

  public taskInsertionField_setFocus = (): void => {
    this.focusservice.setFocus('.doing #new-doing', this.renderer);
  };
  // #endregion

  // #region Notes ----------------------------------------------------------------------------------------------------------------------

  /** @description Bookkeeping: contains the id of the stackitem
   * for which a note is currently visible.
   * Only one note at a time is visible (or none).
   */
  public _showNoteId: UUID = null;

  /** @description Hide all notes
   */
  public hideNotes = (): void => {
    this._showNoteId = null;
  };

  /** @description Hide note on pressing escape in the note-editor
   */
  public note_onEsc = (): void => {
    this.hideNotes();
  };

  /** @description Shows or hides the note for the given task
   */
  public showNote = (item: ITask): void => {
    if (item) {
      if (this.isVisibleNote(item)) {
        this.hideNotes();
      } else {
        this._showNoteId = item.id;
        this.focusservice.setFocus('.doing #note-editor-' + item.id, this.renderer);
      }
    } else {
      this.hideNotes();  // hide all notes als leeg argument is opgegeven.
    }
  };

  /** @description Returns whether the note of the given task
   * is visible or not.
   */
  public isVisibleNote = (item: ITask): boolean => {
    const result = item && this._showNoteId === item.id;
    return result;
  };

  // #endregion

  // #region Buttonclicks ----------------------------------------------------------------------------------------------------------------

  /** Organize a task into a set of related tasks by creating a parent. */
  public organize = (item: ITask): void => {

    // Because of businessrule "BR-1002: parent-task cannot be in state Doing."
    // subtasking a doing is no longer allowed. Therefor we call it 'organize'
    // which means that we create a parenttask on the todo-list with the same name.

    // Create a copy of the current task: the copy becomes the new parent.
    // Note: if the original had a parent (we call it grandparent), than that becomes the parent of the copy.

    // first store the grandparentId before it's overwritten.
    const grandparentId = item.parentId;

    // create a new parent, which is inserted in the todo-tree between the grandparent and the current task.
    const todo = this.createNewTask(item.content);
    todo.status = TaskStatus.ToDo;
    todo.parentId = grandparentId;

    // request new task
    this.emitNewTask(todo);

    // request change of current task
    const args = {
      entity: item,
      propertyname: '_parentId',
      oldValue: grandparentId,
      newValue: todo.id
    } as PropertyChangedEventArgs;

    this.onTaskPropertyChanged.emit(args);
  };


  /** @description Marks the given task as done. Practically this
   * given task is always the one that is on top of the stack.
   */
  public done = (event: MouseEvent, task: ITask): void => {

    // if a user holds the Shift-key while clicking 'done' the task is deleted.
    if (event.shiftKey) {
      this.deleteTask(task);
    } else {
      const args = { task, newStatus: TaskStatus.Done } as TaskStatusChangedEventArg;
      this.onTaskStatusChanged.emit(args);
    }
    this.hideNotes();
  };


  /** @description Delete a task (i.e. send delete-request to parent component) */
  private deleteTask = (task: ITask): void =>{
    const args = { task, newStatus: TaskStatus.Deleted } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
  };


  /** @description Cancels the given task. Practically this given
   * task is always the one that is on top of the stack.
   */
  public cancel = (event: MouseEvent, task: ITask): void => {

    // if a user holds the Shift-key while clicking 'done' the task is deleted.
    if (event.shiftKey) {
      this.deleteTask(task);
    } else {
      const args = { task, newStatus: TaskStatus.Cancelled } as TaskStatusChangedEventArg;
      this.onTaskStatusChanged.emit(args);
    }
    this.hideNotes();
  };

  /** @description Put the given item in 'pending' state. Practically
   * this given task is always the one that is on top of the stack
   * and after postponing it's no longer the top.
   */
  public postpone = (task: ITask): void => {

    const args = { task, newStatus: TaskStatus.Postponed } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
    this.hideNotes();
  };

  /** @description Put the given item in doing-state. Practically
   * this given task is always a postponed one that was on top of
   * the stack before, and after reopening it'll be the top again.
   */
  public reopen = (task: ITask): void => {
    const args = { task, newStatus: TaskStatus.Doing } as TaskStatusChangedEventArg;
    this.onTaskStatusChanged.emit(args);
  };

  /** @description Put the given item in blocked state. Practically
   * this given task is always the one that is on top of the stack
   * and after blocking it's no longer the top.
   */
  public block = (task: ITask): void => {

    // let user insert reason for being blocked
    const dialogRef = this.dialog.open(OkCancelTextInputDialogComponent, {
      width: '350px',
      data: { title: 'Block task', question: 'Please insert the reason why this task has been blocked:', label: 'Reason', okTitle2: 'Save', cancelTitle2: 'Skip', task },
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log(`The dialog was closed: ${String(result)}`);
      const args = { task, newStatus: TaskStatus.Blocked, reasonBlocked: result } as TaskStatusChangedEventArg;
      this.onTaskStatusChanged.emit(args);
      this.hideNotes();
    });

  };

  /** user clicks 'doc' button to convert task into documentation (hence 'doctate') */
  public doctate = (task: ITask): void => {

    const statusChangeRequest = {
      task,
      newStatus: TaskStatus.Documentation
    } as TaskStatusChangedEventArg;

    this.onTaskStatusChanged.emit(statusChangeRequest);

    // in order to end up in the documentation-tree it should not be attached to a parent in a different tree.
    const resetParentRequest = new PropertyChangedEventArgs(statusChangeRequest.task, 'parentId', statusChangeRequest.task.parentId, null);
    this.onTaskPropertyChanged.emit(resetParentRequest);
  };
  // #endregion

  // #region Specials ----------------------------------------------------------------------------------------------------------------

  /**
   * @description Make the postponed-status value available to
   * the template (it is used for showing the reopen-button).
   */
  postponedStatus: number = TaskStatus.Postponed;

  /**
   * @description Make the blocked-status value available to
   * the template (it is used for showing the unblock-button).
   */
  blockedStatus: number = TaskStatus.Blocked;

  // #endregion

  /** @description Processes a height-changerequest from the note-editor. */
  public receiveNoteHeightChange = (args: GenericEventArgs): void => {

    // we do not broadcast this change because data-consistency is not im frage.
    (args.reference as ITask).noteHeight = Number(args.value);
  };

  public inputEntered(args: ITask): void {
    if (!args) {
      console.warn('doing>inputEntered: found null-task, exit.');
      return;
    }

    // console.log('doing: input entered ' + String(args.content) + ' having parentId ' + String(args.parentId));

    this.onTaskGotFocus.emit(args);
  }

  public inputLeft(args: ITask): void {

    if (!args) {
      console.warn('doing>inputLeft: found null-task, exit.');
      return;
    }

    // console.log('doing: input left ' + String(args.content) + ' having parentId ' + String(args.parentId));
  }
}
