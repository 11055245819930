import { ITimerService } from './itimer.service';
import { SimpleTimer } from './ng2-simple-timer.service';

/** Adapter/facade for linking any external timer-service.
 * Requests are passed through to the private -imported- timerservice.
 */
export class TimerService1 implements ITimerService {

  private service: SimpleTimer; // currently we use the timerservice from ng2-simple-timer (https://github.com/J-Siu/ng2-simple-timer/).

  constructor() {
    this.service = new SimpleTimer();
  }

  getTimer(): string[] {
    return this.service.getTimer();
  }
  getSubscription(): string[] {
    return this.service.getSubscription();
  }
  newTimer(name: string, sec: number, delay?: boolean): boolean {
    return this.service.newTimer(name, sec, delay);
  }
  newTimerCD(name: string, sec: number, delay?: number): boolean {
    return this.service.newTimerCD(name, sec, delay);
  }
  newTimerHR(name: string, msec: number, delay?: number): boolean {
    return this.service.newTimerHR(name, msec, delay);
  }
  deleteTimer(name: string): boolean {
    return this.service.delTimer(name);
  }
  subscribe(name: string, callback: () => void): string {
    return this.service.subscribe(name, callback);
  }
  unsubscribe(id: string): boolean {
    return this.service.unsubscribe(id);
  }
}
