
// angular deps
import { Component,  Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';

// model deps
import { IFileOpenEventArgs } from 'src/app/model/api/api.contracts-for-plugins';

@Component({
  selector: 'app-load-file-from-disc',
  templateUrl: './load-file-from-disc.component.html',
  styleUrls: ['./load-file-from-disc.component.scss']
})
export class LoadFileFromDiscComponent {

  @ViewChild('fileInput', { read: ElementRef, static: true }) fileinputElement: ElementRef<Input>;

  @Output()
  onLoadFileFromDisc = new EventEmitter<IFileOpenEventArgs>();

  constructor() { }

  /**
   * @description (old comment, should be updated to the current situation) As soon as a user selects a file in the
   * file-selector-control, we import that file into the current data.
   * note: within the onload event the 'this' keyword refers to the FileReader and not to the appcomponent. Therefore, if
   * a reference to the appcomponent is neeeded, it must be passed as an argument to this eventhandler. This may be done
   * in the ngInit-method in the top of this file.
   * https://developer.mozilla.org/en-US/docs/Web/API/FileReader/onload
   */
  public onImportFileChanged(files: FileList): void {

    const file = files.item(0); // I use .item(0) instead of the indexer [0] because I don't know how
    if (!file) {                //      to spy on the indexer in the unittest.
      return;
    }

    this.importFile(file);
  }

  /**
   * @description Imports a file on a separate thread.
   * Uses webworker, but in stone-age style. Something to be worked on.
   *
   * Note: I'm not sure the worker is destroyed in all error-scenario's.
   */
  public importFile(file: File): void {     // public, only because of unittests

    // Create a worker on a separate thread.
    const importWorker = new Worker(new URL('./importer.worker', import.meta.url), { type: 'module' });

    // 'onmessage' is the receiver for the result from the separate thread.
    importWorker.onmessage = (message) => {

      // dispatch received data to parentcomponent
      this.onLoadFileFromDisc.emit(message.data);

      // destroy the worker (this works, surprisingly)
      importWorker.terminate();
    };

    // post a command to the worker (on the separate thread).
    try {
      importWorker.postMessage(file);
    } catch {
      // if anything goes wrong, destroy the worker.
      importWorker.terminate();
    }
  }
}

