<div class="task-editor" (keyup.esc)="close()">
	<div class="close" (click)="close()">
		<div class="closer"></div>
	</div>


	<div class="rij">
		<span class="header">Edit record with ID {{itemCopy.id}}</span>
		<div class="button-bar">
			<button (click)="save()" name="btSave" class="button-bar-button">
				<div class="button-bar-button-text">Save</div>
			</button>
		</div>
	</div>

	<br />

	<div class="rij">
		<label for="nameEditor">name:</label><input type="text" required name="nameEditor"
			[(ngModel)]="itemCopy.content">
	</div>

	<div class="rij">
		<label for="parentIdEditor">parent:</label><input type="text" name="parentIdEditor"
			[(ngModel)]="itemCopy.parentId">
	</div>

	<div class="rij">
		<label for="stateEditor">status:</label>
		<app-dropdown [items]="dropdownValues" [selectedValue]="itemCopy.status"
			(onSelectionChanged)="receiveTaskStatusChanged($event)" class="dropdown"></app-dropdown>
	</div>

	<div class="rij">
		<label for="dateCreatedEditor">created:</label><input type="text" name="dateCreatedEditor"
			[(ngModel)]="itemCopy.dateCreated">
	</div>

	<div class="rij">
		<label for="dateStartedEditor">started:</label><input type="text" name="dateStartedEditor"
			[(ngModel)]="itemCopy.dateStarted">
	</div>

	<div class="rij">
		<label for="dateFinishedEditor">finished:</label><input type="text" name="dateFinishedEditor"
			[(ngModel)]="itemCopy.dateDone">
	</div>

	<div class="rij">
		<label for="noteEditor">note:</label><input type="text" name="noteEditor" [(ngModel)]="itemCopy.note">
	</div>

</div>