
/** @description Experimental: handle changes by components avoiding the
 * need for (erroneous) change-detection on the receiving side.
 */
export class PropertyChangedEventArgs {

  /** @description the object that has the property
   */
  entity: any;

  /** @description The fieldname/property name that has changed.
   */
  propertyname: string;

  /** @description The value before the change.
   */
  oldValue: any;

  /** @description The value after the change.
   */
  newValue: any;

  constructor(entity: any, property: string, oldValue: any, newValue: any) {
    this.entity = entity;
    this.propertyname = property;
    this.oldValue = oldValue;
    this.newValue = newValue;
  }
}
