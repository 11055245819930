import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { TaskChangedEventArgs } from '../shared/task-changed-event-args';
import { Observable } from 'rxjs';
import { ITask, FilteredTaskArrayContainer, TaskStatus } from 'src/app/model/api/api.task';
import { ILogService } from 'src/app/cross-cutting-concerns/api.cross-cutting-concerns';
import { ITKN_ILOGSERVICE } from 'src/app/application/injectionTokens';
import { IImplements } from 'src/app/model/api/api.shared';


@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.scss']
})
export class DoneComponent implements OnInit {

  // #region Main data -------------------------------------------------------------------------------------------------

  /**
   * @description The main data, which harbours the data of the
   * view (a selection of items in this.data).
   */
  public list: FilteredTaskArrayContainer;

  // private data: ITask[];

  /** Size of the entire history, unfiltered. */
  public totalSize: number;

  // #endregion

  // #region In- and Outputs
  /**
   * @description the dataservice. Contains the entire dataset (not only
   * 'done' but all tasks of all states).
   */
  @Input()
  data = new Observable<ITask[]>();

  @Output()
  onTaskChanged: EventEmitter<TaskChangedEventArgs> = new EventEmitter<TaskChangedEventArgs>();
  // #endregion

  // #region Init and main functions -------------------------------------------------------------------------------------------------
  constructor(
    @Inject(ITKN_ILOGSERVICE) public logger: ILogService
  ) {

    this.list = new FilteredTaskArrayContainer();
  }

  ngOnInit(): void {
    this.data.subscribe(x => {
      // this.data = x;
      this.filterAndSort(x);
    });
  }

  // ngOnDestroy() {
  //   this.dataservice.unsubscribe(); // 2020-07-16 SK: volgens mij is dit goed, maar werkt alleen als
  // dataservice als BehaviorSubject defined is, en ik weet niet zeker of DAT goed is.
  // Ik laat het nu even staan, we zien verder.
  // }

  /** @description Filters and sorts the dataset for the current
   * view: the list of finished tasks.
   */
  private filterAndSort(data: ITask[]): void {

    /*    Technical note of some importance:

          The done-list has 2 filters:
          1. First all data is filtered so this component subsequently
              only processes items that are in a 'done' state.
          2. Second the user can choose a custom filter, which is
              applied on top of the first filter.

          For this second filter we use the functionality of the
              AFilteredCollection-class; for the first filter we
              DO NOT use that class.

          Therefor, next few lines DO NOT use list.setFilter(),
          list.applyFilter() and the like. Those methods are
          used in filterHistory, though.
     */

    // filter the incoming data to a list of only 'done' items.
    const f_isFinishedTask = (task: ITask): boolean =>  // tslint:disable-line:variable-name
      (
        task
        && !(IImplements.ITimedTask(task) && task.status === TaskStatus.Done && !task.dateDone)
        // └─this represents a current timer that has been stopped. It has status 'Done' it would normally be selected for this component.
        && (task.status === TaskStatus.Cancelled ||
          task.status === TaskStatus.Done)
      );
    this.list.items = data.filter(f_isFinishedTask);    // note: the items in the list point to the same objects that are in data!

    this.totalSize = this.list.items.length;  // for GUI

    // sort, most recent done is on top
    const f_sorter = (task1: ITask, task2: ITask): number => { // tslint:disable-line:variable-name
      if (task1.dateDone < task2.dateDone) {
        return 1;
      } else {
        return -1;
      }
    };
    this.list.items = this.list.items.sort(f_sorter);

    // apply the second level filter, settable by the user
    this.filterHistory(this._activeFilterName);
  }
  // #endregion

  // #region Process events from childcomponents -----------------------------------------------------------

  /**
   * @description The historyeditor can not close itself so it
   * throws an event, and here we catch it. And we catch other
   * events from there as well.
   */
  receiveCloseEventFromTaskEditor = (event: any): void => {
    this.hideTaskEditor();
  }

  receiveTaskChanged = (event: TaskChangedEventArgs): void => {
    this.onTaskChanged.emit(event);
  }
  // #endregion

  // #region Drag n drop
  public done_onDrop(event: any): void {

  }
  // #endregion

  // #region TaskEditor --------------------------------------------------------------------------------------------------------------

  /**
   * @description Holds the ID of the currently selected StackItem
   * for which the noteeditor is shown.
   */
  public _showTaskEditorForTaskId: string = null;

  public isHiddenTaskEditor(task: ITask): boolean {
    return task.id !== this._showTaskEditorForTaskId;
  }

  public hideTaskEditor(): void {
    this._showTaskEditorForTaskId = null;
  }

  /** @description show or hide the editor for the given historystackitem. */
  public showTaskEditor(task: ITask): void {
    if (task) {
      // hide it if it's opened already.
      if (this._showTaskEditorForTaskId === task.id) {
        this.hideTaskEditor();
      } else {
        this._showTaskEditorForTaskId = task.id;
        // this.setFocus('nameEditor');  TODO !
      }
    } else {
      this.hideTaskEditor(); // hide all editors.
    }
  }
  // #endregion

  // #region User custom filter ----------------------------------------------------------------------------------------------------

  /** @description Contains the name of the active history-filter.
   */
  public _activeFilterName = 'none';

  /** @description Filter de getoonde lijst.
   */
  public filterHistory(filter?: string): void {

    if (!filter) {
      filter = 'none';
    }

    this._activeFilterName = filter;

    if (filter === 'none') {           // 'none' means 'show none'.
      this.list.setFilter(x => false);
    } else if (filter === 'done') {
      this.list.setFilter(x => x.status === TaskStatus.Done);
    } else if (filter === 'all') {
      this.list.setFilter(x => true);
    } else if (filter === 'parents') {
      this.list.setFilter(x => !x.parentId);
    } else if (filter === 'cancelled') {
      this.list.setFilter(x => x.status === TaskStatus.Cancelled);
    }

    this.list.applyFilter();
  }

  /** @description clear filter (i.e. show all). */
  public clearHistoryFilter(): void {
    this.list.clearFilter();
  }
  // #endregion

  /** @description geeft statustekst van een task. */
  public getStatusText(status: TaskStatus): string {
    return TaskStatus[status];
  }
}
