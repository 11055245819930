/** @description Represents an dropdown entry with a label and a value.
 */
export class DropdownEntry {

  /** @description Value, string so it's pretty generic.
   */
  value: string;

  /** @description Label, text that is displayed in the UI.
   */
  label: string;

  constructor(value: string, label: string) {
    this.value = value;
    this.label = label;
  }
}
