<div id="done-section" class="done repress breath cool" cdkDropList (cdkDropListDropped)="done_onDrop($event)">

        <div class="rij">

                <h4 id="titleHistory">done &nbsp;</h4>

                <span class="sup stack-counter" matTooltip="Visible tasks/All finished tasks"
                        matTooltipClass="top-bar-tooltip">{{list.filtered.size}}/{{totalSize}} &nbsp;</span>

                <label class="button-bar-label" for="button-bar-section"></label>

                <div class="button-bar-section" id="button-bar-section">

                        <mat-icon-button class="ms-button bar-button" (click)="filterHistory('none')" matTooltip="Hide history"
                                matTooltipClass="top-bar-tooltip"><span class="material-symbols-outlined"
                                        [class.bar-active-filter]="_activeFilterName==='none'">block</span></mat-icon-button>

                        <mat-icon-button class="ms-button bar-button" (click)="filterHistory('done')"
                                matTooltip="Show done tasks" matTooltipClass="top-bar-tooltip"><span
                                        class="material-symbols-outlined"
                                        [class.bar-active-filter]="_activeFilterName==='done'">done</span></mat-icon-button>

                        <mat-icon-button class="ms-button bar-button" (click)="filterHistory('cancelled')"
                                matTooltip="Show cancelled tasks" matTooltipClass="top-bar-tooltip"><span
                                        class="material-symbols-outlined"
                                        [class.bar-active-filter]="_activeFilterName==='cancelled'">cancel</span></mat-icon-button>

                        <mat-icon-button mat-icon-button class="ms-button bar-button" (click)="filterHistory('parents')"
                                matTooltip="Show parents" matTooltipClass="top-bar-tooltip"><span
                                        class="material-symbols-outlined"
                                        [class.bar-active-filter]="_activeFilterName==='parents'">account_tree</span></mat-icon-button>

                        <mat-icon-button mat-icon-button class="ms-button bar-button" (click)="filterHistory('all')"
                                matTooltip="Remove filter (show all)" matTooltipClass="top-bar-tooltip"><span
                                        class="material-symbols-outlined"
                                        [class.bar-active-filter]="_activeFilterName==='all'">filter_alt_off</span></mat-icon-button>
                </div>
        </div>

        <div *ngFor="let item of list.filtered.items">
                <div class="item">
                        <button (click)="showTaskEditor(item)" name="btEdit" class="ms-button darkgray edit-button"
                                matTooltip="Edit task" matTooltipClass="top-bar-tooltip">
                                <div class="ms-button-text edit-button-text">edit</div>
                        </button>
                        {{item.id.shortID()}} &bull; <span class="parent-identifier"
                                [hidden]="!item.parentId">({{item.parentId ? item.parentId.shortID() : ''}})&nbsp;</span>
                        <span class="done-text"><a id="{{item.id}}" >{{item.content}}</a></span>&nbsp;
                        <small><i>&bull;&nbsp;<b>{{getStatusText(item.status)}}</b>&nbsp;&bull; Started:
                                        {{item.dateStarted | date : 'short' }} &bull; Finished:
                                        {{item.dateDone | date : 'short' }}</i></small>&nbsp;<sub><b>Note:</b>{{item.note}}</sub>

                        <app-task-editor [item]="item" [hidden]="isHiddenTaskEditor(item)"
                                (onClose)="receiveCloseEventFromTaskEditor($event)"
                                (onTaskChanged)="receiveTaskChanged($event)"></app-task-editor>
                        <br /><br />
                </div>
        </div>
</div>
